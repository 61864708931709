import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { ProviderStudent } from "@user-management-context/read/domain/types/provider/student";

export const adminRetrieveProviderStudents = createAsyncThunk<ProviderStudent[], string, { extra: Partial<Dependencies> }>(
  "admin/providers/retrieveProviderStudents",
  async (providerId, { extra: { adminProviderRepository } }) => {
    return adminProviderRepository!.getProviderStudents(providerId);
  }
);

export const resetAdminRetrieveProviderStudents = createAction("admin/providers/resetRetrieveProviderStudents");
