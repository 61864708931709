import { createReducer } from "@reduxjs/toolkit";
import { UpdateState } from "@redux/app-state";
import { resetUpdateFundingRequest, updateFundingRequest } from "./update-funding-request";

const initialState: UpdateState = { processing: "idle", justUpdatedId: null };

export const updateFundingRequestReducer = createReducer<UpdateState>(initialState, builder => {
  builder.addCase(updateFundingRequest.fulfilled, (state, { meta }) => {
    return {
      ...state,
      justUpdatedId: meta.arg.id,
      processing: "success",
    };
  });
  builder.addCase(updateFundingRequest.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(updateFundingRequest.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetUpdateFundingRequest, () => {
    return initialState;
  });
});
