import { useCallback, useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { ROLE_BASED_URLS } from "src/routes";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { Button } from "@components/ui/button";
import { ReloadIcon } from "@radix-ui/react-icons";
import { useToast } from "@components/ui/use-toast";
import { triggerBatchParticipationCompletionCheck } from "@academy-context/write/application/use-cases/admin/provider-batch-participation-completion/trigger-batch-participation-completion-check";
import { ProviderBatchListSkeleton } from "@academy-context/primary/shared/provider-batch/provider-batch-list.skeleton";
import { ProviderBatch } from "@academy-context/primary/provider/provider-batches/provider-batch.components";
import { CreateBatchModal } from "@academy-context/primary/shared/provider-batch/create-batch-modal";
import {
  CreateProviderBatchBody,
  ProviderStudentEnrollmentToBatchBody,
} from "@user-management-context/shared/application/ports/provider-repository";
import { createProviderBatch } from "@academy-context/write/application/use-cases/shared/provider-batch-creation/create-provider-batch";
import { adminRetrieveProviderTrainings } from "@academy-context/read/application/use-cases/admin/provider-trainings-retrieval/retrieve-provider-trainings";
import { adminRetrieveProviderBatches } from "@academy-context/read/application/use-cases/admin/provider-batches-retrieval/retrieve-provider-batches";
import { adminSelectProviderTrainingsRetrievalForList } from "@academy-context/read/application/use-cases/admin/provider-trainings-retrieval/selectors/provider-training-list-selectors";
import { adminSelectProviderBatchesRetrievalForBatchesPage } from "@academy-context/read/application/use-cases/admin/provider-batches-retrieval/selectors/provider-batch-list-selectors";
import { useAdminStudentEnrolled } from "@academy-context/primary/admin/provider-batches/use-student-enrolled";
import { useAdminProviderBatchCreated } from "@academy-context/primary/admin/provider-batches/use-batch-created";
import { useAdminProviderBatchUpdated } from "@academy-context/primary/admin/provider-batches/use-batch-updated";
import { AppState } from "@redux/app-state";
import {
  adminResetUploadProviderBatchParticipationInvoice,
  adminUploadProviderBatchParticipationInvoice,
} from "@academy-context/write/application/use-cases/admin/provider-batch-participation-invoice-upload/upload-provider-batch-participation-invoice";
import { EnrollProviderStudentModal } from "@user-management-context/primary/provider/student-enrollment/enroll-provider-student-modal";
import { enrollStudentToProviderBatch } from "@academy-context/write/application/use-cases/provider/provider-batch-enrollment/enroll-student-to-batch";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { ProviderBatchItem } from "@academy-context/read/domain/types/shared/provider-batch";
import { BatchCalendarModal } from "@academy-context/primary/shared/provider-batch/batch-calendar-modal";
import { useAdminStudentEnrollementCancelled } from "@academy-context/primary/admin/provider-batches/use-student-enrollement-cancelled";
import { useBatchParticipationCompletionCheck } from "@academy-context/primary/admin/provider-batches/use-batch-participation-completion-check";
import { Switch } from "@components/ui/switch";
import { Label } from "@components/ui/label";
import { BATCH_STATUS } from "@academy-context/read/application/use-cases/shared/selectors/format-batches-for-list";

export const AdminProviderBatchListContainer = () => {
  const { providerId } = useParams<{ providerId: string }>();
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const uploadedInvoice = useAppSelector((state: AppState) => state.adminProviderBatchParticipationInvoiceUpload);
  const { isLoading, batches } = useAppSelector(adminSelectProviderBatchesRetrievalForBatchesPage);
  const { trainings } = useAppSelector(adminSelectProviderTrainingsRetrievalForList);
  const { processing } = useAppSelector((state: AppState) => state.adminBatchParticipationCompletionCheck);
  const [displayArchived, setDisplayArchived] = useState(false);
  const [filteredBatches, setFilteredBatches] = useState<typeof batches>([]);
  const [selectedBatch, setSelectedBatch] = useState<Nullable<ProviderBatchItem>>(null);
  const [isCreateBatchModalOpen, setIsCreateBatchModalOpen] = useState(false);
  const [isEnrollmentModalOpen, setIsEnrollmentModalOpen] = useState(false);
  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);

  useEffect(() => {
    if (displayArchived) setFilteredBatches(batches);
    else {
      const activeBatches = batches.filter(batch => batch.status !== BATCH_STATUS.ARCHIVED);
      setFilteredBatches(activeBatches);
    }
  }, [batches, displayArchived]);

  useEffect(() => {
    if (providerId) {
      dispatch(adminRetrieveProviderTrainings(providerId));
      dispatch(adminRetrieveProviderBatches(providerId));
    }
  }, [dispatch, providerId]);

  useAdminProviderBatchCreated({ providerId: providerId || "" });
  useAdminProviderBatchUpdated({ providerId: providerId || "" });
  useAdminStudentEnrolled({ providerId: providerId || "" });
  useAdminStudentEnrollementCancelled({ providerId: providerId || "" });
  useBatchParticipationCompletionCheck({ providerId: providerId || "" });

  const handleRefresh = useCallback(async () => {
    if (processing === "pending") return;

    dispatch(triggerBatchParticipationCompletionCheck());
    toast({
      title: "Rafraîchissement en cours",
      description: "Les données seront mises à jour dans quelques secondes",
    });
  }, [dispatch, processing, toast]);

  const handleSubmit = (body: CreateProviderBatchBody) => {
    dispatch(createProviderBatch(body));
  };

  const onUploadInvoice = useCallback(
    (invoice: File) => {
      if (providerId) {
        dispatch(adminUploadProviderBatchParticipationInvoice({ providerBatchParticipationInvoice: invoice, providerId }));
      }
    },
    [dispatch, providerId]
  );

  const onResetUploadInvoice = useCallback(() => {
    dispatch(adminResetUploadProviderBatchParticipationInvoice());
  }, [dispatch]);

  const onEnrollStudent = (body: ProviderStudentEnrollmentToBatchBody) => {
    dispatch(enrollStudentToProviderBatch(body));
  };

  const openEnrollmentModal = (batch: ProviderBatchItem) => {
    setSelectedBatch(batch);
    setIsEnrollmentModalOpen(true);
  };

  const closeEnrollmentModal = () => {
    setSelectedBatch(null);
    setIsEnrollmentModalOpen(false);
  };

  const openCalendarModal = (batch: ProviderBatchItem) => {
    setSelectedBatch(batch);
    setIsCalendarModalOpen(true);
  };

  const closeCalendarModal = () => {
    setSelectedBatch(null);
    setIsCalendarModalOpen(false);
  };

  const openCreateBatchModal = () => {
    setIsCreateBatchModalOpen(true);
  };

  const closeCreateBatchModal = () => {
    setIsCreateBatchModalOpen(false);
  };

  if (!providerId) {
    return <Navigate to={ROLE_BASED_URLS[ROLES.ADMIN]["provider"].providerListForBatches} replace />;
  }

  return (
    <>
      <CreateBatchModal isOpen={isCreateBatchModalOpen} onClose={closeCreateBatchModal} onSubmit={handleSubmit} trainings={trainings} />
      {selectedBatch && (
        <EnrollProviderStudentModal
          isOpen={isEnrollmentModalOpen}
          onClose={closeEnrollmentModal}
          onSubmit={onEnrollStudent}
          batch={selectedBatch}
          isAdmin={true}
          providerId={providerId}
        />
      )}
      {selectedBatch && <BatchCalendarModal isOpen={isCalendarModalOpen} onClose={closeCalendarModal} batch={selectedBatch} />}
      <div className="mb-4 flex items-center justify-between">
        <div className="flex gap-2">
          <Button onClick={openCreateBatchModal}>Ajouter une session de formation</Button>
          <Button variant="outline" onClick={handleRefresh} disabled={processing === "pending"}>
            <ReloadIcon className={`size-4 ${processing === "pending" ? "animate-spin" : ""}`} />
            Rafraichir les participations
          </Button>
        </div>
        <div className="flex items-center space-x-2" onClick={() => setDisplayArchived(!displayArchived)}>
          <Switch checked={displayArchived} />
          <Label>Afficher les sessions archivées</Label>
        </div>
      </div>
      <div>
        {isLoading ? (
          <ProviderBatchListSkeleton />
        ) : (
          filteredBatches.map(batch => (
            <div className="mt-4" key={batch.id}>
              <ProviderBatch
                batch={batch}
                uploadedInvoice={uploadedInvoice}
                onUploadInvoice={onUploadInvoice}
                onResetUploadInvoice={onResetUploadInvoice}
                isAdmin
                openCalendarModal={openCalendarModal}
                openEnrollmentModal={openEnrollmentModal}
              />
            </div>
          ))
        )}
      </div>
    </>
  );
};
