import { getFileProps } from "@utils/utils";
import { FileProps } from "../../../../academy-context/write/domain/constants/shared";
import { UploadButton } from "./upload-button";
import { RequestState } from "src/redux/app-state";

interface Props {
  label?: string;
  defaultValue?: string | null;
  onChange: (file: File) => void;
  onError: () => void;
  onDelete?: () => void;
  validatorFunc: (props: FileProps) => boolean;
  types: string[];
  processing: RequestState;
}

const DocumentUploadButton = ({
  label = "Uploader",
  defaultValue = "",
  onChange,
  onError,
  validatorFunc,
  types,
  onDelete,
  processing,
}: Props) => {
  return (
    <div className="flex items-center">
      <UploadButton
        label={label}
        value={defaultValue}
        onChange={onChange}
        onError={onError}
        types={types}
        onDelete={onDelete}
        processing={processing}
        getFileProps={getFileProps}
        validatorFunc={validatorFunc}
      />
    </div>
  );
};

export default DocumentUploadButton;
