import { BackButton } from "@shared-kernel/primary/shared/back-button/back-button";
import { TrainingFollowUpEvaluation } from "@academy-context/read/domain/types/training-follow-up";
import { ROLE_BASED_URLS } from "src/routes";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { EvaluateTrainingFollowUpObjectivePayload } from "@academy-context/write/application/use-cases/shared/training-follow-up-objective-evaluation/evaluate-training-follow-up-objective";
import { EvaluationCard } from "@academy-context/primary/shared/educational-advisor-training-follow-up-evaluation-form/evaluation-card.components";

interface Props {
  evaluations: TrainingFollowUpEvaluation[];
  trainingFollowUpId: string;
  onSubmit: (body: EvaluateTrainingFollowUpObjectivePayload) => void;
}

export const TrainingFollowUpEvaluationForm = ({ evaluations, onSubmit }: Props) => {
  return (
    <div className="flex justify-center">
      <div className="w-[90%]">
        <BackButton link={ROLE_BASED_URLS[ROLES.TEACHER].trainingFollowUp.list} label="Formations" />
        <div className="text-sm font-normal">
          Dans le cadre de la certification Qualiopi, l’évaluation de la formation est obligatoire. Nous vous remercions d’évaluer les
          objectifs suivants. Ces objectifs ont été communiqués au financeur, qui nous demande de lui transmettre un retour sur leur niveau
          d’atteinte.
        </div>
        <div className="mt-5 space-y-5">
          {[...evaluations]
            .sort((a, b) => a.number - b.number)
            .map(e => (
              <EvaluationCard key={e.id} evaluation={e} onSubmit={onSubmit} />
            ))}
        </div>
      </div>
    </div>
  );
};
