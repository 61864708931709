import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { studentRetrieveLessons } from "../../../read/application/use-cases/student/lessons-retrieval/retrieve-lessons";
import { selectStudentLessonsRetrievalForList } from "../../../read/application/use-cases/student/lessons-retrieval/selectors/lessons-list-selectors";
import { formatDateToLocale, formatToCurrency } from "../../../../utils/formatting";
import { StudentLesson } from "@academy-context/read/domain/types/student/lesson";
import { ColumnDef } from "@tanstack/react-table";
import { getPlaceName } from "@academy-context/write/domain/services/locations";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";

const columns: ColumnDef<StudentLesson>[] = [
  {
    accessorKey: "creationDate",
    header: () => "Enregistré le",
    cell: ({ row }) => {
      const { creationDate } = row.original;
      const localeDate = formatDateToLocale(creationDate);
      return localeDate;
    },
    meta: {
      title: "Enregistré le",
      width: "95px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "date",
    header: () => "Date du cours",
    cell: ({ row }) => {
      const { date } = row.original;
      const localeDate = formatDateToLocale(date);
      return localeDate;
    },
    meta: {
      title: "Date du cours",
      width: "95px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "teacher",
    header: () => "Professeur",
    cell: ({ row }) => {
      const { teacher } = row.original;
      return teacher;
    },
    meta: {
      title: "Professeur",
    },
  },
  {
    accessorKey: "hours",
    header: () => "Nombre d'heures",
    cell: ({ row }) => {
      const { hours } = row.original;
      return hours;
    },
    meta: {
      title: "Nombre d'heures",
      width: "90px",
    },
  },
  {
    accessorKey: "hourlyPrice",
    header: () => "Tarif horaire professeur",
    cell: ({ row }) => {
      const { hourlyPrice } = row.original;
      return formatToCurrency(hourlyPrice);
    },
    meta: {
      title: "Tarif horaire professeur",
      width: "90px",
    },
  },
  {
    accessorKey: "place",
    header: () => "Studio",
    cell: ({ row }) => {
      const { place } = row.original;
      return getPlaceName(place.paidByTeacher, place.name);
    },
    meta: {
      title: "Studio",
    },
  },
  {
    accessorKey: "place.price",
    header: () => "Tarif horaire studio",
    cell: ({ row }) => {
      const {
        place: { price },
      } = row.original;
      return formatToCurrency(price ?? 0);
    },
    meta: {
      title: "Tarif horaire studio",
      width: "90px",
    },
  },
  {
    accessorKey: "amountChargedOnStudentBalance",
    header: () => "Montant imputé sur le solde de l'élève",
    cell: ({ row }) => {
      const { amountChargedOnStudentBalance } = row.original;
      return formatToCurrency(amountChargedOnStudentBalance);
    },
    meta: {
      title: "Montant imputé sur le solde de l'élève",
      width: "140px",
    },
  },
];

export const StudentLessonList = () => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector(selectStudentLessonsRetrievalForList);

  useEffect(() => {
    dispatch(studentRetrieveLessons());
  }, [dispatch]);

  return (
    <CustomCard title="HISTORIQUE DES COURS">
      <DataTable
        columns={columns}
        data={data}
        sortField="date"
        order="desc"
        searchPlaceHolder="Rechercher un cours"
        displayDataViewOptions
      />
    </CustomCard>
  );
};
