import { useFieldArray, useFormContext } from "react-hook-form";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Button } from "@components/ui/button";
import { Plus, Trash2 } from "lucide-react";
import { Card, CardContent } from "@components/ui/card";
import { RadioGroup, RadioGroupItem } from "@shared-kernel/primary/shared/shadcn/ui/radio-group";
import { EDUCATIONAL_ADVISOR, EDUCATIONAL_ADVISOR_MAP } from "@academy-context/shared/domain/types/enums/education-advisors";
import { Combobox, ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { FormInputs } from "./form-validation/evaluation-assignments";
import PlainTextLexical from "@shared-kernel/primary/shared/text-editor/plain-text/plain-text-lexical";

interface Props {
  teacherOptions: ComboboxOptions[];
  providerOptions: ComboboxOptions[];
  trainingFollowUpId: string;
}

export const NewEvaluationFormComponent = ({ teacherOptions, providerOptions }: Props) => {
  const formMethods = useFormContext<FormInputs>();
  const { control, watch } = formMethods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "newEvaluations",
  });

  const handleAddEvaluation = () => {
    append({
      title: "Nouvel objectif ajouté par l'élève",
      quantifiableObjective: "",
      educationalAdvisorType: EDUCATIONAL_ADVISOR.TEACHER,
      educationalAdvisorId: "",
    });
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-col items-center justify-between space-y-2">
        <div className="text-base font-semibold">
          Pendant votre formation, avez-vous abordé un objectif ne figurant pas dans ce formulaire ?
        </div>
        <Button type="button" variant="outline" onClick={handleAddEvaluation}>
          <Plus className="mr-2 size-4" />
          Ajouter un objectif
        </Button>
      </div>

      {fields.map((field, index) => (
        <Card key={field.id} className="relative">
          <Button type="button" variant="ghost" size="icon" className="absolute right-2 top-2" onClick={() => remove(index)}>
            <Trash2 className="size-4" />
          </Button>
          <CardContent className="pt-6">
            <div className="space-y-4">
              <FormField
                control={control}
                name={`newEvaluations.${index}.quantifiableObjective`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Décrivez votre nouvel objectif</FormLabel>
                    <FormControl>
                      <PlainTextLexical<FormInputs> name={field.name} control={control} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={control}
                name={`newEvaluations.${index}.educationalAdvisorType`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Type d'intervenant</FormLabel>
                    <RadioGroup
                      onValueChange={value => {
                        field.onChange(value);
                        formMethods.setValue(`newEvaluations.${index}.educationalAdvisorId`, "", {
                          shouldDirty: true,
                        });
                      }}
                      value={field.value}
                      className="flex flex-col space-y-1"
                    >
                      {Object.values(EDUCATIONAL_ADVISOR).map(v => (
                        <FormItem className="flex items-center space-x-3 space-y-0" key={v}>
                          <FormControl>
                            <RadioGroupItem value={v} />
                          </FormControl>
                          <FormLabel className="font-normal">{EDUCATIONAL_ADVISOR_MAP[v]}</FormLabel>
                        </FormItem>
                      ))}
                    </RadioGroup>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={control}
                name={`newEvaluations.${index}.educationalAdvisorId`}
                render={({ field }) => {
                  const advisorType = watch(`newEvaluations.${index}.educationalAdvisorType`);
                  const isProvider = advisorType === EDUCATIONAL_ADVISOR.PROVIDER;

                  return (
                    <FormItem>
                      <FormLabel>Sélectionner un intervenant</FormLabel>
                      <FormControl>
                        {isProvider ? (
                          <Combobox
                            options={providerOptions}
                            value={field.value}
                            onChange={value => field.onChange(value)}
                            placeholder="Selectionner prestataire..."
                            search={{
                              notFoundText: "Pas de prestataire trouvé.",
                              commandInputPlaceHolder: "Chercher prestataire...",
                            }}
                          />
                        ) : (
                          <Combobox
                            options={teacherOptions}
                            value={field.value}
                            onChange={value => field.onChange(value)}
                            placeholder="Selectionner professeur..."
                            search={{
                              notFoundText: "Pas de professeur trouvé.",
                              commandInputPlaceHolder: "Chercher professeur...",
                            }}
                          />
                        )}
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};
