import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormInputs,
  schema,
  defaultValues,
  formatFormDataToBodyData,
} from "./form-validation/funding-request-approval/funding-request-approval";
import { useFundingRequestApproved } from "./use-funding-request-approved";
import { RequestState } from "@redux/app-state";
import { LostDataModal } from "@shared-kernel/primary/shared/lost-data-modal/modal";
import { addMonths, subMonths } from "date-fns";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Combobox } from "@shared-kernel/primary/shared/combobox/combobox";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { PopoverCalendar } from "@shared-kernel/primary/shared/calendar/popover-calendar";
import { Equal, Minus } from "lucide-react";
import { useFormBlocker } from "@shared-kernel/primary/shared/lost-data-modal/use-form-blocker";
import { AFDAS_STUDENT_STATUS_LABELS, FUNDER, FUNDER_LABELS } from "@academy-context/shared/domain/types/enums/funders";
import { SubmittedToFunderFundingRequestVM } from "@academy-context/read/domain/types/admin/funding-request";
import { ORGANIZATION_NAME } from "@shared-kernel/domain/organisation";
import { commonAdapters } from "src/common-adapters";
import { ApproveFundingRequestBody } from "@shared-kernel/application/ports/shared/funding-request-repository";

interface Props {
  onSubmit: (body: ApproveFundingRequestBody) => void;
  fundingRequests: SubmittedToFunderFundingRequestVM[];
  processing: RequestState;
}

export const FundingRequestApprovalForm = ({ onSubmit, fundingRequests, processing }: Props) => {
  const { dateProvider } = commonAdapters;
  const now = dateProvider!.now();
  const maxDate = addMonths(now, 6);
  const minDate = subMonths(now, 6);

  const form = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...defaultValues,
      obtentionDate: now.toISOString(),
    },
  });

  const {
    reset,
    watch,
    setValue,
    formState: { isDirty },
  } = form;
  const { isModalOpen, handleCloseModal, handleProceed } = useFormBlocker(isDirty);

  useFundingRequestApproved({ reset });

  const handleOnSubmit = async (formBody: FormInputs) => {
    const body = formatFormDataToBodyData(formBody);
    onSubmit(body);
  };

  const fundingRequestId = watch("fundingRequestId");
  const selectedFundingRequest = fundingRequests.find(r => r.id === fundingRequestId);

  const fundingRequestOptions = useMemo(() => {
    return fundingRequests.map(r => {
      const funder = FUNDER_LABELS[r.funder.type];
      const funderLabel =
        funder + (r.funder.type === FUNDER.AFDAS ? ` (${AFDAS_STUDENT_STATUS_LABELS[r.funder.payload.studentStatus]})` : "");
      return {
        value: r.id,
        label: `${r.student.name} | ${r.educationalAdvisor.name} | ${funderLabel} | ${new Date(r.submissionDate).toLocaleDateString(
          "fr-FR"
        )} | ${ORGANIZATION_NAME[r.organization].short}`,
      };
    });
  }, [fundingRequests]);

  useEffect(() => {
    setValue("externalFundingRequestId", "");
    if (selectedFundingRequest) setValue("operaOffCommission", selectedFundingRequest?.training.operaOffCommission);
  }, [setValue, selectedFundingRequest]);

  return (
    <>
      <LostDataModal isOpen={isModalOpen} onClose={handleCloseModal} onSubmit={handleProceed} />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleOnSubmit)} className="grid w-full grid-cols-2 gap-4">
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="fundingRequestId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Demande de financement</FormLabel>
                  <FormControl>
                    <Combobox
                      options={fundingRequestOptions}
                      value={watch("fundingRequestId")}
                      onChange={value => field.onChange(value)}
                      placeholder="Selectionner demande de financement..."
                      search={{
                        notFoundText: "Pas de demande de financement trouvée.",
                        commandInputPlaceHolder: "Chercher demande de financement...",
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="externalFundingRequestId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Module</FormLabel>
                  <FormControl>
                    <Input {...field} type="text" placeholder="Exemple : IS-0069683-1" value={watch("externalFundingRequestId") ?? ""} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="obtentionDate"
              render={({ field }) => {
                return (
                  <FormItem className="flex flex-col gap-2">
                    <FormLabel>Date d'obtention</FormLabel>
                    <PopoverCalendar
                      value={field.value}
                      onChange={value => field.onChange(value)}
                      maxDate={maxDate}
                      minDate={minDate}
                      now={now}
                    />
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          </div>
          <div className="space-y-4">
            <div className="flex items-end gap-2">
              <FormItem>
                <FormLabel>Financement obtenu</FormLabel>
                <FormControl>
                  <Input defaultValue={selectedFundingRequest?.training.price ?? ""} type="number" disabled />
                </FormControl>
                <FormMessage />
              </FormItem>
              <Minus className="mb-2" />
              <FormField
                control={form.control}
                name="operaOffCommission"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>OPERA Off</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Equal className="mb-2" />
              <FormItem>
                <FormLabel>Total crédité</FormLabel>
                <FormControl>
                  <Input
                    defaultValue={
                      selectedFundingRequest
                        ? selectedFundingRequest.training.price - selectedFundingRequest.training.operaOffCommission
                        : ""
                    }
                    disabled
                    type="number"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            </div>
            <FormItem className="flex flex-col gap-2">
              <FormLabel>Date (de fin de stage)</FormLabel>
              <PopoverCalendar value={selectedFundingRequest?.training.endDate ?? ""} now={now} disabled onChange={() => {}} />
            </FormItem>
            {selectedFundingRequest?.afdasISWaitingPeriodEndDate && (
              <FormItem className="flex flex-col gap-2">
                <FormLabel>Date de fin de carence</FormLabel>
                <PopoverCalendar value={selectedFundingRequest.afdasISWaitingPeriodEndDate} now={now} disabled onChange={() => {}} />
              </FormItem>
            )}
          </div>

          <div className="col-span-3 mt-2 flex justify-end">
            <Button type="submit" disabled={!isDirty || processing === "pending"}>
              Sauvegarder
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};
