import { createReducer } from "@reduxjs/toolkit";
import { adminResetDeleteProviderTraining, adminDeleteProviderTraining } from "./delete-provider-training";
import { ProcessingState } from "@redux/app-state";

const initialState: ProcessingState = { processing: "idle" };

export const adminDeleteProviderTrainingReducer = createReducer<ProcessingState>(initialState, builder => {
  builder.addCase(adminDeleteProviderTraining.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(adminDeleteProviderTraining.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(adminDeleteProviderTraining.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(adminResetDeleteProviderTraining, () => {
    return initialState;
  });
});
