import { createReducer } from "@reduxjs/toolkit";
import { retrieveTrainingFollowUpAttendanceSheets } from "./retrieve-training-follow-up-attendance-sheets";
import { TrainingFollowUpAttendanceSheet } from "@academy-context/read/domain/types/training-follow-up";
import { RetrievalState } from "@redux/app-state";

const initialState: RetrievalState<TrainingFollowUpAttendanceSheet[]> = { fetching: "idle", data: [] };

export const retrieveTrainingFollowUpAttendanceSheetsReducer = createReducer<RetrievalState<TrainingFollowUpAttendanceSheet[]>>(
  initialState,
  builder => {
    builder.addCase(retrieveTrainingFollowUpAttendanceSheets.fulfilled, (state, { payload }) => {
      return {
        ...state,
        data: payload,
        fetching: "success",
      };
    });
    builder.addCase(retrieveTrainingFollowUpAttendanceSheets.pending, state => {
      return {
        ...state,
        fetching: "pending",
      };
    });
    builder.addCase(retrieveTrainingFollowUpAttendanceSheets.rejected, state => {
      return {
        ...state,
        fetching: "failed",
      };
    });
  }
);
