import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@shared-kernel/primary/shared/shadcn/ui/dialog";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { SubmitBatchParticipationInvoiceBody } from "@user-management-context/shared/application/ports/provider-repository";
import { ProviderBatchItem } from "@academy-context/read/domain/types/shared/provider-batch";
import {
  BatchParticipationInvoiceFormInputs,
  batchParticipationInvoiceSchema,
  defaultBatchParticipationInvoiceValues,
  formatFormDataToBodyData,
  formatParticipationDataToFormData,
} from "@academy-context/primary/provider/provider-batches/form-validation/batch-participation-invoice/batch-participation-invoice";
import { UploadButton } from "@shared-kernel/primary/shared/upload-button/upload-button";
import { INVOICE_FILETYPE, isValidInvoiceFile } from "@academy-context/write/domain/constants/shared";
import { ArrowUpRightFromCircle } from "lucide-react";
import { useAppDispatch } from "@redux/hooks";
import { ProviderBatchParticipationInvoiceUploadState } from "@redux/app-state";
import { getFileProps } from "@utils/utils";

interface Props {
  participation: ProviderBatchItem["participations"][number];
  isOpen: boolean;
  onClose: () => void;
  onSubmitBatchParticipationInvoice: (body: SubmitBatchParticipationInvoiceBody) => void;
  uploadedInvoice: ProviderBatchParticipationInvoiceUploadState;
  onUploadInvoice: (invoice: File) => void;
  onResetUploadInvoice: () => void;
}

export const SubmitBatchParticipationInvoiceModal = ({
  isOpen,
  onClose,
  participation,
  onSubmitBatchParticipationInvoice,
  uploadedInvoice,
  onUploadInvoice,
  onResetUploadInvoice,
}: Props) => {
  const { providerBatchParticipationInvoiceUrl: invoiceUrl, processing: invoiceProcessing } = uploadedInvoice;
  const dispatch = useAppDispatch();
  const form = useForm<BatchParticipationInvoiceFormInputs>({
    resolver: yupResolver(batchParticipationInvoiceSchema),
    defaultValues: defaultBatchParticipationInvoiceValues,
  });
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
    watch,
    clearErrors,
    setError,
    setValue,
  } = form;

  useEffect(() => {
    onResetUploadInvoice();
  }, [dispatch, isOpen, onResetUploadInvoice]);

  useEffect(() => {
    if (invoiceUrl) {
      setValue("invoiceUrl", invoiceUrl, { shouldDirty: true });
    }
  }, [invoiceUrl, setValue]);

  const handleOnSubmit = async (formBody: BatchParticipationInvoiceFormInputs) => {
    const body = formatFormDataToBodyData({ ...formBody, participationId: participation.id });
    onSubmitBatchParticipationInvoice(body);
    onClose();
  };

  useEffect(() => {
    reset(formatParticipationDataToFormData(participation));
  }, [participation, reset, isOpen]);

  const handleInvoiceUrlChange = (invoice: File) => {
    clearErrors("invoiceUrl");
    onUploadInvoice(invoice);
  };

  const handleInvoiceUrlError = () => {
    setError("invoiceUrl", { type: "manual", message: "Veuillez télécharger un fichier PDF de 10 Mo max" });
  };

  const invoiceFromForm = watch("invoiceUrl");
  const invoice = invoiceUrl || invoiceFromForm;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:min-w-[425px]" onPointerDownOutside={onClose}>
        <DialogHeader>
          <DialogTitle>Joindre facture</DialogTitle>
          <DialogDescription>
            Ajoutez la facture de la session pour : {participation.student.name} {participation.student.lastName}
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="grid gap-4 py-4">
              <FormField
                control={form.control}
                name="price"
                render={({ field }) => (
                  <FormItem className="col-span-3 flex flex-col">
                    <FormLabel>Prix</FormLabel>
                    <FormControl>
                      <Input type="number" min={0} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="invoiceNumber"
                render={({ field }) => (
                  <FormItem className="col-span-3">
                    <FormLabel>Numéro de facture</FormLabel>
                    <FormControl>
                      <Input type="text" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="invoiceUrl"
                render={() => (
                  <FormItem className="col-span-3 flex flex-col">
                    <div className="flex items-center">
                      <UploadButton
                        label="Ajouter une facture (PDF)"
                        processing={invoiceProcessing}
                        onChange={handleInvoiceUrlChange}
                        onError={handleInvoiceUrlError}
                        types={INVOICE_FILETYPE}
                        value={invoice}
                        validatorFunc={isValidInvoiceFile}
                        getFileProps={getFileProps}
                      />
                      {invoice && (
                        <a href={invoice} target="_blank" rel="noreferrer" className="ml-2 flex flex-col items-center">
                          <span className="text-sm font-normal">Aperçu</span>
                          <ArrowUpRightFromCircle size={18} />
                        </a>
                      )}
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <DialogFooter>
              <Button onClick={onClose} variant="outline" type="button">
                Annuler
              </Button>
              <Button type="submit" disabled={!isDirty}>
                Envoyer la facture
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
