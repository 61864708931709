import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { ProviderBatchItem } from "@academy-context/read/domain/types/shared/provider-batch";

export const adminRetrieveAllProviderBatches = createAsyncThunk<ProviderBatchItem[], string[], { extra: Partial<Dependencies> }>(
  "providers/adminRetrieveAllProviderBatches",
  async (providerIds: string[], { extra: { adminProviderRepository } }) => {
    const batchPromises = providerIds.map(id => adminProviderRepository!.batchList(id));
    const batchLists = await Promise.all(batchPromises);
    return batchLists.flat();
  }
);

export const resetAdminRetrieveAllProviderBatches = createAction("providers/resetAdminRetrieveAllProviderBatches");
