import { createReducer } from "@reduxjs/toolkit";
import { ProcessingState } from "@redux/app-state";
import { inviteStudent } from "./invite-student";

const initialState: ProcessingState = {
  processing: "idle",
};

export const inviteStudentReducer = createReducer<ProcessingState>(initialState, builder => {
  builder.addCase(inviteStudent.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(inviteStudent.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(inviteStudent.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
});
