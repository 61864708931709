import { ReactElement, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { ROLE_BASED_URLS } from "src/routes";
import { ORGANIZATION } from "@shared-kernel/domain/organisation";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Badge } from "@shared-kernel/primary/shared/shadcn/ui/badge";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { statusFormatter } from "@shared-kernel/primary/shared/student-status-formatter";
import { formatDateToLocale, formatToCurrency } from "../../../../utils/formatting";
import { retrieveStudents } from "@user-management-context/read/application/use-cases/admin/students-retrieval/retrieve-students";
import {
  AdminStudentListItem,
  selectStudents,
} from "@user-management-context/read/application/use-cases/admin/students-retrieval/selectors/students-selectors";

type CellValue = string | ReactElement;

const linkWrapper = (id: string, value: Nullable<CellValue>, isBlacklisted: boolean) => {
  const textStyle = isBlacklisted ? "font-extrabold text-theme" : "";
  return (
    <Link to={`${ROLE_BASED_URLS[ROLES.ADMIN].student.list}/${id}`}>
      <div className="size-full">{typeof value === "string" ? <span className={textStyle}>{value}</span> : value}</div>
    </Link>
  );
};

const valueWrapper = (student: AdminStudentListItem, value: Nullable<CellValue>) => {
  return linkWrapper(student.studentId, value, student.isBlacklisted);
};

const columns: ColumnDef<AdminStudentListItem>[] = [
  {
    accessorKey: "userStatus",
    header: () => "Statut",
    cell: info => statusFormatter(info.row.original),
    enableSorting: true,
    meta: {
      title: "Statut",
      width: "70px",
    },
  },
  {
    accessorKey: "name",
    header: () => "Prénom",
    cell: info => valueWrapper(info.row.original, info.row.original.name),
    sortUndefined: 1,
    enableSorting: true,
    meta: {
      title: "Prénom",
    },
  },
  {
    accessorKey: "lastName",
    header: () => "Nom de famille",
    cell: info => valueWrapper(info.row.original, info.row.original.lastName),
    sortUndefined: 1,
    enableSorting: true,
    meta: {
      title: "Nom de famille",
    },
  },
  {
    accessorKey: "balance.global",
    header: () => "Solde",
    cell: info => {
      const cellValue = info.row.original.balance.global;
      return valueWrapper(info.row.original, formatToCurrency(cellValue));
    },
    enableSorting: true,
    sortUndefined: 1,
    meta: {
      title: "Solde",
      width: "90px",
    },
  },
  {
    accessorKey: `balance.byOrganization.${ORGANIZATION.OPERA_OFF}`,
    header: () => "Solde OO",
    cell: info => {
      const cellValue = info.row.original.balance.byOrganization[ORGANIZATION.OPERA_OFF];
      return valueWrapper(info.row.original, formatToCurrency(cellValue));
    },
    enableSorting: true,
    sortUndefined: 1,
    meta: {
      title: "Solde OO",
      width: "90px",
    },
  },
  {
    accessorKey: `balance.byOrganization.${ORGANIZATION.LES_ATELIERS_OO}`,
    header: () => "Solde LAOO",
    cell: info => {
      const cellValue = info.row.original.balance.byOrganization[ORGANIZATION.LES_ATELIERS_OO];
      return valueWrapper(info.row.original, formatToCurrency(cellValue));
    },
    enableSorting: true,
    sortUndefined: 1,
    meta: {
      title: "Solde LAOO",
      width: "90px",
    },
  },
  {
    accessorKey: "nextFundingDate",
    header: () => "Fin de carence",
    cell: info => {
      const cellValue = info.row.original.nextFundingDate;
      const value = cellValue ? formatDateToLocale(cellValue) : null;
      return valueWrapper(info.row.original, value);
    },
    sortUndefined: 1,
    enableSorting: true,
    meta: {
      title: "Fin de carence",
      width: "95px",
    },
  },
  {
    accessorKey: "email",
    header: () => "Email",
    cell: info => valueWrapper(info.row.original, info.row.original.email),
    enableSorting: true,
    meta: {
      title: "Email",
    },
  },
  {
    accessorKey: "admin.name",
    header: () => "Responsable",
    cell: info => {
      const admin = info.row.original.admin;
      if (!admin) return null;
      const isBrigitteAdmin = admin.id === "4023586c-109f-4712-b7b5-851928304260";
      if (isBrigitteAdmin) return null;
      const badge = <Badge className="bg-green-100 text-green-800 hover:bg-green-200">{admin.name}</Badge>;
      return valueWrapper(info.row.original, badge);
    },
    enableSorting: true,
    meta: {
      title: "Responsable",
    },
  },
];

export const StudentsList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { students } = useAppSelector(selectStudents);

  useEffect(() => {
    dispatch(retrieveStudents());
  }, [dispatch]);

  const onNewStudent = () => {
    navigate(ROLE_BASED_URLS[ROLES.ADMIN].student.create);
  };

  return (
    <CustomCard
      title="Elèves"
      headerChildren={
        <div>
          <Button onClick={onNewStudent}>Nouvel élève</Button>
        </div>
      }
    >
      <DataTable
        columns={columns}
        data={students}
        sortField="balance_global"
        order="asc"
        searchPlaceHolder="Rechercher un élève"
        pageSize={50}
      />
    </CustomCard>
  );
};
