import { createReducer } from "@reduxjs/toolkit";
import { adminRetrieveAdmins } from "./retrieve-admin-list";
import { Admin } from "@user-management-context/read/domain/types/admin/admin";
import { RetrievalState } from "@redux/app-state";

const initialState: RetrievalState<Admin[]> = { data: [], fetching: "idle" };

export const adminRetrieveAdminsReducer = createReducer<RetrievalState<Admin[]>>(initialState, builder => {
  builder.addCase(adminRetrieveAdmins.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(adminRetrieveAdmins.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(adminRetrieveAdmins.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
