import { createReducer } from "@reduxjs/toolkit";
import { RetrievalState } from "@redux/app-state";
import { retrieveConventionTransferList } from "./retrieve-convention-transfer-list";
import { ConventionPaymentVM } from "@academy-context/read/domain/types/admin/payment";

const initialState: RetrievalState<ConventionPaymentVM[]> = { fetching: "idle", data: [] };

export const retrieveConventionTransferListReducer = createReducer<RetrievalState<ConventionPaymentVM[]>>(initialState, builder => {
  builder.addCase(retrieveConventionTransferList.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveConventionTransferList.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveConventionTransferList.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
