import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { TeacherVM } from "@user-management-context/read/domain/types/student/teacher";
import { ProviderVM } from "@user-management-context/read/domain/types/student/provider";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { resetRetrieveTrainingFollowUpEvaluations } from "@academy-context/read/application/use-cases/shared/training-follow-up-evaluations-retrieval/retrieve-training-follow-up-evaluations";
import { AppState } from "@redux/app-state";
import { studentRetrieveTrainingFollowUps } from "@academy-context/read/application/use-cases/student/training-follow-ups-retrieval/retrieve-training-follow-ups";
import { resetRetrieveTrainingFollowUpSurvey } from "@academy-context/read/application/use-cases/student/training-follow-up-survey/retrieve-training-follow-up-survey";
import { useNavigate } from "react-router-dom";
import { resetAssignEducationalAdvisorToEvaluations } from "@academy-context/write/application/use-cases/student/training-follow-up-evaluation-assignment/assign-educational-advisor-to-evaluations";

interface Props {
  trainingFollowUpId: string;
  teachers: TeacherVM[];
  providers: ProviderVM[];
}

export const useEvaluationAssignment = ({ trainingFollowUpId, teachers, providers }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { processing } = useAppSelector((state: AppState) => state.trainingFollowUpEvaluationsAssignment);

  const teacherOptions: ComboboxOptions[] = useMemo(
    () => teachers.map(t => ({ label: `${t.name} ${t.lastName}`, value: t.id })),
    [teachers]
  );

  const providerOptions: ComboboxOptions[] = useMemo(() => providers.map(p => ({ label: p.name, value: p.id })), [providers]);

  useEffect(() => {
    if (processing === "success") {
      toast({ description: "Intervenant(s) assigné(s) avec succès !" });
      dispatch(resetRetrieveTrainingFollowUpSurvey());
      dispatch(resetRetrieveTrainingFollowUpEvaluations());
      dispatch(resetAssignEducationalAdvisorToEvaluations());
      navigate("/training-follow-ups");
      // Used to display correct status on teacher / student sidebar
      dispatch(studentRetrieveTrainingFollowUps());
    } else if (processing === "failed") {
      toast({ description: "Erreur lors de l'assignation des intervenants", variant: "destructive" });
    }
  }, [processing, dispatch, trainingFollowUpId, toast, navigate]);

  return {
    teacherOptions,
    providerOptions,
    processing,
  };
};
