import { FundingRequestEligibilityVM } from "@academy-context/read/domain/types/student/funding-request";
import { CreateFundingRequestBody } from "@shared-kernel/application/ports/shared/funding-request-repository";
import { FundingRequest } from "@academy-context/read/domain/types/student/funding-request";
import { FundingRequestRepository } from "../../../application/ports/shared/funding-request-repository";
import { v4 as uuidv4 } from "uuid";
import { FundingRequestsVM, FundingRequestVM } from "@academy-context/read/domain/types/admin/funding-request";
import { DAY_BLOCK, TRAINING_DAYS_CONFIG_MODE } from "@academy-context/read/domain/types/shared/training-day";
import { EDUCATIONAL_ADVISOR } from "@academy-context/shared/domain/types/enums/education-advisors";
import { FUNDER } from "@academy-context/shared/domain/types/enums/funders";
import { TRAINING_LEVEL } from "@academy-context/shared/domain/types/enums/training-levels";
import { ORGANIZATION } from "@shared-kernel/domain/organisation";
import { fundingRequestId } from "src/test/utils/common";
import { StudentFundingListVM } from "@academy-context/read/domain/types/student/funding";

const trainingDays = [
  { date: "2023-01-16 22:49:42.501+01", block: DAY_BLOCK.MORNING },
  { date: "2023-01-17 22:49:42.501+01", block: DAY_BLOCK.MORNING },
  { date: "2023-01-18 22:49:42.501+01", block: DAY_BLOCK.MORNING },
  { date: "2023-01-19 22:49:42.501+01", block: DAY_BLOCK.MORNING },
  { date: "2023-01-20 22:49:42.501+01", block: DAY_BLOCK.MORNING },
  { date: "2023-01-21 22:49:42.501+01", block: DAY_BLOCK.MORNING },
  { date: "2023-01-22 22:49:42.501+01", block: DAY_BLOCK.MORNING },
  { date: "2023-01-23 22:49:42.501+01", block: DAY_BLOCK.MORNING },
  { date: "2023-01-24 22:49:42.501+01", block: DAY_BLOCK.MORNING },
  { date: "2023-01-25 22:49:42.501+01", block: DAY_BLOCK.MORNING },
];

export class InMemoryFundingRequestRepository implements FundingRequestRepository {
  private _shouldThrow: boolean = false;
  private _fundingRequests: Record<string, FundingRequest> = {};
  private _lastFundingRequestCreationParams?: CreateFundingRequestBody;
  private _nextId: string = "";

  async create(fundingRequest: CreateFundingRequestBody): Promise<string> {
    this._nextId = this._nextId || uuidv4();
    this._lastFundingRequestCreationParams = fundingRequest;
    if (this._shouldThrow) {
      this.throw();
    }

    this.fundingRequests[this._nextId] = {
      id: this._nextId,
      educationalAdvisor: fundingRequest.educationalAdvisor,
      resumeUrl: fundingRequest.resumeUrl,
      bioUrl: fundingRequest.bioUrl,
      coverLetter: fundingRequest.coverLetter,
      personalizedEducationalProject: fundingRequest.personalizedEducationalProject,
      providerBatchId: fundingRequest.providerBatchId,
    };

    return this._nextId;
  }

  async uploadDocument(): Promise<string> {
    if (this._shouldThrow) {
      this.throw();
    }
    return `https://test.com/document`;
  }

  async fundingRequestEligibility(): Promise<FundingRequestEligibilityVM> {
    return {
      isEligibleForAFundingRequest: true,
    };
  }

  async getAll(): Promise<FundingRequestsVM> {
    return [];
  }

  async review(): Promise<void> {
    if (this._shouldThrow) {
      this.throw();
    }
  }

  async get(): Promise<FundingRequestVM> {
    return {
      id: fundingRequestId,
      student: { fullName: "Marc Siebert", id: "student-id" },
      educationalAdvisor: { id: "2cedadc4-a511-48a7-9737-6a351e80c5fa", type: EDUCATIONAL_ADVISOR.TEACHER },
      resumeUrl: "https://test.com/siebert_marc/demandes_de_financement/2022-02-01T12:00:00.000Z_123abc/cv.pdf",
      bioUrl: "https://test.com/siebert_marc/demandes_de_financement/2022-02-01T12:00:00.000Z_123abc/bio.pdf",
      coverLetter: "<p>Je suis très chaud</br> Mais vraiment <strong>chaud chaud</strong></p>",
      personalizedEducationalProject: "<p>J'adore le chant</p>",
      internalTrainingId: "technique-vocale",
      providerBatch: null,
      administrativeInternalTrainingId: null,
      address: "111 rue Vaugirard",
      additionalAddress: "75006 Paris",
      reviewedCoverLetter: "Je suis très motivé",
      reviewedPersonalizedEducationalProject: [
        {
          title: "travail de technique vocale",
          quantifiableObjective: "Je veux atteindre un niveau de chant professionnel",
        },
      ],
      specificAccommodation: "Fauteuil roulant",
      trainingPrice: 2200,
      trainingDaysConfig: {
        mode: TRAINING_DAYS_CONFIG_MODE.MANUAL,
        trainingDays: trainingDays,
        interval: {
          start: trainingDays[0]!.date,
          end: trainingDays[trainingDays.length - 1]!.date,
        },
        halfDays: trainingDays.length,
        hours: trainingDays.length * 4, // Assuming each block is 4 hours
      },

      status: "to_review",
      funder: {
        type: FUNDER.ATLAS,
      },
      reviewedEducationalAdvisor: { id: "reviewed-advisor-id", type: EDUCATIONAL_ADVISOR.TEACHER },
      reviewedInternalTrainingId: "reviewed-training-id",
      reviewedProviderBatchId: "reviewed-batch-id",
      reviewedSpecificAccommodation: "reviewed-accommodation",
      secondaryTeachers: [],
      questions: [],
      level: TRAINING_LEVEL.BEGINNER,
      travelExpenses: 100,
      operaOffCommission: 200,
      adminCommentary: "Commentaire de l'admin",
      organization: ORGANIZATION.LES_ATELIERS_OO,
      monthlyLessonsCount: 4,
    };
  }

  async delete(fundingRequestId: string): Promise<void> {
    delete this.fundingRequests[fundingRequestId];
  }

  async submitToFunder(): Promise<void> {
    if (this._shouldThrow) {
      this.throw();
    }
  }

  async updateErrorReason(): Promise<void> {
    if (this._shouldThrow) {
      this.throw();
    }
  }
  async revertFundingRequestToReview(): Promise<void> {
    if (this._shouldThrow) {
      this.throw();
    }
  }

  async update(): Promise<void> {
    if (this._shouldThrow) {
      this.throw();
    }
  }

  async approve(): Promise<void> {
    throw new Error("Method not implemented.");
  }

  async studentGetAllFundings(): Promise<StudentFundingListVM[]> {
    throw new Error("Method not implemented.");
  }

  get fundingRequests() {
    return this._fundingRequests;
  }

  set nextId(id: string) {
    this._nextId = id;
  }

  get lastFundingRequestCreationParams() {
    return this._lastFundingRequestCreationParams;
  }

  feedWith(...fundingRequests: FundingRequest[]): void {
    for (const fundingRequest of fundingRequests) {
      this._fundingRequests[fundingRequest.id] = fundingRequest;
    }
  }

  shouldThrow() {
    this._shouldThrow = true;
  }

  throw() {
    throw new Error("An error occured");
  }
}
