import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { TrainingFollowUpEvaluation } from "../../../../domain/types/training-follow-up";

export const retrieveTrainingFollowUpEvaluations = createAsyncThunk<
  TrainingFollowUpEvaluation[],
  { trainingId: string },
  { extra: Partial<Dependencies> }
>(
  "trainingFollowUps/retrieveTrainingFollowUpEvaluations",
  async ({ trainingId }: { trainingId: string }, { extra: { trainingFollowUpGateway } }) => {
    return trainingFollowUpGateway!.getTrainingFollowUpEvaluations(trainingId);
  }
);

export const resetRetrieveTrainingFollowUpEvaluations = createAction("training-follow-ups/resetRetrieveTrainingFollowUpEvaluations");
