import { ReactElement, useEffect } from "react";
import { Link } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { ROLE_BASED_URLS } from "src/routes";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { adminRetrieveProviders } from "@user-management-context/read/application/use-cases/admin/providers-retrieval/retrieve-providers";
import { adminRetrieveAllProviderBatches } from "@academy-context/read/application/use-cases/admin/provider-batches-retrieval/retrieve-all-provider-batches";
import { selectProvidersRetrievalForList } from "@user-management-context/read/application/use-cases/admin/providers-retrieval/selectors/provider-list-selectors";
import { selectAllRunningBatches } from "@academy-context/read/application/use-cases/admin/provider-batches-retrieval/selectors/all-provider-batches-selector";
import { CompactProviderBatch } from "./compact-provider-batch.component";
import { ProviderListItem } from "@user-management-context/read/domain/types/admin/provider";
import { ProviderListSkeleton } from "@user-management-context/primary/admin/provider-list/provider-list.skeleton";
import { Separator } from "@shared-kernel/primary/shared/shadcn/ui/separator";

type CellValue = string | ReactElement;

const linkWrapper = (id: string, value: CellValue) => {
  return (
    <Link to={`${ROLE_BASED_URLS[ROLES.ADMIN].provider.providerListForBatches}/${id}`}>
      <div className="size-full">{value}</div>
    </Link>
  );
};

const columns: ColumnDef<ProviderListItem>[] = [
  {
    accessorKey: "name",
    header: () => "Nom",
    cell: info => linkWrapper(info.row.original.id, info.row.original.name),
    enableSorting: true,
    meta: {
      title: "Nom",
    },
  },
  {
    accessorKey: "tag",
    header: () => "Tag",
    cell: info => linkWrapper(info.row.original.id, info.row.original.tag),
    enableSorting: true,
    meta: {
      title: "Tag",
    },
  },
];

export const ProviderListForBatches = () => {
  const dispatch = useAppDispatch();
  const { providers, isLoading } = useAppSelector(selectProvidersRetrievalForList);

  useEffect(() => {
    dispatch(adminRetrieveProviders());
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading && providers.length > 0) {
      dispatch(adminRetrieveAllProviderBatches(providers.map(p => p.id)));
    }
  }, [dispatch, isLoading, providers]);

  const { batchesByProvider, isLoading: batchesLoading } = useAppSelector(selectAllRunningBatches);

  return (
    <>
      <CustomCard title="Prestataires">
        {isLoading ? (
          <ProviderListSkeleton />
        ) : (
          <DataTable
            columns={columns}
            data={providers}
            searchPlaceHolder="Rechercher un prestataire"
            order="asc"
            sortField="name"
            pageSize={50}
          />
        )}
      </CustomCard>

      <CustomCard title="Sessions en cours" cardClassname="mt-4">
        {batchesLoading ? (
          <ProviderListSkeleton />
        ) : batchesByProvider.length === 0 ? (
          <div className="p-4 text-center text-gray-500">Aucune session en cours</div>
        ) : (
          <div className="space-y-6">
            {batchesByProvider.map(({ providerId, providerName, batches }) => (
              <div key={providerId} className="space-y-2">
                <Link
                  to={`${ROLE_BASED_URLS[ROLES.ADMIN].provider.providerListForBatches}/${providerId}`}
                  className="block hover:underline"
                >
                  <h3 className="text-lg font-semibold">{providerName}</h3>
                </Link>
                <Separator className="my-2" />
                <div className="space-y-2">
                  {batches.map(batch => (
                    <CompactProviderBatch
                      key={batch.id}
                      batch={batch}
                      linkTo={`${ROLE_BASED_URLS[ROLES.ADMIN].provider.providerListForBatches}/${providerId}`}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </CustomCard>
    </>
  );
};
