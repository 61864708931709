import { formatDateToLocale, formatToCurrency } from "../../../../../../utils/formatting";
import { getPlaceName } from "../../../../../write/domain/services/locations";
import { LessonExtended } from "../../../../../read/application/use-cases/admin/payment-lesson-list-retrieval/selectors/payment-lesson-list-selectors";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@shared-kernel/primary/shared/shadcn/ui/dialog";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { SimpleTable } from "../../../../../../shared-kernel/primary/shared/simple-table";

interface Props {
  lessons: LessonExtended[];
  isOpen: boolean;
  onClose: () => void;
}

interface TableProps {
  data: LessonExtended[];
}

const LessonTable = ({ data }: TableProps) => {
  const headers = [
    { title: "Enregistré le" },
    { title: "Date du cours" },
    { title: "Elève" },
    { title: "Professeur" },
    { title: "Nombre d'heures" },
    { title: "Tarif horaire" },
    { title: "Studio" },
    { title: "Tarif horaire studio" },
    { title: "Règlement professeur" },
  ];
  const entries = data.map(d => {
    return [
      { value: formatDateToLocale(d.creationDate) },
      { value: formatDateToLocale(d.date) },
      { value: d.student },
      { value: d.teacher },
      { value: d.hours },
      { value: formatToCurrency(d.hourlyPrice ?? 0) },
      { value: getPlaceName(d.place.paidByTeacher, d.place.name) },
      { value: formatToCurrency(d.place.price ?? 0) },
      { value: formatToCurrency(d.paidToTeacher) },
    ];
  });
  return <SimpleTable headers={headers} entries={entries} />;
};

export const LessonListModal = ({ lessons, isOpen, onClose }: Props) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent onPointerDownOutside={onClose}>
        <DialogHeader>
          <DialogTitle>Liste des cours</DialogTitle>
          <DialogDescription />
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <LessonTable data={lessons} />
        </div>
        <DialogFooter>
          <Button onClick={onClose} type="button" variant="outline">
            Fermer
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
