import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { StandardPayment } from "../../../../../read/domain/types/admin/payment";
import { UpdateTransferModal } from "./update-transfer-modal";
import { formatDateToLocale, formatToCurrency } from "../../../../../../utils/formatting";
import { LessonListModal } from "./lesson-list-modal";
import { retrievePaymentLessonList } from "../../../../../read/application/use-cases/admin/payment-lesson-list-retrieval/retrieve-payment-lesson-list";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { selectPaymentLessonListRetrieval } from "../../../../../read/application/use-cases/admin/payment-lesson-list-retrieval/selectors/payment-lesson-list-selectors";
import { ColumnDef } from "@tanstack/react-table";
import { CustomCard } from "../../../../../../shared-kernel/primary/shared/custom-card/custom-card";
import { Checkbox } from "@shared-kernel/primary/shared/shadcn/ui/checkbox";
import { Check, Eye, Hourglass } from "lucide-react";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { PaymentStatus } from "@academy-context/read/domain/types/enums/payment-status";
import { Badge } from "@components/ui/badge";
import { selectTransfersRetrievalForList } from "@academy-context/read/application/use-cases/admin/admin-transfer-list-retrieval/selectors/transfers-list-selectors";
import { DataTableFilter } from "@components/ui/data-table-toolbar";
import { ORGANIZATION, ORGANIZATION_NAME } from "@shared-kernel/domain/organisation";

type SelectorReturnType = ReturnType<typeof selectTransfersRetrievalForList>["data"][number];

const columns: ColumnDef<SelectorReturnType>[] = [
  // This is a special case where the sort must be based on a value and filtering on another value
  {
    id: "from",
    accessorFn: row => row.month,
    cell: ({ row }) => row.original.month,
    enableSorting: true,
    sortingFn: (a, b) => {
      const fromA = a.original.from;
      const fromB = b.original.from;
      return fromA.localeCompare(fromB);
    },
    meta: {
      title: "Date",
      width: "85px",
    },
  },
  {
    accessorKey: "teacher.fullName",
    header: () => "Professeur",
    cell: info => {
      const { teacher } = info.row.original;
      return (
        <div className="flex items-center space-x-1">
          <div>{teacher.fullName}</div>
          {teacher.badges.map(badge => (
            <Badge className="h-5 bg-green-500 hover:bg-green-500" key={teacher.id + badge}>
              {badge}
            </Badge>
          ))}
        </div>
      );
    },
    meta: {
      title: "Professeur",
    },
  },
  {
    accessorKey: "amount.global",
    header: () => "Montant à verser",
    cell: ({ row }) => formatToCurrency(row.original.amount.global),
    meta: {
      title: "Montant à verser",
      width: "80px",
    },
    enableSorting: true,
  },
  {
    accessorKey: `amount.byOrganization.${ORGANIZATION.OPERA_OFF}`,
    header: () => "ΣOO",
    cell: ({ row }) => formatToCurrency(row.original.amount.byOrganization[ORGANIZATION.OPERA_OFF]),
    meta: {
      title: "ΣOO",
      width: "80px",
    },
    enableSorting: true,
  },
  {
    accessorKey: `amount.byOrganization.${ORGANIZATION.LES_ATELIERS_OO}`,
    header: () => "ΣLAOO",
    cell: ({ row }) => formatToCurrency(row.original.amount.byOrganization[ORGANIZATION.LES_ATELIERS_OO]),
    meta: {
      title: "ΣLAOO",
      width: "80px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "amountPaid",
    header: () => "Montant versé",
    cell: ({ row }) => (row.original.amountPaid != null ? formatToCurrency(row.original.amountPaid) : ""),
    meta: {
      title: "Montant versé",
      width: "80px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "organization",
    header: () => "Société",
    cell: ({ row }) => {
      const { organization } = row.original;
      return organization ? ORGANIZATION_NAME[organization].short : null;
    },
    meta: {
      title: "Société",
      width: "70px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "reference",
    header: () => "Référence du virement",
    meta: {
      title: "Référence du virement",
      width: "80px",
    },
  },
  {
    accessorKey: "paymentDate",
    header: () => "Date du virement",
    cell: ({ row }) => {
      const value = row.original.paymentDate;
      return value ? formatDateToLocale(value) : null;
    },
    meta: {
      title: "Date du virement",
      width: "90px",
    },
    enableSorting: true,
  },
];

const statuses = [
  {
    value: PaymentStatus.PAID,
    label: "Payé",
    icon: Check,
  },
  {
    value: PaymentStatus.PENDING,
    label: "À payer",
    icon: Hourglass,
  },
];

interface Props {
  transfers: SelectorReturnType[];
  title: string;
  cardClassname?: string;
}

export const TransfersTable = ({ transfers, title }: Props) => {
  const dispatch = useAppDispatch();
  const [dataColumns, setDataColumns] = useState<ColumnDef<SelectorReturnType>[]>([...columns]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const currentSelectedLineInfo = useRef<StandardPayment | null>(null);
  const currentSelectedLineIsChecked = useRef<boolean>(false);
  const [isLessonListModalOpen, setIsLessonListModalOpen] = useState<boolean>(false);
  const selectedPaymentId = useRef<string | null>(null);
  const { data: lessons } = useAppSelector(selectPaymentLessonListRetrieval);
  const [filters, setFilters] = useState<DataTableFilter[]>([]);

  useEffect(() => {
    if (isLessonListModalOpen && selectedPaymentId.current) {
      dispatch(retrievePaymentLessonList(selectedPaymentId.current));
    } else {
      selectedPaymentId.current = null;
    }
  }, [dispatch, isLessonListModalOpen]);

  useEffect(() => {
    const statusColumn: ColumnDef<SelectorReturnType> = {
      accessorKey: "status",
      header: () => "Vir. effectué",
      meta: {
        title: "Vir. effectué",
        width: "75px",
      },
      enableSorting: true,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      cell: ({ row }) => {
        const transfer = row.original;
        const isPaid = row.original.status === PaymentStatus.PAID;
        return (
          <Checkbox
            id={transfer.id}
            defaultChecked={isPaid}
            onClick={(event: SyntheticEvent) => {
              // Prevents checkbox from being checked/unchecked
              event.preventDefault();
              setIsModalOpen(true);
              currentSelectedLineInfo.current = transfer;
              currentSelectedLineIsChecked.current = isPaid;
            }}
          />
        );
      },
    };
    // Added here because column is added above. Without the column it causes a visual console error (not important though)
    setFilters([
      {
        label: "Statut",
        value: "status",
        options: statuses,
      },
    ]);
    const lessonListPreviewColumn: ColumnDef<SelectorReturnType> = {
      accessorKey: "id",
      header: () => "Cours",
      enableSorting: false,
      cell: ({ row }) => (
        <div className="flex justify-center">
          <Eye
            className="cursor-pointer px-1 py-0"
            onClick={() => {
              selectedPaymentId.current = row.original.id;
              setIsLessonListModalOpen(true);
            }}
          />
        </div>
      ),
      meta: {
        width: "50px",
      },
    };
    setDataColumns([...columns, statusColumn, lessonListPreviewColumn]);
  }, [transfers]);

  return (
    <CustomCard title={title}>
      {currentSelectedLineInfo.current && (
        <UpdateTransferModal
          transfer={currentSelectedLineInfo.current}
          isPaid={currentSelectedLineIsChecked.current}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <LessonListModal lessons={lessons} isOpen={isLessonListModalOpen} onClose={() => setIsLessonListModalOpen(false)} />
      <DataTable
        columns={dataColumns}
        data={transfers}
        sortField="from"
        order="desc"
        searchPlaceHolder="Rechercher un paiement"
        pageSize={50}
        displayDataViewOptions
        filters={filters}
      />
    </CustomCard>
  );
};
