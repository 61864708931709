import { InitialConfigType, LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { AutoLinkPlugin, createLinkMatcherWithRegExp } from "@lexical/react/LexicalAutoLinkPlugin";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { $getRoot, $insertNodes, $setSelection, LexicalEditor } from "lexical";
import "./rich-text-lexical.module.scss";
import { $generateNodesFromDOM } from "@lexical/html";
import { cn } from "@components/utils/utils";
import { LinkNode, AutoLinkNode } from "@lexical/link";
import { ClickableLinkPlugin } from "@lexical/react/LexicalClickableLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { ListItemNode, ListNode } from "@lexical/list";
import { ToolbarPlugin } from "../plugins/toolbar";
import { DisabledPlugin } from "@shared-kernel/primary/shared/text-editor/plugins/disabled";
import { OnChangePluginImplementation } from "@shared-kernel/primary/shared/text-editor/plugins/on-change";
import { OutsideChangesPlugin } from "@shared-kernel/primary/shared/text-editor/plugins/outside-changes";

interface RichTextProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T>;
  defaultValue?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  dataTestId?: string;
}

const theme = {
  paragraph: "rich-text-paragraph",
  text: {
    bold: "rich-text-bold",
    italic: "rich-text-italic",
    underline: "rich-text-underline",
    strikethrough: "rich-text-strikethrough",
    underlineStrikethrough: "rich-text-underlineStrikethrough",
  },
};

const urlRegExp = new RegExp(
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/
);
function validateUrl(url: string): boolean {
  return url === "https://" || urlRegExp.test(url);
}

const URL_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

const EMAIL_REGEX =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

const MATCHERS = [
  createLinkMatcherWithRegExp(URL_REGEX, text => {
    return text;
  }),
  createLinkMatcherWithRegExp(EMAIL_REGEX, text => {
    return `mailto:${text}`;
  }),
];

function RichTextLexical<T extends FieldValues>({ name, control, defaultValue, placeholder, disabled, dataTestId }: RichTextProps<T>) {
  const initialConfig: InitialConfigType = {
    namespace: "RichTextEditor",
    theme,
    onError: (error: Error) => {
      console.error(error);
    },
    editorState: (editor: LexicalEditor) => {
      if (defaultValue) {
        editor.update(() => {
          const parser = new DOMParser();
          const dom = parser.parseFromString(defaultValue, "text/html");
          const nodes = $generateNodesFromDOM(editor, dom);
          const root = $getRoot();
          root.select();
          root.clear();
          $insertNodes(nodes);
          // Removes focus from the editor
          $setSelection(null);
        });
      }
    },
    nodes: [LinkNode, AutoLinkNode, ListNode, ListItemNode],
  };

  return (
    <Controller<T>
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LexicalComposer initialConfig={initialConfig}>
          <div className={cn("relative rounded-md border border-gray-300 bg-white", error && "border-red-500")}>
            <ToolbarPlugin />
            <div className="h-[300px] overflow-auto">
              <RichTextPlugin
                contentEditable={
                  <ContentEditable
                    className="min-h-[250px] p-4 text-sm leading-5 outline-none [&>a:visited]:text-purple-600 [&>a]:text-blue-600 [&>a]:underline hover:[&>a]:text-blue-700 [&>li]:my-1 [&>ol]:my-2 [&>ol]:list-decimal [&>ol]:pl-6 [&>ul]:my-2 [&>ul]:list-disc [&>ul]:pl-6"
                    data-testid={dataTestId}
                  />
                }
                placeholder={
                  <div className="pointer-events-none absolute left-4 top-[calc(2.5rem+1rem)] select-none leading-7 text-gray-400">
                    {placeholder}
                  </div>
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
            </div>
            <OnChangePluginImplementation onChange={onChange} />
            <OutsideChangesPlugin value={value || defaultValue} />
            {disabled !== undefined && <DisabledPlugin disabled={disabled} />}
            <HistoryPlugin />
            <LinkPlugin validateUrl={validateUrl} />
            <AutoLinkPlugin matchers={MATCHERS} />
            <ClickableLinkPlugin />
            <ListPlugin />
          </div>
        </LexicalComposer>
      )}
    />
  );
}

export default RichTextLexical;
