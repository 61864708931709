import { createReducer } from "@reduxjs/toolkit";
import { ProcessingState } from "@redux/app-state";
import { refuseProviderPayment, resetRefuseProviderPayment } from "./refuse-provider-payment";

const initialState: ProcessingState = {
  processing: "idle",
};

export const refuseProviderPaymentReducer = createReducer<ProcessingState>(initialState, builder => {
  builder.addCase(refuseProviderPayment.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(refuseProviderPayment.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(refuseProviderPayment.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetRefuseProviderPayment, () => {
    return initialState;
  });
});
