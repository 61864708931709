import { Star } from "lucide-react";
import { useState } from "react";

interface RatingProps {
  value: number;
  onValueChange: (value: number) => void;
  disabled?: boolean;
}

export const Rating = ({ value = 0, onValueChange, disabled = false }: RatingProps) => {
  const [hoverValue, setHoverValue] = useState<number | null>(null);
  const [animatingStar, setAnimatingStar] = useState<number | null>(null);
  const stars = Array.from({ length: 5 }, (_, i) => i + 1);

  const handleStarClick = (star: number) => {
    if (disabled) return;

    onValueChange(star);
    setAnimatingStar(star);

    // Remove animation class after a short delay
    setTimeout(() => {
      setAnimatingStar(null);
    }, 300);
  };

  return (
    <div className="inline-flex">
      {stars.map(star => (
        <div
          key={star}
          className="relative px-0.5 first:pl-0 last:pr-0"
          onMouseEnter={() => !disabled && setHoverValue(star)}
          onMouseLeave={() => !disabled && setHoverValue(null)}
          onClick={() => handleStarClick(star)}
        >
          <Star
            className={`pointer-events-none size-6 transition-all duration-200 ${
              ((!disabled && hoverValue) || value) >= star ? "fill-yellow-400 text-yellow-400" : "fill-none text-gray-300"
            } ${disabled ? "opacity-50" : ""} ${animatingStar === star ? "scale-125" : ""}`}
          />
          {/* Invisible overlay to create a continuous hover area */}
          <div className={`absolute inset-0 ${disabled ? "cursor-not-allowed" : "cursor-pointer"}`} />
        </div>
      ))}
    </div>
  );
};
