import { DAY_BLOCK } from "@academy-context/read/domain/types/shared/training-day";

export const userId = "user-id";
export const studentId = "student-id";
export const teacherId = "teacher-id";
export const trainingId = "training-id";
export const fundingRequestId = "funding-request-id";
export const fundingId = "funding-id";
export const invitationId = "invitation-id";
export const providerId = "provider-id";
export const providerBatchParticipationId = "provider-batch-participation-id";
export const providerBatchEnrollmentId = "provider-batch-enrollment-id";
export const providerBatchId = "provider-batch-id";
export const trainingDays = [
  { date: "2022-12-04T23:00:00.000Z", block: DAY_BLOCK.MORNING },
  { date: "2023-01-03T23:00:00.000Z", block: DAY_BLOCK.MORNING },
  { date: "2023-01-18T23:00:00.000Z", block: DAY_BLOCK.MORNING },
  { date: "2023-01-19T23:00:00.000Z", block: DAY_BLOCK.MORNING },
  { date: "2023-01-20T23:00:00.000Z", block: DAY_BLOCK.MORNING },
  { date: "2023-01-21T23:00:00.000Z", block: DAY_BLOCK.MORNING },
  { date: "2023-01-22T23:00:00.000Z", block: DAY_BLOCK.MORNING },
  { date: "2023-01-23T23:00:00.000Z", block: DAY_BLOCK.MORNING },
  { date: "2023-01-24T23:00:00.000Z", block: DAY_BLOCK.MORNING },
  { date: "2023-01-25T23:00:00.000Z", block: DAY_BLOCK.MORNING },
];
