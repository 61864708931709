import { SURVEY_QUESTION_TYPE } from "@academy-context/shared/domain/types/enums/survey";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Textarea } from "@components/ui/textarea";
import { Rating } from "@shared-kernel/primary/shared/rating/rating";
import { Card, CardContent, CardHeader, CardTitle } from "@components/ui/card";

import { FormInputs } from "./form-validation/evaluation-assignments";
import { TrainingFollowUpSurveyVM } from "@academy-context/read/domain/types/shared/training-follow-up-survey";

interface Props {
  surveyData: TrainingFollowUpSurveyVM[];
}

export const SurveyQuestionsComponent = ({ surveyData }: Props) => {
  const { control } = useFormContext<FormInputs>();

  const { fields } = useFieldArray({
    control,
    name: "surveyAnswers",
  });

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Questionnaire post-formation</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        {fields.map((field, index) => {
          const data = surveyData.find(item => item.question.id === field.questionId);
          const isAnswered = Boolean(data?.response);
          const questionText = data?.question.text;
          const isRatingType = data?.question.type === SURVEY_QUESTION_TYPE.RATING;

          if (!data || !questionText) {
            return null;
          }

          return (
            <FormField
              key={field.id}
              control={control}
              name={`surveyAnswers.${index}.value`}
              render={({ field: formField }) => (
                <FormItem className="flex flex-col space-y-2">
                  <FormLabel className="text-sm font-normal">{questionText}</FormLabel>
                  <FormControl>
                    {isRatingType ? (
                      <Rating
                        value={Number(formField.value) || data.question.options?.max || 5}
                        onValueChange={(value: number) => {
                          formField.onChange(value);
                        }}
                        disabled={isAnswered}
                        aria-label={`Rating for ${questionText}`}
                      />
                    ) : (
                      <Textarea
                        placeholder="Votre réponse..."
                        className="resize-none"
                        {...field}
                        disabled={isAnswered}
                        aria-label={`Text response for ${questionText}`}
                      />
                    )}
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          );
        })}
      </CardContent>
    </Card>
  );
};
