import { ORGANIZATION } from "@shared-kernel/domain/organisation";
import { Student, StudentListElement, USER_STATUS } from "@user-management-context/read/domain/types/admin/student";

const existingStudent: Student = {
  id: "1",
  name: "John",
  lastName: "Doe",
  email: "john@doe.com",
  mobile: "+33612345678",
  favoriteTeachers: [],
  blackListed: false,
  profilePictureUrl: null,
  canRequestARefundOnce: false,
  canRequestANonAfdasFundingOnce: false,
  adminId: null,
};

const existingStudent2: Student = {
  id: "studentId2",
  name: "Gaspar",
  lastName: "Laisné",
  email: "test@example.com",
  mobile: "0101832782",
  favoriteTeachers: [],
  blackListed: false,
  profilePictureUrl: null,
  canRequestARefundOnce: false,
  canRequestANonAfdasFundingOnce: false,
  adminId: null,
};

const existingStudent3: Student = {
  id: "studentId3",
  name: "Philippine",
  lastName: "Cotuand",
  email: "test@example.com",
  mobile: "0901832782",
  favoriteTeachers: [],
  blackListed: false,
  profilePictureUrl: null,
  canRequestARefundOnce: false,
  canRequestANonAfdasFundingOnce: false,
  adminId: null,
};

const existingStudent4: Student = {
  id: "studentId4",
  name: "Galatee",
  lastName: "Lévesque",
  email: "student@example.com",
  mobile: "0901832782",
  favoriteTeachers: [],
  blackListed: false,
  profilePictureUrl: null,
  canRequestARefundOnce: false,
  canRequestANonAfdasFundingOnce: false,
  adminId: null,
};

const existingStudent5: Student = {
  id: "studentId5",
  name: "Chapin",
  lastName: "Boucher",
  email: "test@example.com",
  mobile: "0101832782",
  favoriteTeachers: [],
  blackListed: false,
  profilePictureUrl: null,
  canRequestARefundOnce: false,
  canRequestANonAfdasFundingOnce: false,
  adminId: null,
};

const existingStudent6: Student = {
  id: "studentId6",
  name: "Huon",
  lastName: "Douffet",
  email: "test@example.com",
  mobile: "0901832782",
  favoriteTeachers: [],
  blackListed: false,
  profilePictureUrl: null,
  canRequestARefundOnce: false,
  canRequestANonAfdasFundingOnce: false,
  adminId: null,
};

const existingStudent7: Student = {
  id: "studentId7",
  name: "Donat",
  lastName: "Robert",
  email: "student@example.com",
  mobile: "0901832782",
  favoriteTeachers: [],
  blackListed: false,
  profilePictureUrl: null,
  canRequestARefundOnce: false,
  canRequestANonAfdasFundingOnce: false,
  adminId: null,
};

const existingStudent8: Student = {
  id: "studentId8",
  name: "Favor",
  lastName: "Rivard",
  email: "test@example.com",
  mobile: "0101832782",
  favoriteTeachers: [],
  blackListed: false,
  profilePictureUrl: null,
  canRequestARefundOnce: false,
  canRequestANonAfdasFundingOnce: false,
  adminId: null,
};

const existingStudent9: Student = {
  id: "studentId9",
  name: "Eulalie",
  lastName: "Cormier",
  email: "test@example.com",
  mobile: "0901832782",
  favoriteTeachers: [],
  blackListed: false,
  profilePictureUrl: null,
  canRequestARefundOnce: false,
  canRequestANonAfdasFundingOnce: false,
  adminId: null,
};

const existingStudent10: Student = {
  id: "studentId10",
  name: "Armand",
  lastName: "Bellefeuille",
  email: "student@example.com",
  mobile: "0901832782",
  favoriteTeachers: [],
  blackListed: false,
  profilePictureUrl: null,
  canRequestARefundOnce: false,
  canRequestANonAfdasFundingOnce: false,
  adminId: null,
};

const existingStudent11: Student = {
  id: "studentId11",
  name: "Joseph",
  lastName: "Lizotte",
  email: "test@example.com",
  mobile: "0101832782",
  favoriteTeachers: [],
  blackListed: false,
  profilePictureUrl: null,
  canRequestARefundOnce: false,
  canRequestANonAfdasFundingOnce: false,
  adminId: null,
};

const existingStudent12: Student = {
  id: "studentId12",
  name: "Alain",
  lastName: "Dubois",
  email: "test@example.com",
  mobile: "0901832782",
  favoriteTeachers: [],
  blackListed: false,
  profilePictureUrl: null,
  canRequestARefundOnce: false,
  canRequestANonAfdasFundingOnce: false,
  adminId: null,
};

export const students = [
  existingStudent,
  existingStudent2,
  existingStudent3,
  existingStudent4,
  existingStudent5,
  existingStudent6,
  existingStudent7,
  existingStudent8,
  existingStudent9,
  existingStudent10,
  existingStudent11,
  existingStudent12,
];

export const studentsWithBalance: StudentListElement[] = [
  {
    userId: "user-id",
    name: "Marc",
    lastName: "Siebert",
    email: "test@example.com",
    userStatus: USER_STATUS.REGISTERED,
    details: {
      studentId: "1",
      isBlacklisted: false,
      balance: {
        global: 0,
        byOrganization: {
          les_ateliers_oo: 0,
          opera_off: 0,
        },
      },
      nextFundingDate: "2024-01-01",
      isFundingRequestInProgress: false,
      admin: null,
    },
  },
  {
    userId: "user-id",
    name: "Marc",
    lastName: "Siebert",
    email: "test@example.com",
    userStatus: USER_STATUS.REGISTERED,
    details: {
      studentId: "studentId",
      isBlacklisted: false,
      balance: {
        global: 500,
        byOrganization: {
          [ORGANIZATION.LES_ATELIERS_OO]: 500,
          [ORGANIZATION.OPERA_OFF]: 0,
        },
      },
      nextFundingDate: "01/02/2022",
      isFundingRequestInProgress: false,
      admin: null,
    },
  },
  {
    userId: "user-id-2",
    name: "Gaspar",
    lastName: "Laisné",
    email: "test@example.com",
    userStatus: USER_STATUS.REGISTERED,
    details: {
      balance: {
        global: 1000,
        byOrganization: {
          [ORGANIZATION.LES_ATELIERS_OO]: 1000,
          [ORGANIZATION.OPERA_OFF]: 0,
        },
      },
      nextFundingDate: "02/04/2022",
      studentId: "studentId2",
      isBlacklisted: false,
      isFundingRequestInProgress: false,
      admin: null,
    },
  },
  {
    userId: "user-id-3",
    name: "Philippine",
    lastName: "Cotuand",
    email: "test@example.com",
    userStatus: USER_STATUS.REGISTERED,
    details: {
      isBlacklisted: false,
      balance: {
        global: 1500,
        byOrganization: {
          [ORGANIZATION.LES_ATELIERS_OO]: 1500,
          [ORGANIZATION.OPERA_OFF]: 0,
        },
      },
      nextFundingDate: "03/12/2022",
      studentId: "studentId3",
      isFundingRequestInProgress: false,
      admin: null,
    },
  },
];
