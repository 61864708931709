import { array, boolean, date, number, object, string, TestContext } from "yup";
import { FundingRequestVM } from "../../../../read/domain/types/admin/funding-request";
import { ReviewFundingRequestBody, UpdateFundingRequestBody } from "@shared-kernel/application/ports/shared/funding-request-repository";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { EDUCATIONAL_ADVISOR } from "@academy-context/shared/domain/types/enums/education-advisors";
import { TRAINING_LEVEL } from "@academy-context/shared/domain/types/enums/training-levels";
import { ORGANIZATION } from "@shared-kernel/domain/organisation";
import { DAY_BLOCK, TRAINING_DAYS_CONFIG_MODE, TrainingDaysConfig } from "@academy-context/read/domain/types/shared/training-day";
import { FUNDER } from "@academy-context/shared/domain/types/enums/funders";
import { AnyObject } from "yup/lib/types";
import { addDays, addWeeks, isMonday, nextFriday } from "date-fns";
import { computeDaysSpreadBetweenDates } from "@academy-context/primary/admin/shared/selectors/funding-request/date-options-selectors";

const MIN_DAYS_UNTIL_TRAINING_START = 35;
export const TRAINING_WEEK_SPREAD = 11;
export const TRAINING_MONTH_SPREAD = 3;

export const generateTrainingDays = (interval: { start: Date; end: Date }) =>
  computeDaysSpreadBetweenDates(interval.start, interval.end).map(date => ({
    date,
    block: DAY_BLOCK.AFTERNOON,
  }));

export const computeFirstTrainingDay = (now: Date) => {
  let targetDate = addDays(now, MIN_DAYS_UNTIL_TRAINING_START);
  while (!isMonday(targetDate)) {
    targetDate = addDays(targetDate, 1);
  }
  return targetDate;
};

export const computeLastTrainingDay = (start: Date) => addWeeks(nextFriday(start), TRAINING_WEEK_SPREAD);

export type ValidationMode = "save" | "submit";

export type FormInputs = {
  validationMode: ValidationMode;
  reviewedEducationalAdvisorType: EDUCATIONAL_ADVISOR;
  isUndefinedTeacher: boolean;
  reviewedEducationalAdvisorId: string;
  reviewedInternalTrainingId: string;
  reviewedProviderBatchId: string;
  administrativeInternalTrainingId: string;
  address: string;
  additionalAddress: string;
  coverLetter: string;
  personalizedEducationalProject: {
    title: { label: string; value: string };
    quantifiableObjective: string;
  }[];
  trainingPrice: number;
  trainingDaysConfigMode: TRAINING_DAYS_CONFIG_MODE;
  trainingHalfDays: number | null;
  // Must be a complex type for some reasons: https://stackoverflow.com/questions/67544864/react-hook-form-add-multiple-field-arrays
  trainingDays: { date: Date; block: DAY_BLOCK }[];
  trainingDaysIntervalStartDate: Date | null;
  trainingDaysIntervalEndDate: Date | null;
  trainingHours: number;
  reviewedSpecificAccommodation: string;
  operaOffCommission: number;
  travelExpenses?: number;
  level: TRAINING_LEVEL;
  adminCommentary: string;
  organization: ORGANIZATION;
};

export const defaultValues: FormInputs = {
  validationMode: "submit",
  reviewedEducationalAdvisorType: EDUCATIONAL_ADVISOR.TEACHER,
  isUndefinedTeacher: false,
  reviewedEducationalAdvisorId: "",
  reviewedInternalTrainingId: "",
  reviewedProviderBatchId: "",
  administrativeInternalTrainingId: "",
  address: "",
  additionalAddress: "",
  coverLetter: "",
  personalizedEducationalProject: [
    {
      title: { label: "", value: "" },
      quantifiableObjective: "",
    },
  ],
  trainingPrice: 2400,
  trainingHours: 40,
  trainingDaysConfigMode: TRAINING_DAYS_CONFIG_MODE.AUTOMATIC,
  trainingHalfDays: 10,
  trainingDays: [],
  trainingDaysIntervalStartDate: null,
  trainingDaysIntervalEndDate: null,
  reviewedSpecificAccommodation: "",
  operaOffCommission: 500,
  travelExpenses: undefined,
  level: TRAINING_LEVEL.BEGINNER,
  adminCommentary: "",
  organization: ORGANIZATION.LES_ATELIERS_OO,
};

const validateIntervalCoherence = (end: Date | undefined, context: TestContext<AnyObject>): boolean => {
  const {
    parent: { trainingDaysIntervalStartDate },
  } = context;

  if (!end || !trainingDaysIntervalStartDate) {
    return true;
  }

  return end.getTime() >= trainingDaysIntervalStartDate.getTime();
};

const validateHalfDayDurationCoherence = (value: number | undefined, context: TestContext<AnyObject>): boolean => {
  const {
    parent: { trainingHalfDays },
  } = context;
  if (!value || !trainingHalfDays) return true;

  const ratio = value / trainingHalfDays;

  return ratio >= 3 && ratio <= 5;
};

export const schema = object().shape({
  validationMode: string().required().oneOf(["save", "submit"]),
  reviewedEducationalAdvisorType: string()
    .max(255)
    .when("validationMode", {
      is: "submit",
      then: schema => schema.required(REQUIRED_FIELD).oneOf(Object.values(EDUCATIONAL_ADVISOR)),
      otherwise: schema => schema.nullable(),
    }),
  isUndefinedTeacher: boolean().when("validationMode", {
    is: "submit",
    then: schema => schema.required(REQUIRED_FIELD),
    otherwise: schema => schema.nullable(),
  }),
  reviewedEducationalAdvisorId: string()
    .max(255)
    .when(["validationMode", "reviewedEducationalAdvisorType"], {
      is: (mode: string, type: string) => mode === "submit" && type === EDUCATIONAL_ADVISOR.PROVIDER,
      then: schema => schema.required(REQUIRED_FIELD),
      otherwise: schema => schema.nullable(),
    }),
  reviewedInternalTrainingId: string()
    .max(255)
    .when(["validationMode", "reviewedEducationalAdvisorType"], {
      is: (mode: string, type: string) => mode === "submit" && type === EDUCATIONAL_ADVISOR.TEACHER,
      then: schema => schema.required(REQUIRED_FIELD),
      otherwise: schema => schema.nullable(),
    }),
  reviewedProviderBatchId: string()
    .max(255)
    .when(["validationMode", "reviewedEducationalAdvisorType"], {
      is: (mode: string, type: string) => mode === "submit" && type === EDUCATIONAL_ADVISOR.PROVIDER,
      then: schema => schema.required(REQUIRED_FIELD),
      otherwise: schema => schema.nullable(),
    }),
  administrativeInternalTrainingId: string()
    .max(255)
    .when(["validationMode", "reviewedEducationalAdvisorType"], {
      is: (mode: string, type: string) => mode === "submit" && type === EDUCATIONAL_ADVISOR.PROVIDER,
      then: schema => schema.required(REQUIRED_FIELD),
      otherwise: schema => schema.nullable(),
    }),
  address: string()
    .max(255)
    .when(["validationMode", "reviewedEducationalAdvisorType"], {
      is: (mode: string, type: string) => mode === "submit" && type === EDUCATIONAL_ADVISOR.PROVIDER,
      then: schema => schema.required(REQUIRED_FIELD),
      otherwise: schema => schema.nullable(),
    }),
  additionalAddress: string()
    .max(255)
    .when(["validationMode", "reviewedEducationalAdvisorType"], {
      is: (mode: string, type: string) => mode === "submit" && type === EDUCATIONAL_ADVISOR.PROVIDER,
      then: schema => schema.required(REQUIRED_FIELD),
      otherwise: schema => schema.nullable(),
    }),
  coverLetter: string().when("validationMode", {
    is: "submit",
    then: schema => schema.required(REQUIRED_FIELD),
    otherwise: schema => schema.nullable(),
  }),
  trainingPrice: number().when("validationMode", {
    is: "submit",
    then: schema =>
      schema.typeError(`Minimum 0€`).positive(`Minimum 0€`).min(0, `Minimum 0€`).max(10_000, `Maximum 10 000€`).required(REQUIRED_FIELD),
    otherwise: schema => schema.nullable(),
  }),
  trainingDaysConfigMode: string().when("validationMode", {
    is: "submit",
    then: schema => schema.max(255).required(REQUIRED_FIELD).oneOf(Object.values(TRAINING_DAYS_CONFIG_MODE)),
    otherwise: schema => schema.nullable(),
  }),
  trainingDays: array().when(["validationMode", "trainingDaysConfigMode"], {
    is: (mode: string, configMode: string) => mode === "submit" && configMode === TRAINING_DAYS_CONFIG_MODE.MANUAL,
    then: schema =>
      schema.min(1, "Requiert au moins une date").of(
        object().shape({
          date: date().required(REQUIRED_FIELD).typeError("Date invalide"),
          block: string().required(REQUIRED_FIELD).oneOf(Object.values(DAY_BLOCK)),
        })
      ),
    otherwise: schema => schema.nullable(),
  }),
  trainingHalfDays: number().when(["validationMode", "trainingDaysConfigMode"], {
    is: (mode: string, configMode: string) => mode === "submit" && configMode === TRAINING_DAYS_CONFIG_MODE.AUTOMATIC,
    then: schema => schema.typeError(`Minimum 1`).positive(`Minimum 1`).min(1, `Minimum 1`).required(REQUIRED_FIELD),
    otherwise: schema => schema.nullable(),
  }),
  trainingHours: number().when("validationMode", {
    is: "submit",
    then: schema =>
      schema
        .typeError(`Minimum 1`)
        .positive(`Minimum 1`)
        .min(1, `Minimum 1`)
        .required(REQUIRED_FIELD)
        .test("is-coherent", "Attention incohérence sur les heures et le nombre de demi-journées", validateHalfDayDurationCoherence),
    otherwise: schema => schema.nullable(),
  }),
  trainingDaysIntervalStartDate: date().when(["validationMode", "trainingDaysConfigMode"], {
    is: (mode: string, configMode: string) => mode === "submit" && configMode === TRAINING_DAYS_CONFIG_MODE.AUTOMATIC,
    then: schema => schema.required(REQUIRED_FIELD),
    otherwise: schema => schema.nullable(),
  }),
  trainingDaysIntervalEndDate: date().when(["validationMode", "trainingDaysConfigMode"], {
    is: (mode: string, configMode: string) => mode === "submit" && configMode === TRAINING_DAYS_CONFIG_MODE.AUTOMATIC,
    then: schema =>
      schema
        .required(REQUIRED_FIELD)
        .test("end is after start", "La date de fin doit être après la date de début", validateIntervalCoherence),
    otherwise: schema => schema.nullable(),
  }),
  personalizedEducationalProject: array().when("validationMode", {
    is: "submit",
    then: schema =>
      schema.min(1, "Requiert au moins un objectif").of(
        object().shape({
          title: object().shape({
            label: string().max(255).required(REQUIRED_FIELD),
            value: string().max(255).required(REQUIRED_FIELD),
          }),
          quantifiableObjective: string().required(REQUIRED_FIELD),
        })
      ),
    otherwise: schema => schema.nullable(),
  }),
  reviewedSpecificAccommodation: string().nullable(),
  operaOffCommission: number().when("validationMode", {
    is: "submit",
    then: schema => schema.min(1).max(2000).required(REQUIRED_FIELD),
    otherwise: schema => schema.nullable(),
  }),
  travelExpenses: number().nullable().min(1, "Le montant doit être au minimum de 1€").max(2000, "Le montant doit être au maximum de 2000€"),
  level: string().when("validationMode", {
    is: "submit",
    then: schema => schema.required(REQUIRED_FIELD).oneOf(Object.values(TRAINING_LEVEL)),
    otherwise: schema => schema.nullable(),
  }),
  adminCommentary: string().nullable(),
  organization: string().when("validationMode", {
    is: "submit",
    then: schema => schema.max(255).required(REQUIRED_FIELD).oneOf(Object.values(ORGANIZATION)),
    otherwise: schema => schema.nullable(),
  }),
});

export const formatFormDataToBodyDataReviewFundingRequest = (fundingRequest: FormInputs): ReviewFundingRequestBody => {
  const {
    reviewedEducationalAdvisorId,
    isUndefinedTeacher,
    reviewedEducationalAdvisorType,
    reviewedInternalTrainingId,
    reviewedProviderBatchId,
    administrativeInternalTrainingId,
    address,
    additionalAddress,
    coverLetter,
    personalizedEducationalProject,
    trainingPrice,
    trainingHours,
    trainingDays,
    reviewedSpecificAccommodation,
    operaOffCommission,
    travelExpenses,
    level,
    adminCommentary,
    organization,
    trainingDaysConfigMode,
    trainingHalfDays,
    trainingDaysIntervalEndDate,
    trainingDaysIntervalStartDate,
  } = fundingRequest;

  const reviewedEducationalAdvisor = isUndefinedTeacher
    ? {
        id: null,
        type: EDUCATIONAL_ADVISOR.TEACHER,
      }
    : {
        id: reviewedEducationalAdvisorId,
        type: reviewedEducationalAdvisorType,
      };

  let trainingDaysConfig: TrainingDaysConfig;
  const base: Pick<TrainingDaysConfig, "hours" | "halfDays"> = {
    hours: trainingHours,
    halfDays: trainingHalfDays ?? 0,
  };

  if (trainingDaysConfigMode === TRAINING_DAYS_CONFIG_MODE.AUTOMATIC && trainingDaysIntervalStartDate && trainingDaysIntervalEndDate) {
    trainingDaysConfig = {
      ...base,
      mode: TRAINING_DAYS_CONFIG_MODE.AUTOMATIC,
      interval: {
        start: trainingDaysIntervalStartDate.toISOString(),
        end: trainingDaysIntervalEndDate.toISOString(),
      },
    };
  } else if (trainingDaysConfigMode === TRAINING_DAYS_CONFIG_MODE.MANUAL && trainingDays.length > 0) {
    trainingDaysConfig = {
      ...base,
      mode: TRAINING_DAYS_CONFIG_MODE.MANUAL,
      interval: {
        start: trainingDays[0]!.date.toISOString(),
        end: trainingDays[trainingDays.length - 1]!.date.toISOString(),
      },
      trainingDays: trainingDays.map(({ date, block }) => ({ date: date.toISOString(), block })),
    };
  } else {
    throw new Error(`Invalid training days config mode: ${trainingDaysConfigMode}`);
  }

  return {
    reviewedEducationalAdvisor,
    reviewedInternalTrainingId: reviewedInternalTrainingId || null,
    reviewedProviderBatchId: reviewedProviderBatchId || null,
    administrativeInternalTrainingId: administrativeInternalTrainingId || null,
    address: address || null,
    additionalAddress: additionalAddress || null,
    coverLetter,
    reviewedPersonalizedEducationalProject: personalizedEducationalProject.map(p => ({
      title: p.title.value,
      quantifiableObjective: p.quantifiableObjective,
    })),
    trainingPrice,
    trainingDaysConfig,
    reviewedSpecificAccommodation: reviewedSpecificAccommodation || null,
    operaOffCommission,
    travelExpenses: travelExpenses || null,
    level,
    adminCommentary: adminCommentary || null,
    organization,
  };
};

export const formatFormDataToBodyDataUpdateFundingRequest = (fundingRequest: FormInputs, id: string): UpdateFundingRequestBody => {
  const {
    reviewedEducationalAdvisorId,
    isUndefinedTeacher,
    reviewedEducationalAdvisorType,
    reviewedInternalTrainingId,
    reviewedProviderBatchId,
    administrativeInternalTrainingId,
    address,
    additionalAddress,
    coverLetter,
    personalizedEducationalProject,
    trainingPrice,
    trainingHours,
    trainingDays,
    reviewedSpecificAccommodation,
    operaOffCommission,
    travelExpenses,
    level,
    adminCommentary,
    organization,
    trainingDaysConfigMode,
    trainingHalfDays,
    trainingDaysIntervalEndDate,
    trainingDaysIntervalStartDate,
  } = fundingRequest;

  const reviewedEducationalAdvisor = isUndefinedTeacher
    ? {
        id: null,
        type: EDUCATIONAL_ADVISOR.TEACHER,
      }
    : {
        id: reviewedEducationalAdvisorId,
        type: reviewedEducationalAdvisorType,
      };

  let trainingDaysConfig: TrainingDaysConfig;
  const base: Pick<TrainingDaysConfig, "hours" | "halfDays"> = {
    hours: trainingHours,
    halfDays: trainingHalfDays ?? 0,
  };

  if (trainingDaysConfigMode === TRAINING_DAYS_CONFIG_MODE.AUTOMATIC && trainingDaysIntervalStartDate && trainingDaysIntervalEndDate) {
    trainingDaysConfig = {
      ...base,
      mode: TRAINING_DAYS_CONFIG_MODE.AUTOMATIC,
      interval: {
        start: trainingDaysIntervalStartDate.toISOString(),
        end: trainingDaysIntervalEndDate.toISOString(),
      },
    };
  } else if (trainingDaysConfigMode === TRAINING_DAYS_CONFIG_MODE.MANUAL && trainingDays.length > 0) {
    trainingDaysConfig = {
      ...base,
      mode: TRAINING_DAYS_CONFIG_MODE.MANUAL,
      interval: {
        start: trainingDays[0]!.date.toISOString(),
        end: trainingDays[trainingDays.length - 1]!.date.toISOString(),
      },
      trainingDays: trainingDays.map(({ date, block }) => ({ date: date.toISOString(), block })),
    };
  } else {
    throw new Error(`Invalid training days config mode: ${trainingDaysConfigMode}`);
  }

  return {
    id,
    reviewedEducationalAdvisor,
    reviewedInternalTrainingId: reviewedInternalTrainingId || null,
    reviewedProviderBatchId: reviewedProviderBatchId || null,
    administrativeInternalTrainingId: administrativeInternalTrainingId || null,
    address: address || null,
    additionalAddress: additionalAddress || null,
    coverLetter,
    reviewedPersonalizedEducationalProject: personalizedEducationalProject.map(p => ({
      title: p.title.value,
      quantifiableObjective: p.quantifiableObjective,
    })),
    reviewedSecondaryTeachers: null,
    trainingPrice,
    trainingDaysConfig,
    reviewedSpecificAccommodation: reviewedSpecificAccommodation || null,
    operaOffCommission,
    travelExpenses: travelExpenses || null,
    level,
    adminCommentary: adminCommentary || null,
    organization,
  };
};

export const formatFundingRequestDataToFormData = (fundingRequest: FundingRequestVM, now: Date): FormInputs => {
  const {
    educationalAdvisor,
    reviewedEducationalAdvisor,
    internalTrainingId,
    reviewedInternalTrainingId,
    providerBatch,
    reviewedProviderBatchId,
    administrativeInternalTrainingId,
    reviewedCoverLetter,
    coverLetter,
    reviewedPersonalizedEducationalProject,
    trainingPrice: trainingPriceFromDB,
    trainingDaysConfig: trainingDaysConfigFromDB,
    address,
    additionalAddress,
    reviewedSpecificAccommodation,
    level,
    specificAccommodation,
    operaOffCommission,
    travelExpenses,
    adminCommentary,
    organization: organizationFromDB,
    funder,
  } = fundingRequest;

  const isFranceTravailFunder = funder.type === FUNDER.FRANCE_TRAVAIL;

  const reviewedEducationalAdvisorId = reviewedEducationalAdvisor?.id ?? educationalAdvisor.id;
  const reviewedEducationalAdvisorType = reviewedEducationalAdvisor?.type ?? educationalAdvisor.type;
  const isProvider = reviewedEducationalAdvisorType === EDUCATIONAL_ADVISOR.PROVIDER;

  let commission = operaOffCommission;
  if (commission == null) {
    if (isFranceTravailFunder) commission = 500;
    else {
      commission = isProvider ? 400 : 550;
    }
  }

  const trainingHours = trainingDaysConfigFromDB?.hours ?? (isFranceTravailFunder ? 50 : defaultValues.trainingHours);
  const organization = organizationFromDB ?? (isFranceTravailFunder ? ORGANIZATION.OPERA_OFF : defaultValues.organization);
  const price = trainingPriceFromDB ?? (isFranceTravailFunder ? 2000 : defaultValues.trainingPrice);

  let startDate: Date | null = null;
  let endDate: Date | null = null;
  let trainingDays: { date: Date; block: DAY_BLOCK }[] = [];
  if (trainingDaysConfigFromDB) {
    startDate = new Date(trainingDaysConfigFromDB.interval.start);
    endDate = new Date(trainingDaysConfigFromDB.interval.end);
    if (trainingDaysConfigFromDB.mode === TRAINING_DAYS_CONFIG_MODE.MANUAL)
      trainingDays = trainingDaysConfigFromDB.trainingDays.map(({ date, block }) => ({ date: new Date(date), block }));
  } else {
    startDate = computeFirstTrainingDay(now);
    endDate = computeLastTrainingDay(startDate);
  }

  return {
    validationMode: "submit",
    reviewedEducationalAdvisorId: reviewedEducationalAdvisorId ?? defaultValues.reviewedEducationalAdvisorId,
    isUndefinedTeacher: !reviewedEducationalAdvisorId && reviewedEducationalAdvisorType === EDUCATIONAL_ADVISOR.TEACHER,
    reviewedEducationalAdvisorType,
    reviewedInternalTrainingId: reviewedInternalTrainingId ?? internalTrainingId ?? defaultValues.reviewedInternalTrainingId,
    reviewedProviderBatchId: reviewedProviderBatchId ?? providerBatch?.id ?? defaultValues.reviewedProviderBatchId,
    administrativeInternalTrainingId: administrativeInternalTrainingId ?? defaultValues.administrativeInternalTrainingId,
    coverLetter: reviewedCoverLetter || coverLetter || defaultValues.coverLetter,
    reviewedSpecificAccommodation: reviewedSpecificAccommodation ?? specificAccommodation ?? defaultValues.reviewedSpecificAccommodation,
    personalizedEducationalProject: reviewedPersonalizedEducationalProject
      ? reviewedPersonalizedEducationalProject.map(o => ({
          title: { label: o.title, value: o.title },
          quantifiableObjective: o.quantifiableObjective,
        }))
      : defaultValues.personalizedEducationalProject,
    trainingPrice: price,
    trainingDays,
    trainingDaysConfigMode: trainingDaysConfigFromDB?.mode ?? defaultValues.trainingDaysConfigMode,
    trainingHalfDays: trainingDaysConfigFromDB?.halfDays ?? defaultValues.trainingHalfDays,
    trainingHours,
    trainingDaysIntervalStartDate: startDate,
    trainingDaysIntervalEndDate: endDate,
    address: address ?? defaultValues.address,
    additionalAddress: additionalAddress ?? defaultValues.additionalAddress,
    level: level ?? defaultValues.level,
    operaOffCommission: commission,
    travelExpenses: travelExpenses ?? defaultValues.travelExpenses,
    adminCommentary: adminCommentary ?? defaultValues.adminCommentary,
    organization,
  };
};
