import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { AppState } from "src/redux/app-state";
import { retrieveProviderStudents } from "@user-management-context/read/application/use-cases/provider/student-list/retrieve-provider-students";
import { adminRetrieveProviderStudents } from "@user-management-context/read/application/use-cases/admin/provider-students-retrieval/retrieve-provider-students";
import { ProviderStudent } from "@user-management-context/read/domain/types/provider/student";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@shared-kernel/primary/shared/shadcn/ui/command";
import { Card } from "@shared-kernel/primary/shared/shadcn/ui/card";
import { getNormalizedString } from "@utils/utils";

import { ProviderBatchItem } from "@academy-context/read/domain/types/shared/provider-batch";

interface Props {
  onSelect: (student: ProviderStudent) => void;
  className?: string;
  isAdmin?: boolean;
  providerId?: string;
  batch: ProviderBatchItem;
}

export const StudentCombobox = ({ onSelect, className, isAdmin = false, providerId, batch }: Props) => {
  const dispatch = useAppDispatch();
  const { data: providerStudents, fetching: providerFetching } = useAppSelector((state: AppState) => state.providerStudentsList);
  const { data: adminStudents, fetching: adminFetching } = useAppSelector((state: AppState) => state.adminProviderStudentsList);
  const [inputValue, setInputValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const students = isAdmin ? adminStudents : providerStudents;
  const fetching = isAdmin ? adminFetching : providerFetching;

  useEffect(() => {
    if (isAdmin && providerId) {
      dispatch(adminRetrieveProviderStudents(providerId));
    } else {
      dispatch(retrieveProviderStudents());
    }
  }, [dispatch, isAdmin, providerId]);

  const filteredStudents = students?.filter(student => {
    // Filter out students who are already enrolled or participating
    const isEnrolled = batch.enrollments.some(enrollment => enrollment.student.id === student.id);
    const isParticipating = batch.participations.some(participation => participation.student.id === student.id);
    if (isEnrolled || isParticipating) return false;

    // Then apply search filter
    if (!inputValue) return true;
    const searchValue = getNormalizedString(inputValue.toLowerCase());
    const studentName = getNormalizedString(`${student.name} ${student.lastName} ${student.email}`.toLowerCase());
    return studentName.includes(searchValue);
  });

  return (
    <Card className={className}>
      <Command shouldFilter={false} className="rounded-lg border-none shadow-none">
        <CommandInput
          placeholder="Rechercher un élève..."
          value={inputValue}
          onValueChange={setInputValue}
          disabled={fetching === "pending"}
          className="border-none"
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            // Small delay to allow click events on CommandItems to fire before hiding the list
            setTimeout(() => setIsFocused(false), 200);
          }}
        />
        {isFocused && (
          <CommandList>
            <CommandEmpty>Aucun élève trouvé</CommandEmpty>
            <CommandGroup>
              {filteredStudents?.map(student => (
                <CommandItem
                  key={student.id}
                  onSelect={() => {
                    onSelect(student);
                    setInputValue("");
                  }}
                  className="flex flex-col items-start gap-1"
                >
                  <div className="font-medium">
                    {student.name} {student.lastName}
                  </div>
                  <div className="text-sm text-muted-foreground">{student.email}</div>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        )}
      </Command>
    </Card>
  );
};
