import React, { useEffect } from "react";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { useFormContext } from "react-hook-form";
import { computeFirstTrainingDay, FormInputs, defaultValues, computeLastTrainingDay } from "./form-validation/funding-request";
import { RadioGroup, RadioGroupItem } from "@components/ui/radio-group";
import { Input } from "@components/ui/input";
import { PopoverCalendar } from "@shared-kernel/primary/shared/calendar/popover-calendar";
import { TRAINING_DAYS_CONFIG_MODE, TRAINING_DAYS_CONFIG_MODE_MAP } from "@academy-context/read/domain/types/shared/training-day";
import { TrainingDaysManualConfigForm } from "@academy-context/primary/admin/funding-request-detail/training-days-manual-config-form";
import { FundingRequestVM } from "@academy-context/read/domain/types/admin/funding-request";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import { retrieveStudentSummary } from "@academy-context/read/application/use-cases/student/summary-retrieval/retrieve-student-summary";
import { formatDateToLocale } from "@utils/formatting";

interface Props {
  now: Date;
  fundingRequest: FundingRequestVM;
  initialStartDate: Date;
}

export const TrainingDaysConfigFormField: React.FC<Props> = ({ now, fundingRequest, initialStartDate }) => {
  const dispatch = useAppDispatch();
  const formMethods = useFormContext<FormInputs>();
  const { watch, setValue } = formMethods;
  const { data } = useAppSelector((state: AppState) => state.studentSummaryRetrieval);

  const trainingDaysConfigMode = watch("trainingDaysConfigMode");
  const trainingDaysIntervalStartDate = watch("trainingDaysIntervalStartDate");

  const isTrainingDayConfigManual = trainingDaysConfigMode === TRAINING_DAYS_CONFIG_MODE.MANUAL;

  let isDay1BeforeNextFundingDate = false;
  if (trainingDaysIntervalStartDate && data?.nextFundingDate != null) {
    isDay1BeforeNextFundingDate = new Date(data.nextFundingDate).getTime() >= trainingDaysIntervalStartDate.getTime();
  }

  const firstTrainingDaysEligible = computeFirstTrainingDay(now);
  const isTrainingDaysStartEligible = trainingDaysIntervalStartDate && trainingDaysIntervalStartDate < firstTrainingDaysEligible;

  useEffect(() => {
    dispatch(retrieveStudentSummary(fundingRequest.student.id));
  }, [dispatch, fundingRequest.student.id]);

  const handleModeChange = (value: TRAINING_DAYS_CONFIG_MODE) => {
    if (value === TRAINING_DAYS_CONFIG_MODE.AUTOMATIC) {
      const startDate = computeFirstTrainingDay(now);
      const endDate = computeLastTrainingDay(startDate);

      setValue("trainingDaysIntervalStartDate", startDate);
      setValue("trainingDaysIntervalEndDate", endDate);
      setValue("trainingDays", defaultValues.trainingDays);
      setValue("trainingHalfDays", defaultValues.trainingHalfDays);
    } else {
      setValue("trainingDaysIntervalStartDate", null);
      setValue("trainingDaysIntervalEndDate", null);
      setValue("trainingDays", []);
      setValue("trainingHalfDays", null);
    }
    setValue("trainingDaysConfigMode", value);
  };

  return (
    <>
      <div className="flex items-center justify-between space-x-2">
        <FormField
          control={formMethods.control}
          name="trainingDaysConfigMode"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Gestion des émargements</FormLabel>
                <RadioGroup
                  onValueChange={value => handleModeChange(value as TRAINING_DAYS_CONFIG_MODE)}
                  value={field.value}
                  className="flex flex-col space-y-1"
                >
                  {Object.values(TRAINING_DAYS_CONFIG_MODE).map((v, index) => (
                    <FormItem className="flex items-center space-x-3 space-y-0" key={index}>
                      <FormControl>
                        <RadioGroupItem value={v} data-testid={`training-days-mode-${v}`} />
                      </FormControl>
                      <FormLabel className="font-normal">{TRAINING_DAYS_CONFIG_MODE_MAP[v]}</FormLabel>
                    </FormItem>
                  ))}
                </RadioGroup>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <FormField
          control={formMethods.control}
          name="trainingHours"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Nombre d'heures</FormLabel>
              <FormControl className="w-[150px]">
                <Input data-testid="training-hours-input" type="number" step="1" min={1} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      {isTrainingDaysStartEligible && (
        <p className="font-bold text-red-500">
          Attention, la date de debut choisie est antérieure à la date minimum de début de stage :{" "}
          {formatDateToLocale(firstTrainingDaysEligible.toISOString())}
        </p>
      )}
      {isDay1BeforeNextFundingDate && data?.nextFundingDate && (
        <p className="font-bold text-red-500">
          Attention, la date de debut de stage choisie est antérieure à la date de carence calculée :{" "}
          {formatDateToLocale(data.nextFundingDate)}
        </p>
      )}
      {isTrainingDayConfigManual ? (
        <TrainingDaysManualConfigForm now={now} initialStartDate={initialStartDate} />
      ) : (
        <div className="grid grid-cols-8 items-center space-x-2">
          <FormField
            control={formMethods.control}
            name="trainingHalfDays"
            render={({ field }) => (
              <FormItem className="col-span-2">
                <FormLabel>Demi-journées</FormLabel>
                <FormControl>
                  <Input
                    data-testid="training-half-days-input"
                    type="number"
                    step="1"
                    min={1}
                    value={field.value ?? ""}
                    onChange={field.onChange}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={formMethods.control}
            name="trainingDaysIntervalStartDate"
            render={({ field }) => {
              return (
                <FormItem className="col-span-3">
                  <FormLabel>Date de début</FormLabel>
                  <FormControl>
                    <div>
                      <PopoverCalendar
                        value={field.value?.toISOString() ?? now.toISOString()}
                        onChange={value => field.onChange(value)}
                        now={now}
                        useDefaultMonth
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={formMethods.control}
            name="trainingDaysIntervalEndDate"
            render={({ field }) => {
              return (
                <FormItem className="col-span-3">
                  <FormLabel>Date de fin</FormLabel>
                  <FormControl>
                    <div>
                      <PopoverCalendar
                        value={field.value?.toISOString() ?? now.toISOString()}
                        onChange={value => field.onChange(value)}
                        now={now}
                        useDefaultMonth
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
        </div>
      )}
    </>
  );
};
