import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { adminRetrieveTrainingFollowUpEvaluations } from "../../../read/application/use-cases/admin/training-follow-up-evaluations-retrieval/retrieve-training-follow-up-evaluations";
import { BackButton } from "@shared-kernel/primary/shared/back-button/back-button";
import { ROLE_BASED_URLS } from "src/routes";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { AppState } from "@redux/app-state";
import { AdminEvaluationCard } from "@academy-context/primary/admin/training-follow-up-evaluation-form/admin-evaluation-card.components";
import { useEvaluationUpdated } from "@academy-context/primary/admin/training-follow-up-evaluation-form/use-evaluation-updated";
import { ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { adminRetrieveTeachers } from "@user-management-context/read/application/use-cases/admin/admin-teachers-retrieval/retrieve-teachers";
import { adminRetrieveProviders } from "@user-management-context/read/application/use-cases/admin/providers-retrieval/retrieve-providers";
import { selectProvidersRetrievalForList } from "@user-management-context/read/application/use-cases/admin/providers-retrieval/selectors/provider-list-selectors";
import { selectTeachersRetrievalForList } from "@user-management-context/read/application/use-cases/admin/admin-teachers-retrieval/selectors/teachers-list-selectors";
import {
  resetRetrieveTrainingFollowUpSurvey,
  retrieveTrainingFollowUpSurvey,
} from "@academy-context/read/application/use-cases/student/training-follow-up-survey/retrieve-training-follow-up-survey";
import { AdminPostTrainingSurveyComponent } from "@academy-context/primary/admin/training-follow-up-evaluation-form/post-training-survey.components";
import { resetRetrieveTrainingFollowUpEvaluations } from "@academy-context/read/application/use-cases/shared/training-follow-up-evaluations-retrieval/retrieve-training-follow-up-evaluations";

export const AdminTrainingFollowUpEvaluationsContainer = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { data: evaluations } = useAppSelector((state: AppState) => state.adminTrainingFollowUpEvaluationsRetrieval);
  const { providers } = useAppSelector(selectProvidersRetrievalForList);
  const { data: teachers } = useAppSelector(selectTeachersRetrievalForList);
  const { data: surveyData } = useAppSelector((state: AppState) => state.trainingFollowUpSurveyRetrieval);

  useEffect(() => {
    dispatch(adminRetrieveTeachers());
    dispatch(adminRetrieveProviders());
    if (id) {
      dispatch(adminRetrieveTrainingFollowUpEvaluations({ trainingId: id }));
      dispatch(retrieveTrainingFollowUpSurvey(id));
    }
    return () => {
      dispatch(resetRetrieveTrainingFollowUpSurvey());
      dispatch(resetRetrieveTrainingFollowUpEvaluations());
    };
  }, [dispatch, id]);

  useEvaluationUpdated({ trainingId: id! });

  const teacherOptions: ComboboxOptions[] = useMemo(
    () => teachers.map(t => ({ label: `${t.name} ${t.lastName}`, value: t.teacherId })),
    [teachers]
  );

  const providerOptions: ComboboxOptions[] = useMemo(() => providers.map(p => ({ label: p.name, value: p.id })), [providers]);

  if (!id) return <></>;

  return (
    <div className="mb-5 flex justify-center">
      <div className="w-[90%]">
        <BackButton link={`${ROLE_BASED_URLS[ROLES.ADMIN].trainingFollowUp.list}/${id}`} label="Projet Pédagogique" />
        <div className="mt-5 space-y-5">
          {evaluations.map(e => (
            <div key={e.id} className="space-y-2">
              <AdminEvaluationCard evaluation={e} teacherOptions={teacherOptions} providerOptions={providerOptions} />
            </div>
          ))}
          {surveyData.length > 0 && <AdminPostTrainingSurveyComponent surveyData={surveyData} />}
        </div>
      </div>
    </div>
  );
};
