import { FUNDER_LABELS } from "@academy-context/shared/domain/types/enums/funders";
import { AppState } from "@redux/app-state";
import { isLoadingState } from "@utils/utils";

export type AdminFundingExtended = ReturnType<typeof selectFundingsRetrievalForList>["data"][number];

export const selectFundingsRetrievalForList = (state: AppState) => {
  const {
    fundingsRetrieval: { data, fetching },
  } = state;

  return {
    data: [...data].map(f => {
      return {
        ...f,
        funder: FUNDER_LABELS[f.funder],
      };
    }),
    isLoading: isLoadingState(fetching),
  };
};
