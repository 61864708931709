import { useEffect, useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { CircleX } from "lucide-react";
import { computeHalfDaysCount, DAY_BLOCK, DayBlockOptions } from "@academy-context/read/domain/types/shared/training-day";
import { Calendar } from "@components/ui/calendar";
import { fr } from "date-fns/locale";
import { Button } from "@components/ui/button";
import { Combobox } from "@shared-kernel/primary/shared/combobox/combobox";
import { format, startOfMonth } from "date-fns";
import { Separator } from "@components/ui/separator";
import { selectDateOptions } from "@academy-context/primary/admin/shared/selectors/funding-request/date-options-selectors";
import { cn } from "@components/utils/utils";
import {
  computeLastTrainingDay,
  generateTrainingDays,
  TRAINING_MONTH_SPREAD,
} from "@academy-context/primary/admin/funding-request-detail/form-validation/funding-request";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { commonAdapters } from "src/common-adapters";

type TrainingDaysFormInputs = {
  trainingDays: { date: Date; block: DAY_BLOCK }[];
};

interface Props {
  initialStartDate: Date;
  now: Date;
  formContext: "funding_request" | "provider_batch";
  numberOfMonthsDisplayed: number;
}

export const TrainingDaysForm = ({ initialStartDate, now, formContext, numberOfMonthsDisplayed }: Props) => {
  const { dateProvider } = commonAdapters;
  const { control, watch } = useFormContext<TrainingDaysFormInputs>();
  const { remove, insert, append, replace, fields } = useFieldArray({
    control,
    name: "trainingDays",
    keyName: "fieldId",
  });
  const [selected, setSelected] = useState<Date[]>([]);
  const startOfMonthNow = startOfMonth(now);
  const [currentMonth, setCurrentMonth] = useState<Nullable<Date>>(startOfMonth(initialStartDate));
  const dateOptions = selectDateOptions(startOfMonthNow, TRAINING_MONTH_SPREAD - 1);

  const trainingDays = watch("trainingDays");

  const halfDaysCount = computeHalfDaysCount(trainingDays);

  useEffect(() => {
    setSelected(trainingDays.map(({ date }) => date));
  }, [trainingDays]);

  useEffect(() => {
    setSelected(trainingDays.map(({ date }) => date));
    const startDate = trainingDays[0]?.date;
    if (startDate) setCurrentMonth(startOfMonth(startDate));
    else setCurrentMonth(null);
  }, [initialStartDate, trainingDays]);

  const onSelect = (dates: Date[]) => {
    const hasAddedANewDate = dates.length > trainingDays.length;
    if (hasAddedANewDate) {
      const lastSelectedDate = dates[dates.length - 1];
      if (lastSelectedDate) {
        const isNewDate = !trainingDays.find(({ date }) => date.getTime() === lastSelectedDate.getTime());
        if (isNewDate) {
          const index = trainingDays.findIndex(({ date }) => date.getTime() > lastSelectedDate.getTime());
          const newTrainingDay = { date: lastSelectedDate, block: DAY_BLOCK.AFTERNOON };
          if (index === -1) append(newTrainingDay);
          else insert(index, newTrainingDay);
        }
      }
    } else {
      const removedIndex = trainingDays.findIndex(({ date }) => !dates.find(d => d.getTime() === date.getTime()));
      if (removedIndex !== -1) {
        remove(removedIndex);
      }
    }
  };

  const onRemove = (index: number) => {
    remove(index);
  };

  const isFundingRequestForm = formContext === "funding_request";
  const isExistingOption = currentMonth && dateOptions.find(o => o.value === startOfMonth(currentMonth).toISOString());
  const bankHolidaysSelected = useMemo(() => {
    return trainingDays.filter(({ date }) => dateProvider!.isBankHoliday(date));
  }, [trainingDays, dateProvider]);

  return (
    <div className="flex flex-col space-y-4">
      <div className={cn("flex flex-col space-y-4", isFundingRequestForm && "items-center")}>
        <FormLabel>Demi-journées de formation</FormLabel>
        {isFundingRequestForm && (
          <div className="flex items-center">
            <div className="text-sm">Générer de nouvelles demi-journées</div>
            <Combobox
              options={dateOptions}
              value={isExistingOption?.value ?? ""}
              onChange={month => {
                const currentMonth = new Date(month);
                setCurrentMonth(new Date(month));
                const trainingDays = generateTrainingDays({ start: currentMonth, end: computeLastTrainingDay(currentMonth) });
                replace(trainingDays);
              }}
              placeholder="Selectionner les dates..."
              search={{
                notFoundText: "Pas de date trouvée.",
                commandInputPlaceHolder: "Chercher date...",
              }}
              disableSort
            />
          </div>
        )}

        <div className="flex w-full items-center justify-between space-x-4">
          <p className="text-sm text-gray-500">
            Demi-journée{halfDaysCount > 1 ? "s" : ""} sélectionné{halfDaysCount > 1 ? "s" : ""} :{" "}
            <span className="text-xl font-bold text-destructive">{halfDaysCount}</span>
          </p>
          <Button
            onClick={() => {
              replace([]);
            }}
            disabled={trainingDays.length === 0}
          >
            Effacer tout
          </Button>
        </div>
        <>
          {bankHolidaysSelected.length > 0 && (
            <p className="text-sm text-yellow-500">
              Avertissement : Vous avez sélectionné {bankHolidaysSelected.length} jour{bankHolidaysSelected.length > 1 ? "s" : ""} férié
              {bankHolidaysSelected.length > 1 ? "s" : ""}. Veuillez vérifier que ces dates sont correctes et conformes au planning de la
              formation.
              <ul className="list-disc pl-5">
                {bankHolidaysSelected.map(({ date }, index) => (
                  <li key={index}>{format(date, "PPP", { locale: fr })}</li>
                ))}
              </ul>
            </p>
          )}
          <Calendar
            mode="multiple"
            selected={selected}
            onSelect={dates => {
              if (dates) {
                onSelect(dates);
              }
            }}
            numberOfMonths={numberOfMonthsDisplayed}
            today={now}
            weekStartsOn={1}
            locale={fr}
            className="p-0"
            defaultMonth={currentMonth || undefined}
            showOutsideDays={false}
          />
        </>
      </div>
      <FormField
        control={control}
        name="trainingDays"
        render={() => {
          return (
            <FormItem>
              <FormControl>
                <div className="flex flex-col space-y-1">
                  {fields.map(({ fieldId, date }, index) => (
                    <div key={fieldId}>
                      <TrainingDay date={date} index={index} onRemove={onRemove} />
                      {index < fields.length - 1 && <Separator className="mt-1" />}
                    </div>
                  ))}
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
    </div>
  );
};

interface TrainingDayProps {
  date: Date;
  index: number;
  onRemove: (index: number) => void;
}

const TrainingDay = ({ date, index, onRemove }: TrainingDayProps) => {
  const { control } = useFormContext<TrainingDaysFormInputs>();

  return (
    <FormField
      key={date.toISOString() + index}
      control={control}
      name={`trainingDays.${index}`}
      render={({ field: itemField }) => {
        return (
          <FormItem>
            <div className="grid grid-cols-5 items-center space-x-2">
              <FormLabel className="col-span-2">{`Jour ${index + 1} : ${format(new Date(itemField.value.date.toISOString()), "PPP", {
                locale: fr,
              })}`}</FormLabel>
              <FormControl className="col-span-3">
                <div className="grid grid-cols-5 items-center space-x-2">
                  <div className="col-span-3">
                    <Combobox
                      options={DayBlockOptions}
                      value={itemField.value.block}
                      onChange={value => itemField.onChange({ ...itemField.value, block: value })}
                      placeholder="Selectionner le bloc ..."
                      search={{
                        notFoundText: "Pas de bloc trouvé.",
                        commandInputPlaceHolder: "Chercher bloc...",
                      }}
                    />
                  </div>
                  <button
                    type="button"
                    className="cursor-pointer bg-white text-red-500 opacity-100 hover:text-red-700"
                    onClick={() => onRemove(index)}
                  >
                    <CircleX />
                  </button>
                </div>
              </FormControl>
            </div>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
};
