import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { CancelBatchEnrollmentCommand } from "@academy-context/primary/provider/provider-batches/cancel-batch-enrollment-modal";

export const cancelBatchEnrollment = createAsyncThunk<void, CancelBatchEnrollmentCommand, { extra: Partial<Dependencies> }>(
  "providers/cancelBatchEnrollment",
  async (command: CancelBatchEnrollmentCommand, { extra: { providerRepository } }) => {
    return providerRepository!.cancelEnrollment(command);
  }
);
