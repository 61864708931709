import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { UpdateFundingRequestBody } from "@shared-kernel/application/ports/shared/funding-request-repository";

export const updateFundingRequest = createAsyncThunk<void, UpdateFundingRequestBody, { extra: Partial<Dependencies> }>(
  "fundingRequests/updateFundingRequest",
  async (body: UpdateFundingRequestBody, { extra: { fundingRequestRepository } }) => {
    return fundingRequestRepository!.update(body);
  }
);

export const resetUpdateFundingRequest = createAction("fundingRequests/resetUpdateFundingRequest");
