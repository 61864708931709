import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { ProviderStudent } from "@user-management-context/read/domain/types/provider/student";

export const retrieveProviderStudents = createAsyncThunk<ProviderStudent[], void, { extra: Partial<Dependencies> }>(
  "providers/retrieveProviderStudents",
  async (_, { extra: { providerRepository } }) => {
    return providerRepository!.getProviderStudents();
  }
);

export const resetRetrieveProviderStudents = createAction("users/resetRetrieveProviderStudents");
