import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import { useEvaluationCompleted } from "@academy-context/primary/shared/educational-advisor-training-follow-up-evaluation-form/use-evaluation-completed";
import { retrieveTrainingFollowUpEvaluations } from "@academy-context/read/application/use-cases/shared/training-follow-up-evaluations-retrieval/retrieve-training-follow-up-evaluations";
import { TrainingFollowUpEvaluationForm } from "@academy-context/primary/shared/educational-advisor-training-follow-up-evaluation-form/training-follow-up-evaluation-form.components";
import {
  evaluateTrainingFollowUpObjective,
  EvaluateTrainingFollowUpObjectivePayload,
} from "@academy-context/write/application/use-cases/shared/training-follow-up-objective-evaluation/evaluate-training-follow-up-objective";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";

interface Props {
  role: ROLES.TEACHER | ROLES.PROVIDER;
}

export const EducationalAdvisorTrainingFollowUpEvaluationsContainer = ({ role }: Props) => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { data: evaluations } = useAppSelector((state: AppState) => state.trainingFollowUpEvaluationsRetrieval);

  useEffect(() => {
    if (id) dispatch(retrieveTrainingFollowUpEvaluations({ trainingId: id }));
  }, [dispatch, id]);

  useEvaluationCompleted({ trainingId: id!, role });

  if (!id) return <></>;

  const onSubmit = (body: EvaluateTrainingFollowUpObjectivePayload) => {
    dispatch(evaluateTrainingFollowUpObjective(body));
  };

  return <TrainingFollowUpEvaluationForm evaluations={evaluations} trainingFollowUpId={id} onSubmit={onSubmit} />;
};
