import { createReducer } from "@reduxjs/toolkit";
import { ProcessingState } from "@redux/app-state";
import {
  resetUpdateStudentTimelineEvent,
  updateStudentTimelineEvent,
} from "@academy-context/write/application/use-cases/admin/student-timeline-event-update/update-student-timeline-event";

const initialState: ProcessingState = { processing: "idle" };

export const updateStudentTimelineEventReducer = createReducer<ProcessingState>(initialState, builder => {
  builder.addCase(updateStudentTimelineEvent.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(updateStudentTimelineEvent.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(updateStudentTimelineEvent.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetUpdateStudentTimelineEvent, () => {
    return initialState;
  });
});
