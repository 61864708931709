import { ColumnDef } from "@tanstack/react-table";
import { formatDateToLocale } from "../../../../utils/formatting";
import { Link } from "react-router-dom";
import { EducationalAdvisorTrainingFollowUpListVM } from "@academy-context/read/domain/types/training-follow-up";
import { DangerSign } from "@shared-kernel/primary/shared/danger-sign/danger-sign";
import { Separator } from "@shared-kernel/primary/shared/shadcn/ui/separator";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { ROLE_BASED_URLS } from "src/routes";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";

const linkWrapper = (id: string, value: string) => (
  <Link to={`${ROLE_BASED_URLS[ROLES.TEACHER].trainingFollowUp.list}/${id}`}>
    <span>{value}</span>
  </Link>
);

const columns: ColumnDef<EducationalAdvisorTrainingFollowUpListVM>[] = [
  {
    accessorKey: "isAvailableAction",
    header: () => "Action requise",
    cell: ({ row }) =>
      row.original.isAvailableAction ? (
        <div className="flex justify-center">
          <DangerSign />
        </div>
      ) : null,
    meta: {
      title: "Action requise",
      width: "60px",
    },
  },
  {
    accessorKey: "date",
    header: () => "Date",
    cell: ({ row }) => linkWrapper(row.original.id, formatDateToLocale(row.original.date)),
    meta: {
      title: "Date",
      width: "90px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "title",
    header: () => "Formation administrative auprès du financeur",
    cell: ({ row }) => linkWrapper(row.original.id, row.original.title),
    meta: {
      title: "Formation administrative",
    },
  },
  {
    id: "providerBatchTitle",
    accessorKey: "providerBatchTitle",
    header: () => "Formation prestataire",
    cell: ({ row }) => (row.original.providerBatchTitle ? linkWrapper(row.original.id, row.original.providerBatchTitle) : null),
    meta: {
      title: "Formation prestataire",
    },
  },
  {
    accessorKey: "studentDetails.name",
    header: () => "Elève",
    cell: ({ row }) => linkWrapper(row.original.id, `${row.original.studentDetails.name} ${row.original.studentDetails.lastName}`),
    meta: {
      title: "Elève",
      width: "300px",
    },
    enableSorting: true,
  },
];

interface Props {
  trainingFollowUps: EducationalAdvisorTrainingFollowUpListVM[];
  isTeacher?: boolean;
}

export const EducationalAdvisorTrainingFollowUpList = ({ trainingFollowUps, isTeacher = false }: Props) => {
  const filteredColumns = isTeacher ? columns.filter(c => c.id !== "providerBatchTitle") : columns;
  return (
    <CustomCard title="Suivi qualité de vos formations">
      <div className="mb-4 w-full">
        <p>
          Vous trouverez ci-dessous l’ensemble des formations de vos élèves nécessitant une évaluation de votre part.
          <br />
          En cliquant sur le nom de la formation, vous pourrez évaluer la formation de l'élève.
        </p>
        <div className="flex">
          <DangerSign /> <span className="ml-2">: Lorsque ce logo apparait, une action de votre part est requise.</span>
        </div>
      </div>
      <Separator className="bg-gray-400" />
      <DataTable
        columns={filteredColumns}
        data={trainingFollowUps}
        sortField="date"
        order="desc"
        searchPlaceHolder="Rechercher une formation"
      />
    </CustomCard>
  );
};
