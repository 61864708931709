import { InitialConfigType, LexicalComposer } from "@lexical/react/LexicalComposer";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { $getRoot, $insertNodes, $setSelection, LexicalEditor } from "lexical";
import { $generateNodesFromDOM } from "@lexical/html";
import { cn } from "@components/utils/utils";
import { DisabledPlugin } from "@shared-kernel/primary/shared/text-editor/plugins/disabled";
import { OnChangePluginImplementation } from "@shared-kernel/primary/shared/text-editor/plugins/on-change";
import { OutsideChangesPlugin } from "@shared-kernel/primary/shared/text-editor/plugins/outside-changes";

interface PlainTextProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T>;
  defaultValue?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  dataTestId?: string;
}

const theme = {
  paragraph: "plain-text-paragraph",
  root: "outline-none",
};

function PlainTextLexical<T extends FieldValues>({
  name,
  control,
  defaultValue,
  placeholder,
  className,
  disabled = false,
  dataTestId,
}: PlainTextProps<T>): JSX.Element {
  const initialConfig: InitialConfigType = {
    namespace: "PlainTextEditor",
    theme,
    onError: (error: Error) => {
      console.error(error);
    },
    editorState: (editor: LexicalEditor) => {
      if (defaultValue) {
        editor.update(() => {
          const parser = new DOMParser();
          const dom = parser.parseFromString(defaultValue, "text/html");
          const nodes = $generateNodesFromDOM(editor, dom);
          const root = $getRoot();
          root.select();
          root.clear();
          $insertNodes(nodes);
          // Removes focus from the editor
          $setSelection(null);
        });
      }
    },
  };

  return (
    <Controller<T>
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LexicalComposer initialConfig={initialConfig}>
          <div className={cn("relative rounded-md border border-gray-300 bg-white", error && "border-red-500")}>
            <div className={cn("h-[150px] overflow-hidden", className)}>
              <PlainTextPlugin
                contentEditable={
                  <div className="relative h-full">
                    <ContentEditable
                      className={cn("absolute inset-0 overflow-y-auto px-4 py-3 text-sm leading-5")}
                      data-testid={dataTestId}
                    />
                  </div>
                }
                placeholder={
                  <div className={cn("absolute top-3 left-4 pointer-events-none select-none leading-5 text-gray-400")}>{placeholder}</div>
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
            </div>
            <OnChangePluginImplementation onChange={onChange} />
            <OutsideChangesPlugin value={value || defaultValue} />
            {disabled !== undefined && <DisabledPlugin disabled={disabled} />}
            <HistoryPlugin />
          </div>
        </LexicalComposer>
      )}
    />
  );
}

export default PlainTextLexical;
