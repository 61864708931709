import { createReducer } from "@reduxjs/toolkit";
import { ProcessingState } from "@redux/app-state";
import { toggleTrainingFollowUpDunningsStatus } from "./toggle-training-follow-up-dunnings-status";

const initialState: ProcessingState = { processing: "idle" };

export const toggleTrainingFollowUpDunningsStatusReducer = createReducer<ProcessingState>(initialState, builder => {
  builder.addCase(toggleTrainingFollowUpDunningsStatus.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(toggleTrainingFollowUpDunningsStatus.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(toggleTrainingFollowUpDunningsStatus.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
});
