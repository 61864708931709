import { StudentSummaryCard } from "./student-summary-card.component";
import { WritingListSummary } from "./lessons-list-summary.components";
import { selectStudentSummaryRetrieval } from "@academy-context/read/application/use-cases/student/summary-retrieval/selectors/student-summary-selectors";

interface Props {
  summary: ReturnType<typeof selectStudentSummaryRetrieval>["data"];
  adminView?: boolean;
}

export const StudentSummary = ({ summary, adminView = false }: Props) => {
  const entries = adminView ? summary.lastEntries : summary.lastEntries.slice(0, 10);
  return (
    <div>
      <div className="mb-5 grid gap-4">
        <StudentSummaryCard
          nextFundingDate={summary.nextFundingDate}
          localeNextFundingDate={summary.localeNextFundingDate}
          balance={summary.balance}
        />
      </div>
      <WritingListSummary adminView={adminView} entries={entries} />
    </div>
  );
};
