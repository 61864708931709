import { createReducer } from "@reduxjs/toolkit";
import { evaluateTrainingFollowUpObjective, resetEvaluateTrainingFollowUpObjective } from "./evaluate-training-follow-up-objective";
import { ProcessingState } from "@redux/app-state";

const initialState: ProcessingState = { processing: "idle" };

export const evaluateTrainingFollowUpObjectiveReducer = createReducer<ProcessingState>(initialState, builder => {
  builder.addCase(evaluateTrainingFollowUpObjective.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(evaluateTrainingFollowUpObjective.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(evaluateTrainingFollowUpObjective.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetEvaluateTrainingFollowUpObjective, () => {
    return initialState;
  });
});
