import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { FundingRequestApprovalForm } from "./funding-request-approval-form.component";
import { approveFundingRequest } from "../../../write/application/use-cases/admin/admin-funding-request-approval/approve-funding-request";
import { retrieveSubmittedToFunderFundingRequests } from "../../../read/application/use-cases/admin/funding-request-list/submitted-to-funder/retrieve-submitted-to-funder-funding-requests";
import { Card, CardContent } from "@components/ui/card";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { AppState } from "@redux/app-state";
import { selectFundingRequestApproval } from "@academy-context/primary/admin/funding-detail/use-funding-request-approved";
import { FormSkeleton } from "@shared-kernel/primary/shared/skeletons/form.skeleton";
import { FundingsList } from "@academy-context/primary/admin/funding-detail/funding-list.components";
import { selectFundingsRetrievalForList } from "@academy-context/read/application/use-cases/admin/fundings-retrieval/selectors/funding-list-selectors";
import { retrieveFundings } from "@academy-context/read/application/use-cases/admin/fundings-retrieval/retrieve-fundings";
import { ApproveFundingRequestBody } from "@shared-kernel/application/ports/shared/funding-request-repository";

export const FundingAdminDetail = () => {
  const dispatch = useAppDispatch();
  const { data: fundings, isLoading } = useAppSelector(selectFundingsRetrievalForList);
  const { data: fundingRequests } = useAppSelector((state: AppState) => state.submittedToFunderFundingRequestsRetrieval);
  const { processing } = useAppSelector(selectFundingRequestApproval);

  useEffect(() => {
    dispatch(retrieveSubmittedToFunderFundingRequests());
    dispatch(retrieveFundings());
  }, [dispatch]);

  const onApproveFundingRequest = async (fundingRequest: ApproveFundingRequestBody) => {
    await dispatch(approveFundingRequest(fundingRequest));
  };

  if (isLoading) return <FormSkeleton />;

  return (
    <>
      <CustomCard title="Nouveau financement">
        <FundingRequestApprovalForm onSubmit={onApproveFundingRequest} fundingRequests={fundingRequests} processing={processing} />
      </CustomCard>
      <Card className="mt-4">
        <CardContent className="flex flex-col items-center pt-3 text-black dark:text-white">
          <FundingsList fundings={fundings} />
        </CardContent>
      </Card>
    </>
  );
};
