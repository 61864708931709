import { createReducer } from "@reduxjs/toolkit";
import { ProcessingState } from "@redux/app-state";
import { signAttendanceSheet } from "./sign-attendance-sheet";

const initialState: ProcessingState = { processing: "idle" };

export const signAttendanceSheetReducer = createReducer<ProcessingState>(initialState, builder => {
  builder.addCase(signAttendanceSheet.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(signAttendanceSheet.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(signAttendanceSheet.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
});
