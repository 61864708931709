import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot, $insertNodes, $setSelection } from "lexical";
import { useEffect } from "react";

export function OutsideChangesPlugin({ value }: { value?: string }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    // Skip update if no value provided
    if (value === undefined) return;

    // Check if content actually needs to be updated
    const currentContent = editor.getEditorState().read(() => {
      $getRoot();
      return $generateHtmlFromNodes(editor, null);
    });

    // Only update if content is changed from outside
    const isInternalChange = value === currentContent;
    if (isInternalChange) {
      return;
    }

    editor.update(() => {
      const root = $getRoot();
      if (!value) {
        root.clear();
        $setSelection(null);
        return;
      }
      const parser = new DOMParser();
      const dom = parser.parseFromString(value, "text/html");
      const nodes = $generateNodesFromDOM(editor, dom);
      root.select();
      root.clear();
      $insertNodes(nodes);
      $setSelection(null);
    });
  }, [editor, value]);

  return null;
}
