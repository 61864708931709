import { array, number, object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { RefundRequestBody } from "@academy-context/write/domain/types/admin/refund-request";
import { isValid } from "iban";
import { Nullable } from "@shared-kernel/core/types/nullable";

export const MAX_REFUND_REQUEST_DESCRIPTION_CHARACTERS = 255;
export const MAX_REFUND_REQUEST_PAYMENT_LABEL_CHARACTERS = 30;
export const MAX_INVOICE_PRICE = 4000;
export const MIN_INVOICE_PRICE = 1;

export type FormInputs = {
  iban: string;
  paymentLabel: string;
  commentary: string;
  invoices: {
    amount: number;
    url: Nullable<string>;
  }[];
};

export const defaultValues = {
  iban: "",
  paymentLabel: "",
  commentary: "",
  invoices: [],
};

export const schema = object().shape({
  iban: string()
    .max(255)
    .required(REQUIRED_FIELD)
    .test("iban", "L'IBAN renseigné est invalide", value => value != null && isValid(value)),
  paymentLabel: string().max(MAX_REFUND_REQUEST_PAYMENT_LABEL_CHARACTERS).required(REQUIRED_FIELD),
  commentary: string().nullable(),
  invoices: array()
    .min(1, "Requiert au moins une facture")
    .of(
      object().shape({
        amount: number()
          .typeError("Une valeur est nécessaire")
          .min(MIN_INVOICE_PRICE, `minimum ${MIN_INVOICE_PRICE}€`)
          .max(MAX_INVOICE_PRICE, `maximum ${MAX_INVOICE_PRICE}€`)
          .required(REQUIRED_FIELD),
        url: string().url("URL invalide").max(255).required(REQUIRED_FIELD),
      })
    ),
});

export const formatFormDataToBodyData = (refundRequest: FormInputs): RefundRequestBody => {
  return {
    iban: refundRequest.iban,
    paymentLabel: refundRequest.paymentLabel,
    commentary: refundRequest.commentary || null,
    // @ts-expect-error Yup already checks if null
    invoices: refundRequest.invoices.filter(i => Boolean(i.url)),
  };
};
