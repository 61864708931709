import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { retrieveTeacher } from "../../../../user-management-context/read/application/use-cases/admin/admin-teacher-retrieval/retrieve-teacher";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { selectTeacherRetrieval } from "../../../../user-management-context/read/application/use-cases/admin/admin-teacher-retrieval/selectors/teacher-detail-selectors";
import { useTeacherUpdate } from "./use-teacher-update";
import { TeacherForm } from "./teacher-form.component";
import { AdminUpdateTeacherBody } from "@user-management-context/write/domain/types/admin/teacher";
import { updateTeacher } from "../../../../user-management-context/write/application/use-cases/admin/teacher-update/update-teacher";

export const TeacherAdminDetail = () => {
  const dispatch = useAppDispatch();
  const teacher = useAppSelector(selectTeacherRetrieval);

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) dispatch(retrieveTeacher(id));
  }, [dispatch, id]);

  const saveTeacher = async (teacher: AdminUpdateTeacherBody) => {
    await dispatch(updateTeacher({ id: id as string, body: teacher }));
  };

  useTeacherUpdate();

  if (!teacher) return null;

  return <TeacherForm teacher={teacher} onSubmit={saveTeacher} />;
};
