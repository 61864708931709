import { AppState } from "@redux/app-state";
import { convertDateToMonthAndYear } from "../../../../../../../utils/formatting";

const labels: Record<"balance_adjustment" | "standard_lesson" | "funding_request" | "refund" | "participation", string> = {
  balance_adjustment: "Ajustement de solde",
  standard_lesson: "Cours",
  funding_request: "Financement",
  refund: "Remboursement via demande",
  participation: "Stage prestataire",
};

export const selectStudentSummaryRetrieval = (state: AppState) => {
  const {
    studentSummaryRetrieval: { data: summary },
  } = state;

  if (!summary) return { data: { lastEntries: [], nextFundingDate: null, localeNextFundingDate: null, balance: 0 } };

  const entries = [...summary.lastEntries]
    .map(l => {
      const isFunding = ["funding_request"].includes(l.type);
      const isBalanceAdjustment = ["balance_adjustment"].includes(l.type);
      const isCredited = isFunding || (isBalanceAdjustment && l.totalAmount > 0);
      return {
        date: l.date,
        localeDate: new Date(l.date).toLocaleDateString("fr-FR"),
        category: labels[l.type],
        label: l.detail,
        totalAmount: l.totalAmount,
        commentary: l.commentary,
        isCredited,
        isBalanceAdjustment,
      };
    })
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  return {
    data: {
      ...summary,
      localeNextFundingDate: summary.nextFundingDate ? convertDateToMonthAndYear(summary.nextFundingDate) : null,
      lastEntries: entries,
    },
  };
};
