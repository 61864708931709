import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { ApproveFundingRequestBody } from "@shared-kernel/application/ports/shared/funding-request-repository";

export const approveFundingRequest = createAsyncThunk<void, ApproveFundingRequestBody, { extra: Partial<Dependencies> }>(
  "fundingRequests/approveFundingRequest",
  async (fundingRequest: ApproveFundingRequestBody, { extra: { fundingRequestRepository } }) => {
    return fundingRequestRepository!.approve(fundingRequest);
  }
);

export const resetApproveFundingRequest = createAction("fundingRequests/resetApproveFundingRequest");
