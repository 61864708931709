import { Nullable } from "@shared-kernel/core/types/nullable";
import { ORGANIZATION } from "@shared-kernel/domain/organisation";

export interface Student {
  id: string;
  name: string;
  lastName: string;
  email: string;
  mobile: Nullable<string>;
  favoriteTeachers: string[];
  blackListed: boolean;
  profilePictureUrl: string | null;
  canRequestARefundOnce: boolean;
  canRequestANonAfdasFundingOnce: boolean;
  adminId: Nullable<string>;
}

export enum USER_STATUS {
  REGISTERED = "registered",
  NOT_REGISTERED = "not_registered",
  INVITATION_SENT = "invitation_sent",
  EXPIRED_INVITATION = "expired_invitation",
}

export interface StudentListElement {
  userId: string;
  name: string;
  lastName: string;
  userStatus: USER_STATUS;
  email: string;
  details: {
    studentId: string;
    balance: {
      global: number;
      byOrganization: Record<ORGANIZATION, number>;
    };
    isBlacklisted: boolean;
    nextFundingDate: Nullable<string>;
    isFundingRequestInProgress: boolean;
    admin: {
      id: string;
      name: string;
      lastName: string;
    } | null;
  };
}
