import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";

import { retrieveLessons } from "../../../read/application/use-cases/teacher/lessons-retrieval/retrieve-lessons";
import { selectTeacherLessonsRetrievalForList } from "../../../read/application/use-cases/teacher/lessons-retrieval/selectors/lessons-list-selectors";
import { TeacherLessonListVM } from "@academy-context/read/domain/types/teacher/lesson";
import { ColumnDef } from "@tanstack/react-table";
import { formatDateToLocale, formatToCurrency } from "../../../../utils/formatting";
import { getPlaceName } from "@academy-context/write/domain/services/locations";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import parse from "html-react-parser";

const columns: ColumnDef<TeacherLessonListVM>[] = [
  {
    accessorKey: "creationDate",
    header: () => "Enregistré le",
    cell: ({ row }) => {
      const { creationDate } = row.original;
      const localeDate = formatDateToLocale(creationDate);
      return localeDate;
    },
    meta: {
      title: "Enregistré le",
      width: "95px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "date",
    header: () => "Date du cours",
    cell: ({ row }) => {
      const { date } = row.original;
      const localeDate = formatDateToLocale(date);
      return localeDate;
    },
    meta: {
      title: "Date du cours",
      width: "95px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "student.fullName",
    header: () => "Elève",
    cell: ({ row }) => {
      const { student } = row.original;
      return student.fullName;
    },
    enableSorting: true,
    meta: {
      title: "Elève",
    },
  },
  {
    accessorKey: "place",
    header: () => "Studio",
    cell: ({ row }) => {
      const { place } = row.original;
      return getPlaceName(place.paidByTeacher, place.name);
    },
    meta: {
      title: "Studio",
    },
  },
  {
    accessorKey: "hours",
    header: () => "Durée du cours (heures)",
    cell: ({ row }) => {
      const { hours } = row.original;
      return hours;
    },
    meta: {
      title: "Durée du cours",
      width: "90px",
    },
  },
  {
    accessorKey: "hourlyPrice",
    header: () => "Tarif horaire (€/heure)",
    cell: ({ row }) => {
      const { hourlyPrice } = row.original;
      return formatToCurrency(hourlyPrice);
    },
    meta: {
      title: "Tarif horaire professeur",
      width: "90px",
    },
  },
  {
    accessorKey: "paidToTeacher",
    header: () => "Règlement professeur",
    cell: ({ row }) => {
      const { paidToTeacher } = row.original;
      return formatToCurrency(paidToTeacher);
    },
    meta: {
      title: "Règlement professeur",
      width: "90px",
    },
  },
  {
    accessorKey: "commentary",
    header: () => "Commentaire",
    cell: info => (info.row.original.commentary ? parse(info.row.original.commentary) : null),
    enableSorting: false,
    meta: {
      title: "Commentaire",
    },
  },
];

export const TeacherLessonsList = () => {
  const dispatch = useAppDispatch();
  const { data: lessons } = useAppSelector(selectTeacherLessonsRetrievalForList);

  useEffect(() => {
    dispatch(retrieveLessons());
  }, [dispatch]);

  return (
    <CustomCard title="HISTORIQUE DES COURS">
      <DataTable
        columns={columns}
        data={lessons}
        sortField="creationDate"
        order="desc"
        searchPlaceHolder="Rechercher un cours"
        displayDataViewOptions
      />
    </CustomCard>
  );
};
