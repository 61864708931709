import {
  CancellationItem,
  EnrollmentItem,
  ParticipationItem,
} from "@academy-context/primary/provider/provider-batches/batch-student-items";
import { BATCH_STATUS } from "@academy-context/read/application/use-cases/shared/selectors/format-batches-for-list";
import { PROVIDER_BATCH_ENROLLMENT_STATUS } from "@academy-context/read/domain/types/enums/provider-batch-enrollment-status";
import { PROVIDER_BATCH_PARTICIPATION_STATUS } from "@academy-context/read/domain/types/enums/provider-batch-participation-status";
import { ProviderBatchItem } from "@academy-context/read/domain/types/shared/provider-batch";
import { confirmProviderBatchEnrollment } from "@academy-context/write/application/use-cases/provider/provider-batch-enrollment-confirmation/confirm-provider-batch-enrollment";
import { submitProviderBatchParticipationInvoice } from "@academy-context/write/application/use-cases/provider/provider-batch-participation-invoice-submission/submit-provider-batch-participation-invoice";
import { Badge } from "@components/ui/badge";
import { Button } from "@components/ui/button";
import { Card, CardHeader } from "@components/ui/card";
import { Separator } from "@components/ui/separator";
import { cn } from "@components/utils/utils";
import { ProviderBatchParticipationInvoiceUploadState } from "@redux/app-state";
import { useAppDispatch } from "@redux/hooks";
import { Nullable } from "@shared-kernel/core/types/nullable";
import {
  ConfirmBatchEnrollmentBody,
  SubmitBatchParticipationInvoiceBody,
} from "@user-management-context/shared/application/ports/provider-repository";
import { formatDateToLocale } from "@utils/formatting";
import { Calendar, Plus } from "lucide-react";
import { ReactElement } from "react";

const batchStatusElement: Record<BATCH_STATUS, ReactElement> = {
  [BATCH_STATUS.UPCOMING]: <Badge variant="secondary">À venir</Badge>,
  [BATCH_STATUS.FINISHED]: <Badge variant="destructive">Terminé</Badge>,
  [BATCH_STATUS.ARCHIVED]: <Badge variant="default">Terminé (Archivé)</Badge>,
  [BATCH_STATUS.ONGOING]: <Badge className="bg-green-500">En cours</Badge>,
};

export interface ProviderBatchStudent {
  name: string;
  lastName: string;
  status: PROVIDER_BATCH_ENROLLMENT_STATUS | PROVIDER_BATCH_PARTICIPATION_STATUS;
}

interface Props {
  batch: ProviderBatchItem & {
    cancellations: (ProviderBatchStudent & { cancellationDate: Nullable<string>; cancellationMessage: Nullable<string> })[];
    status: BATCH_STATUS;
  };
  uploadedInvoice: ProviderBatchParticipationInvoiceUploadState;
  onUploadInvoice: (invoice: File) => void;
  onResetUploadInvoice: () => void;
  isAdmin?: boolean;
  openCalendarModal: (batch: ProviderBatchItem) => void;
  openEnrollmentModal: (batch: ProviderBatchItem) => void;
}

export const ProviderBatch = ({
  batch,
  uploadedInvoice,
  onUploadInvoice,
  onResetUploadInvoice,
  isAdmin = false,
  openCalendarModal,
  openEnrollmentModal,
}: Props) => {
  const dispatch = useAppDispatch();

  const onConfirmBatchEnrollment = (body: ConfirmBatchEnrollmentBody) => {
    dispatch(confirmProviderBatchEnrollment(body));
  };

  const onSubmitBatchParticipationInvoice = (body: SubmitBatchParticipationInvoiceBody) => {
    dispatch(submitProviderBatchParticipationInvoice(body));
  };

  const canEnrollStudent = [BATCH_STATUS.FINISHED, BATCH_STATUS.ARCHIVED].includes(batch.status);
  const isArchived = batch.status === BATCH_STATUS.ARCHIVED;

  return (
    <Card className={cn("text-black", isArchived && "bg-gray-500")}>
      <CardHeader className="grid grid-cols-5 p-2">
        <div className="col-span-1 flex items-center justify-center">
          {batchStatusElement[batch.status]}
          <div
            className="ml-2 cursor-pointer"
            onClick={() => {
              openCalendarModal(batch);
            }}
          >
            <Calendar className="size-4" />
          </div>
        </div>
        <div className="col-span-3 mx-2 flex flex-col items-center">
          <div>
            <span className="ml-3 font-light">du</span>
            <span className="ml-3 text-2xl font-extrabold">{formatDateToLocale(batch.interval.start)}</span>
            <span className="ml-3 font-light">au</span>
            <span className="ml-3 text-2xl font-extrabold">{formatDateToLocale(batch.interval.end)}</span>
          </div>
          <div>
            {batch.title} - {batch.price}€
          </div>
        </div>
        <div className="col-span-1 flex items-center justify-center">
          <Button
            onClick={() => {
              openEnrollmentModal(batch);
            }}
            disabled={canEnrollStudent}
          >
            <Plus />
            Ajouter un élève à la session
          </Button>
        </div>
      </CardHeader>
      <Separator className="bg-gray-400" />
      <div className="flex items-center justify-center p-3">
        <div className="mt-3 w-11/12">
          <div>
            <h3 className="font-bold">
              Elèves <span className="text-red-500">non-confirmés</span> pour la session
            </h3>
            <Separator className="bg-gray-400" />
            <div className="mt-3">
              {batch.enrollments.map(enrollment => (
                <EnrollmentItem
                  key={enrollment.id}
                  enrollment={enrollment}
                  onConfirmBatchEnrollment={onConfirmBatchEnrollment}
                  batch={batch}
                  isAdmin={isAdmin}
                />
              ))}
            </div>
          </div>
          <div className="mt-3">
            <h3 className="font-bold">
              Elèves <span className="text-green-500">confirmés</span> pour la session
            </h3>
            <Separator className="bg-gray-400" />
            <div className="mt-3">
              {batch.participations.map(participation => (
                <ParticipationItem
                  key={participation.id}
                  participation={participation}
                  onSubmitBatchParticipationInvoice={onSubmitBatchParticipationInvoice}
                  uploadedInvoice={uploadedInvoice}
                  onUploadInvoice={onUploadInvoice}
                  onResetUploadInvoice={onResetUploadInvoice}
                  batch={batch}
                />
              ))}
            </div>
          </div>
          {batch.cancellations.length > 0 && (
            <div className="mt-3">
              <h3 className="font-bold">
                Elèves <span className="text-red-500">annulés</span> pour la session
              </h3>
              <Separator className="bg-gray-400" />
              <div className="mt-3">
                {batch.cancellations.map(student => (
                  <CancellationItem key={student.name} student={student} />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};
