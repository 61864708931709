import { PropsWithChildren } from "react";
import { ImageFileProps } from "../../../../academy-context/write/domain/constants/shared";
import { Picture } from "./picture";
import { UploadButton } from "./upload-button";
import { RequestState } from "src/redux/app-state";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { Label } from "@shared-kernel/primary/shared/shadcn/ui/label";

const loadImage = (blob: Blob) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const objectUrl = URL.createObjectURL(blob);
    const img = new Image();
    img.addEventListener("load", () => resolve(img));
    img.addEventListener("error", err => reject(err));
    img.src = objectUrl;
  });

const getImageFileProps = async (file: File): Promise<ImageFileProps> => {
  const img: HTMLImageElement = await loadImage(file);
  return {
    fileSize: file.size,
    type: file.type,
    width: img.naturalWidth,
    height: img.naturalHeight,
  };
};

interface Props {
  processing: RequestState;
  label?: string;
  value: Nullable<string>;
  onChange: (file: File) => void;
  types: string[];
  onError: () => void;
  onDelete?: () => void;
  validatorFunc: (props: ImageFileProps) => boolean;
}

const ImageUploadButton = ({
  label = "Uploader",
  value = "",
  onChange,
  onError,
  validatorFunc,
  types,
  processing,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <div>
      <div className="mb-1">
        <Label htmlFor="element">{label}</Label>
      </div>
      {value && <Picture img={value} />}
      <div className="mb-1">{children}</div>
      <UploadButton<ImageFileProps>
        processing={processing}
        value={value}
        onChange={onChange}
        onError={onError}
        types={types}
        validatorFunc={validatorFunc}
        getFileProps={getImageFileProps}
      />
    </div>
  );
};

export default ImageUploadButton;
