import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { UseFormReset } from "react-hook-form";
import { FormInputs } from "./form-validation/lesson";
import { adminResetCreateLesson } from "../../../write/application/use-cases/admin/lesson-creation/create-lesson";
import { retrieveStudents } from "../../../../user-management-context/read/application/use-cases/admin/students-retrieval/retrieve-students";
import { adminRetrieveLessons } from "@academy-context/read/application/use-cases/admin/lessons-retrieval/all/retrieve-lessons";

export const selectLessonCreate = (state: AppState) => state.adminLessonCreate;

interface Props {
  reset: UseFormReset<FormInputs>;
}

export const useLessonCreate = ({ reset }: Props) => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { justCreatedLessonId, fetching } = useAppSelector(selectLessonCreate);

  useEffect(() => {
    if (fetching === "success" && justCreatedLessonId) {
      toast({ description: "Cours ajouté !" });
      reset();
      dispatch(adminResetCreateLesson());
      dispatch(adminRetrieveLessons());
      dispatch(retrieveStudents());
    } else if (fetching === "failed") {
      toast({ description: "Erreur lors de l'ajout du cours", variant: "destructive" });
    }
  }, [justCreatedLessonId, fetching, dispatch, toast, reset]);
};
