import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { adminRetrieveProviderBatches } from "@academy-context/read/application/use-cases/admin/provider-batches-retrieval/retrieve-provider-batches";
import { resetBatchParticipationCompletionCheck } from "@academy-context/write/application/use-cases/admin/provider-batch-participation-completion/trigger-batch-participation-completion-check";

interface Props {
  providerId: string;
}

export const useBatchParticipationCompletionCheck = ({ providerId }: Props) => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { processing } = useAppSelector((state: AppState) => state.adminBatchParticipationCompletionCheck);

  useEffect(() => {
    if (processing === "success") {
      if (providerId) dispatch(adminRetrieveProviderBatches(providerId));
      dispatch(resetBatchParticipationCompletionCheck());
    } else if (processing === "failed") {
      toast({
        variant: "destructive",
        title: "Erreur",
        description: "Impossible de rafraîchir les données",
      });
    }
    // We don't want to react on providerId change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, processing, toast]);
};
