import { SURVEY_QUESTION_TYPE } from "@academy-context/shared/domain/types/enums/survey";
import { Textarea } from "@components/ui/textarea";
import { Rating } from "@shared-kernel/primary/shared/rating/rating";
import { Card, CardContent, CardHeader, CardTitle } from "@components/ui/card";
import { TrainingFollowUpSurveyVM } from "@academy-context/read/domain/types/shared/training-follow-up-survey";
import { Label } from "@components/ui/label";

interface Props {
  surveyData: TrainingFollowUpSurveyVM[];
}

export const AdminPostTrainingSurveyComponent = ({ surveyData }: Props) => {
  if (surveyData.length === 0) {
    return <div>Aucune donnée de questionnaire disponible.</div>;
  }
  const isAnswered = surveyData.every(item => Boolean(item.response));

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Questionnaire post-formation</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        {!isAnswered && (
          <div className="mt-2 flex items-center justify-center text-center text-gray-500">
            <div className="size-4 animate-pulse rounded-full bg-gray-400" />
            <h2 className="ml-2 text-lg font-semibold">Non évalué pour le moment</h2>
          </div>
        )}
        {surveyData.map(item => {
          return (
            <div key={item.question.id} className="flex flex-col space-y-2">
              <Label className="text-sm font-normal">{item.question.text}</Label>
              {item.question.type === SURVEY_QUESTION_TYPE.RATING ? (
                <Rating value={Number(item.response) || 0} onValueChange={() => {}} disabled />
              ) : (
                <Textarea placeholder="Votre réponse..." className="resize-none" disabled defaultValue={item.response ?? ""} />
              )}
            </div>
          );
        })}
      </CardContent>
    </Card>
  );
};
