import { AdminProviderBatchListContainer } from "@academy-context/primary/admin/provider-batches/provider-batch-list.container";
import { Page } from "@shared-kernel/primary/shared/page";

export const AdminProviderBatchListPage = () => {
  return (
    <Page title="Sessions">
      <AdminProviderBatchListContainer />
    </Page>
  );
};
