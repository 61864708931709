import parse from "html-react-parser";
import { TrainingFollowUpEvaluation } from "@academy-context/read/domain/types/training-follow-up";
import { ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { EDUCATIONAL_ADVISOR, EDUCATIONAL_ADVISOR_MAP } from "@academy-context/shared/domain/types/enums/education-advisors";
import { useMemo } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@components/ui/card";
import { Separator } from "@components/ui/separator";
import { EducationalAdvisorEvaluationDetailsComponent } from "@academy-context/primary/student/training-follow-up-evaluations/educational-advisor-evaluation.components";

interface Props {
  evaluation: TrainingFollowUpEvaluation;
  teacherOptions: ComboboxOptions[];
  providerOptions: ComboboxOptions[];
}

export const AdminEvaluationCard = ({ evaluation, teacherOptions, providerOptions }: Props) => {
  const { title, quantifiableObjective, assignedEducationalAdvisor, isAbandoned } = evaluation;

  const educationalAdvisor = useMemo(() => {
    if (!assignedEducationalAdvisor) return null;
    return assignedEducationalAdvisor.type === EDUCATIONAL_ADVISOR.TEACHER
      ? teacherOptions.find(t => t.value === assignedEducationalAdvisor.id)
      : providerOptions.find(p => p.value === assignedEducationalAdvisor.id);
  }, [assignedEducationalAdvisor, teacherOptions, providerOptions]);

  return (
    <Card className="w-full">
      <CardHeader className="flex flex-col items-center justify-between ">
        <CardTitle className="text-lg">{title}</CardTitle>
      </CardHeader>
      <Separator className="bg-gray-400" />
      <CardContent className="flex flex-col items-center pt-3 text-black dark:text-white">
        {assignedEducationalAdvisor ? (
          <h4 className="mb-2 font-semibold">
            Assigné à : {educationalAdvisor?.label} ({EDUCATIONAL_ADVISOR_MAP[assignedEducationalAdvisor.type]})
          </h4>
        ) : (
          <h4 className="mb-2 font-semibold">
            {isAbandoned ? "Objectif abandonné par l'élève" : "Non assigné à un intervenant pour le moment"}
          </h4>
        )}
        <div className="grid w-full gap-5">
          <div className="flex flex-col items-center">
            <div className="max-h-[150px] min-h-[150px] w-full overflow-y-auto rounded-md border border-[#ced4da] bg-[#e9ecef] px-2 py-3 text-sm">
              {parse(quantifiableObjective)}
            </div>
          </div>
          <EducationalAdvisorEvaluationDetailsComponent evaluation={evaluation} />
        </div>
      </CardContent>
    </Card>
  );
};
