import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { StudentFundingListVM } from "../../../../domain/types/student/funding";

export const studentRetrieveFundings = createAsyncThunk<StudentFundingListVM[], void, { extra: Partial<Dependencies> }>(
  "fundings/retrieveFundings",
  async (_: void, { extra: { fundingRequestRepository } }) => {
    return fundingRequestRepository!.studentGetAllFundings();
  }
);
