import { useEffect } from "react";
import { retrieveToReviewFundingRequests } from "../../../read/application/use-cases/admin/funding-request-list/to-review-funding-request-list-retrieval/retrieve-to-review-funding-requests";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { Link } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";
import { errorReasonLabelMap, ToReviewFundingRequestVM } from "@academy-context/read/domain/types/admin/funding-request";
import { formatDateToLocale } from "../../../../utils/formatting";
import { Card, CardContent } from "@shared-kernel/primary/shared/shadcn/ui/card";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { ROLE_BASED_URLS } from "src/routes";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { AFDAS_STUDENT_STATUS_LABELS, FUNDER, FUNDER_LABELS } from "@academy-context/shared/domain/types/enums/funders";
import parse from "html-react-parser";
import { Badge } from "@components/ui/badge";

const linkWrapper = (id: string, value: string) => (
  <Link to={`${ROLE_BASED_URLS[ROLES.ADMIN].fundingRequest.list}/${id}`}>
    <div className="size-full">
      <span>{value}</span>
    </div>
  </Link>
);

const badgeColorClass: Record<string, string> = {
  error: "bg-destructive hover:bg-destructive/90",
  info: "bg-primary hover:bg-primary/90",
  success: "bg-success hover:bg-success/90",
};

const pendingFundingRequestscolumns: ColumnDef<ToReviewFundingRequestVM>[] = [
  {
    accessorKey: "creationDate",
    header: () => "Date demande élève",
    cell: info => linkWrapper(info.row.original.id, formatDateToLocale(info.row.original.creationDate)),
    meta: {
      title: "Date demande élève",
      width: "150px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "funder.type",
    header: () => "Financeur",
    cell: info => {
      const label =
        FUNDER_LABELS[info.row.original.funder.type] +
        (info.row.original.funder.type === FUNDER.AFDAS
          ? ` (${AFDAS_STUDENT_STATUS_LABELS[info.row.original.funder.payload.studentStatus]})`
          : "");
      return linkWrapper(info.row.original.id, label);
    },
    meta: {
      title: "Financeur",
    },
    enableSorting: true,
  },
  {
    accessorKey: "student.name",
    header: () => "Elève",
    cell: info => linkWrapper(info.row.original.id, info.row.original.student.name),
    meta: {
      title: "Elève",
    },
    enableSorting: true,
  },
  {
    accessorKey: "student.phone",
    header: () => "Téléphone",
    cell: info => linkWrapper(info.row.original.id, info.row.original.student.phone),
    meta: {
      title: "Téléphone",
    },
  },
  {
    accessorKey: "error",
    header: () => "Statut",
    cell: info => {
      const { error, hasAlreadyBeenReviewed, isReviewDraft } = info.row.original;
      const badges = [];

      if (error) {
        const errorMsg = errorReasonLabelMap[error];
        const label = hasAlreadyBeenReviewed ? `Retour val. (${errorMsg})` : errorMsg;
        badges.push({ type: "error", label });
      } else if (hasAlreadyBeenReviewed) {
        badges.push({ type: "error", label: "Retour val." });
      }

      if (isReviewDraft) {
        badges.push({ type: "info", label: "Sauvegardé" });
      }

      if (badges.length === 0) {
        badges.push({ type: "success", label: "À traiter" });
      }

      return (
        <div className="space-y-1">
          {badges.map((badge, index) => (
            <Badge key={index} className={`mr-1 ${badgeColorClass[badge.type]}`}>
              {badge.label}
            </Badge>
          ))}
        </div>
      );
    },
    meta: {
      title: "Statut",
    },
  },
  {
    accessorKey: "adminCommentary",
    header: () => "Commentaire",
    cell: info => (info.row.original.adminCommentary ? parse(info.row.original.adminCommentary) : null),
    meta: {
      title: "Commentaire",
    },
  },
];

export const FundingRequestToReviewList = () => {
  const dispatch = useAppDispatch();
  const { data: toReview } = useAppSelector((state: AppState) => state.toReviewFundingRequestsRetrieval);

  useEffect(() => {
    dispatch(retrieveToReviewFundingRequests());
  }, [dispatch]);

  return (
    <Card>
      <CardContent className="space-y-2">
        <DataTable
          columns={pendingFundingRequestscolumns}
          data={toReview}
          sortField="creationDate"
          order="desc"
          searchPlaceHolder="Rechercher une demande de financement"
          pageSize={30}
        />
      </CardContent>
    </Card>
  );
};
