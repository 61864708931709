import { createReducer } from "@reduxjs/toolkit";
import { resetConfirmProviderBatchEnrollment, confirmProviderBatchEnrollment } from "./confirm-provider-batch-enrollment";
import { ProcessingState } from "src/redux/app-state";

const initialState: ProcessingState = { processing: "idle" };

export const confirmProviderBatchEnrollmentReducer = createReducer<ProcessingState>(initialState, builder => {
  builder.addCase(confirmProviderBatchEnrollment.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(confirmProviderBatchEnrollment.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(confirmProviderBatchEnrollment.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetConfirmProviderBatchEnrollment, () => {
    return initialState;
  });
});
