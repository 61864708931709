import { useEffect, useRef, useState } from "react";
import { default as ReactSignatureCanvas } from "react-signature-canvas";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { DangerSign } from "@shared-kernel/primary/shared/danger-sign/danger-sign";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Check, Save, Trash } from "lucide-react";
import {
  SessionDayBlockLabelMap,
  TRAINING_SESSION_SOURCE_TYPE,
  TrainingFollowUpAttendanceSheet,
} from "@academy-context/read/domain/types/training-follow-up";
import { EDUCATIONAL_ADVISOR_MAP } from "@academy-context/shared/domain/types/enums/education-advisors";
import { commonAdapters } from "src/common-adapters";
import { cn } from "@components/utils/utils";

interface Props {
  defaultSignatureUrl: Nullable<string>;
  onSubmit: (url: string) => void;
  attendanceSheet: TrainingFollowUpAttendanceSheet;
}

const generateTitle = (status: "signed" | "enabled" | "disabled", attendanceSheet: TrainingFollowUpAttendanceSheet) => {
  const {
    scheduledDate,
    block,
    context: { educationalAdvisor, source },
  } = attendanceSheet;

  const asterisk = source.type === TRAINING_SESSION_SOURCE_TYPE.AUTOMATIC_GENERATION ? "*" : "";

  const sessionDetails = [
    `${new Date(scheduledDate).toLocaleDateString("fr-FR")} : ${SessionDayBlockLabelMap[block]}${asterisk}`,
    educationalAdvisor && `${EDUCATIONAL_ADVISOR_MAP[educationalAdvisor.type]} : ${educationalAdvisor.name}`,
    source.label && `Session : ${source.label}`,
  ].filter(Boolean);

  return (
    <div
      className={cn("flex items-center text-sm", {
        "text-red-500": status === "enabled",
      })}
    >
      <div className="w-6">
        {status === "enabled" && <DangerSign />}
        {status === "signed" && <Check color="green" />}
      </div>
      <div className="ml-2 flex w-full flex-col font-semibold">
        {sessionDetails.map((line, index) => (
          <span key={index} className="truncate">
            {line}
          </span>
        ))}
      </div>
    </div>
  );
};

export const SignatureCanvas = ({ defaultSignatureUrl, onSubmit, attendanceSheet }: Props) => {
  const sigCanvas = useRef<ReactSignatureCanvas>(null);
  const [isTouched, setIsTouched] = useState(false);

  const isAlreadySigned = Boolean(attendanceSheet.studentSignatureUrl);
  const { dateProvider } = commonAdapters;
  const now = dateProvider!.now();
  const isAfterScheduledDate = new Date(attendanceSheet.scheduledDate).getTime() <= now.getTime();
  const isSignatureEnabled = !isAlreadySigned && isAfterScheduledDate;
  const isDefaultSignatureEnabled = Boolean(defaultSignatureUrl);
  const status = isAlreadySigned ? "signed" : isSignatureEnabled ? "enabled" : "disabled";

  useEffect(() => {
    if (isSignatureEnabled) sigCanvas.current?.on();
    else sigCanvas.current?.off();
  }, [isSignatureEnabled]);

  const clear = () => {
    sigCanvas.current?.clear();
    setIsTouched(false);
  };

  const save = () => {
    const url = sigCanvas.current?.toDataURL("image/jpeg", {}) ?? null;
    if (url) onSubmit(url);
  };

  const setDefaultSignature = () => {
    if (defaultSignatureUrl) {
      onSubmit(defaultSignatureUrl);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="h-[60px] w-4/5">{generateTitle(status, attendanceSheet)}</div>
      <div className="mt-2">
        <div className="flex justify-center">
          <div className="h-[170px] w-[360px] shadow-xl">
            {status === "signed" && <img src={attendanceSheet.studentSignatureUrl ?? undefined} alt="signature" className="size-full" />}
            {status === "enabled" && (
              <ReactSignatureCanvas
                ref={sigCanvas}
                canvasProps={{ width: 360, height: 170 }}
                backgroundColor="rgb(255,255,255)"
                onEnd={() => setIsTouched(true)}
              />
            )}
            {status === "disabled" && <div className="size-full bg-[#8080808e]"></div>}
          </div>
        </div>
        <div className="mt-2 flex h-[40px] w-full justify-around">
          {status === "enabled" && (
            <>
              <div className="w-[140px] leading-none">
                {defaultSignatureUrl && (
                  <Button type="submit" disabled={!isDefaultSignatureEnabled} onClick={setDefaultSignature} className="px-0">
                    Utiliser la première signature
                  </Button>
                )}
              </div>
              <Button disabled={!isTouched} onClick={save} type="submit" className="px-1">
                <Save className="mr-2" size={16} />
                Enregistrer
              </Button>
              <Button disabled={!isTouched} onClick={clear} className="px-1">
                <Trash className="mr-2" size={16} />
                Effacer
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
