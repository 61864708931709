import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { TrainingFollowUpAttendanceSheet } from "../../../../domain/types/training-follow-up";

export const retrieveTrainingFollowUpAttendanceSheets = createAsyncThunk<
  TrainingFollowUpAttendanceSheet[],
  string,
  { extra: Partial<Dependencies> }
>("trainingFollowUps/retrieveTrainingFollowUpAttendanceSheets", async (trainingId: string, { extra: { trainingFollowUpGateway } }) => {
  return trainingFollowUpGateway!.getTrainingFollowUpAttendanceSheets(trainingId);
});
