import { AppState } from "@redux/app-state";
import { createSelector } from "@reduxjs/toolkit";
import { selectProvidersRetrievalForList } from "@user-management-context/read/application/use-cases/admin/providers-retrieval/selectors/provider-list-selectors";
import { BATCH_STATUS, formatBatchesForList } from "@academy-context/read/application/use-cases/shared/selectors/format-batches-for-list";

export const selectAllRunningBatches = createSelector(
  [(state: AppState) => state.adminAllProviderBatchesRetrieval, selectProvidersRetrievalForList],
  (batchState, providerState) => {
    const { providers } = providerState;
    const { data: batches, fetching } = batchState;

    const formattedBatches = formatBatchesForList(batches);
    const runningBatches = formattedBatches.filter(batch => batch.status === BATCH_STATUS.ONGOING);

    // Group batches by provider
    const batchesByProvider = providers.reduce((acc, provider) => {
      const providerBatches = runningBatches.filter(batch => batch.provider.id === provider.id);
      if (providerBatches.length > 0) {
        acc.push({
          providerId: provider.id,
          providerName: provider.name,
          batches: providerBatches,
        });
      }
      return acc;
    }, [] as { providerId: string; providerName: string; batches: typeof runningBatches }[]);

    return {
      isLoading: providerState.isLoading || fetching === "pending",
      batchesByProvider,
    };
  }
);
