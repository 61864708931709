import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { Admin } from "@user-management-context/read/domain/types/admin/admin";

export const adminRetrieveAdmins = createAsyncThunk<Admin[], void, { extra: Partial<Dependencies> }>(
  "admins/adminRetrieveAdmins",
  async (_: void, { extra: { userGateway } }) => {
    return userGateway!.adminList();
  }
);
