import { Nullable } from "@shared-kernel/core/types/nullable";
import { FUNDING_REQUEST_STATUS } from "../enums/funding-request-status";
import { EDUCATIONAL_ADVISOR, FundingRequestEducationalAdvisor } from "@academy-context/shared/domain/types/enums/education-advisors";
import { READ_FUNDER_WITH_PAYLOAD } from "@academy-context/shared/domain/types/enums/funders";
import { TRAINING_LEVEL } from "@academy-context/shared/domain/types/enums/training-levels";
import { ORGANIZATION } from "@shared-kernel/domain/organisation";
import { TrainingDaysConfig } from "@academy-context/read/domain/types/shared/training-day";

interface Student {
  id: string;
  name: string;
  phone: string;
}

interface Training {
  startDate: string;
  endDate: string;
  price: number;
  operaOffCommission: number;
  hours: number;
}

interface FundingRequestBase {
  id: string;
  student: Student;
  educationalAdvisor: {
    id: string;
    type: EDUCATIONAL_ADVISOR;
    name: string;
  };
  funder: READ_FUNDER_WITH_PAYLOAD;
  error: Nullable<FUNDING_REQUEST_ERROR_REASONS>;
  hasAlreadyBeenReviewed: boolean;
  isReviewDraft: boolean;
  adminCommentary: Nullable<string>;
  afdasISWaitingPeriodEndDate: Nullable<string>;
}

export type ToReviewFundingRequestVM = FundingRequestBase & {
  status: "to_review";
  creationDate: string;
  reviewDate: Nullable<string>;
  submissionDate: Nullable<string>;
  organization: Nullable<ORGANIZATION>;
};

export type SubmittedToFunderFundingRequestVM = FundingRequestBase & {
  status: "submitted_to_funder";
  creationDate: string;
  reviewDate: string;
  submissionDate: string;
  training: Training;
  organization: ORGANIZATION;
};

export type ReviewedFundingRequestVM = FundingRequestBase & {
  id: number;
  status: "reviewed";
  creationDate: string;
  reviewDate: string;
  submissionDate: string;
  training: Training;
  organization: ORGANIZATION;
};

export type ApprovedFundingRequestVM = FundingRequestBase & {
  id: number;
  status: "approved";
  creationDate: string;
  reviewDate: string;
  submissionDate: string;
  obtentionDate: string;
  organization: ORGANIZATION;
  training: {
    startDate: string;
    endDate: string;
    price: number;
    operaOffCommission: number;
    hours: number;
  };
  externalFundingRequestId: string;
};

export type FundingRequestsVM = (
  | ToReviewFundingRequestVM
  | SubmittedToFunderFundingRequestVM
  | ReviewedFundingRequestVM
  | ApprovedFundingRequestVM
)[];

export interface FundingRequestVM {
  id: string;
  student: { fullName: string; id: string };
  educationalAdvisor: FundingRequestEducationalAdvisor;
  reviewedEducationalAdvisor: Nullable<FundingRequestEducationalAdvisor>;
  funder: READ_FUNDER_WITH_PAYLOAD;
  resumeUrl: Nullable<string>;
  bioUrl: Nullable<string>;
  coverLetter: string;
  personalizedEducationalProject: string;
  internalTrainingId: Nullable<string>;
  reviewedInternalTrainingId: Nullable<string>;
  providerBatch: Nullable<{ id: string; title: string }>;
  reviewedProviderBatchId: Nullable<string>;
  administrativeInternalTrainingId: Nullable<string>;
  trainingPrice: Nullable<number>;
  trainingDaysConfig: Nullable<TrainingDaysConfig>;
  address: Nullable<string>;
  additionalAddress: Nullable<string>;
  reviewedCoverLetter: Nullable<string>;
  reviewedPersonalizedEducationalProject: Nullable<{ title: string; quantifiableObjective: string }[]>;
  status: FUNDING_REQUEST_STATUS;
  specificAccommodation: Nullable<string>;
  reviewedSpecificAccommodation: Nullable<string>;
  secondaryTeachers: string[];
  questions: { question: string; answer: string; order: number }[];
  level: Nullable<TRAINING_LEVEL>;
  travelExpenses: Nullable<number>;
  operaOffCommission: Nullable<number>;
  adminCommentary: Nullable<string>;
  organization: Nullable<ORGANIZATION>;
  monthlyLessonsCount: number;
}

export enum FUNDING_REQUEST_ERROR_REASONS {
  PASSWORD = "password",
  WAITING_PERIOD = "waiting_period",
  OTHER = "other",
  REFUSED_BY_FUNDER = "refused_by_funder",
  NO_ERROR = "no_error",
}

export const errorReasonLabelMap: Record<FUNDING_REQUEST_ERROR_REASONS, string> = {
  [FUNDING_REQUEST_ERROR_REASONS.WAITING_PERIOD]: "Carence",
  [FUNDING_REQUEST_ERROR_REASONS.PASSWORD]: "Mot de passe",
  [FUNDING_REQUEST_ERROR_REASONS.REFUSED_BY_FUNDER]: "Refus",
  [FUNDING_REQUEST_ERROR_REASONS.OTHER]: "Autre",
  [FUNDING_REQUEST_ERROR_REASONS.NO_ERROR]: "Pas de problème",
};
