import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AdminCreateStudentBody, AdminUpdateStudentBody } from "@user-management-context/write/domain/types/admin/student";
import { Student } from "@user-management-context/read/domain/types/admin/student";
import {
  AdminStudentFormInputs,
  schema,
  defaultValues,
  adminFormatFormDataToBodyData,
  formatStudentDataToFormData,
} from "./form-validation/student";
import SelectTeachersModal from "../../shared/select-teachers-modal/select-teachers-modal";
import { LostDataModal } from "@shared-kernel/primary/shared/lost-data-modal/modal";
import { PhoneInput } from "../../../../user-management-context/primary/shared/phone-input/phone-input";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import {
  resetUploadProfilePicture,
  uploadProfilePicture,
} from "../../../../user-management-context/write/application/use-cases/student/profile-picture-upload/upload-profile-picture";
import { ERROR_MESSAGE, IMAGE_TYPE, isValidImageFile } from "@academy-context/write/domain/constants/shared";
import { resetRetrieveStudent } from "../../../../user-management-context/read/application/use-cases/admin/student-retrieval/retrieve-student";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import ImageUploadButton from "@shared-kernel/primary/shared/upload-button/image-upload-button";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Pen, Save, X } from "lucide-react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@shared-kernel/primary/shared/shadcn/ui/select";
import { Checkbox } from "@shared-kernel/primary/shared/shadcn/ui/checkbox";
import { isEmailUsed } from "@user-management-context/read/application/use-cases/admin/user-email-existence/is-email-used";
import { useFormBlocker } from "@shared-kernel/primary/shared/lost-data-modal/use-form-blocker";
import { adminRetrieveAdmins } from "@user-management-context/read/application/use-cases/admin/admin-list-retrieval/retrieve-admin-list";

interface Props {
  onSubmit: (body: AdminCreateStudentBody | AdminUpdateStudentBody) => void;
  student?: Student;
}
export const StudentForm = ({ student, onSubmit }: Props) => {
  const dispatch = useAppDispatch();
  const { data: admins } = useAppSelector((state: AppState) => state.adminsRetrieval);
  const methods = useForm<AdminStudentFormInputs>({ resolver: yupResolver(schema), defaultValues, context: { validation: "admin" } });
  const { doesExist } = useAppSelector((state: AppState) => state.userEmailExistence);
  const { profilePictureUrl, processing } = useAppSelector((state: AppState) => state.profilePictureUpload);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { isModalOpen: isLostDataModalOpen, handleCloseModal, handleProceed } = useFormBlocker(shouldBlockNavigation);

  const {
    reset,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    dispatch(adminRetrieveAdmins());
    return () => {
      dispatch(resetUploadProfilePicture());
      dispatch(resetRetrieveStudent());
    };
  }, [dispatch]);

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student]);

  useEffect(() => {
    setShouldBlockNavigation(isDirty);
  }, [isDirty]);

  useEffect(() => {
    if (profilePictureUrl) {
      setValue("profilePictureUrl", profilePictureUrl, { shouldDirty: true });
    }
  }, [profilePictureUrl, setValue]);

  const email = watch("email");

  // This part is used to check if user account is already registered
  useEffect(() => {
    if (email) dispatch(isEmailUsed(email));
  }, [dispatch, email]);

  useEffect(() => {
    const condition = student ? student.email !== email : email;
    if (doesExist && condition) {
      setError("email", { message: "Cet email est déjà utilisé", type: "custom" });
      setDisableSubmit(true);
    } else {
      clearErrors("email");
      setDisableSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, doesExist]);

  const resetForm = () => {
    if (student) reset(formatStudentDataToFormData(student));
    dispatch(resetUploadProfilePicture());
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOnSubmit = async (formBody: AdminStudentFormInputs) => {
    // Should not block navigation on creation otherwise modal opens up since we redirect user to newly created student
    if (!student) setShouldBlockNavigation(false);
    const body = adminFormatFormDataToBodyData(formBody);
    onSubmit(body);
  };

  const handleProfilePictureChange = (picture: File) => {
    clearErrors("profilePictureUrl");
    dispatch(uploadProfilePicture({ picture }));
  };

  const handleProfilePictureError = () => {
    setError("profilePictureUrl", { type: "manual", message: ERROR_MESSAGE });
  };

  const onValidateFavoriteTeachers = (teachers: string[]) => {
    setValue("favoriteTeachers", teachers, { shouldDirty: true });
  };

  const favoriteTeachers = watch("favoriteTeachers") ? watch("favoriteTeachers") : [];

  const phone = watch("mobile");

  const pictureFromForm = watch("profilePictureUrl");
  const profilePicture = profilePictureUrl || pictureFromForm;

  return (
    <>
      <LostDataModal isOpen={isLostDataModalOpen} onClose={handleCloseModal} onSubmit={handleProceed} />
      <Form {...methods}>
        <form onSubmit={methods.handleSubmit(handleOnSubmit)} className="grid w-full grid-cols-4 gap-4 space-y-8">
          <div className="col-span-2">
            <FormField
              control={methods.control}
              name="profilePictureUrl"
              render={() => (
                <FormItem className=" grid grid-cols-2 gap-4">
                  <div className="">
                    <ImageUploadButton
                      processing={processing}
                      label="Photo de profil"
                      onChange={handleProfilePictureChange}
                      onError={handleProfilePictureError}
                      types={IMAGE_TYPE}
                      value={profilePicture || null}
                      validatorFunc={isValidImageFile}
                    />
                    <FormMessage />
                  </div>
                  <ul className="ml-4 mt-2 list-disc text-sm">
                    <li>140*100px minimum</li>
                    <li>10mo max</li>
                    <li>JPG ou PNG</li>
                  </ul>
                </FormItem>
              )}
            />
          </div>
          <div className="space-y-4">
            <FormField
              control={methods.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Prénom</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={methods.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Nom de famille</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={methods.control}
              name="blackListed"
              render={({ field }) => (
                <FormItem className="flex items-center space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>Blacklister l'élève</FormLabel>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={methods.control}
              name="canRequestARefundOnce"
              render={({ field }) => (
                <FormItem className="flex items-center space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>Demande de remboursement</FormLabel>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={methods.control}
              name="canRequestANonAfdasFundingOnce"
              render={({ field }) => (
                <FormItem className="flex items-center space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>Demande de financement autre que AFDAS</FormLabel>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="space-y-4">
            <FormField
              control={methods.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input {...field} type="email" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={methods.control}
              name="adminId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Administrateur responsable</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value || undefined}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Sélectionner un administrateur" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {admins.map(admin => (
                        <SelectItem key={admin.id} value={admin.id}>
                          {admin.name} {admin.lastName}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={methods.control}
              name="mobile"
              render={() => (
                <FormItem>
                  <FormLabel>Téléphone</FormLabel>
                  <FormControl>
                    <PhoneInput
                      phone={phone}
                      onChange={value => {
                        setValue("mobile", value as string, { shouldDirty: true });
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormItem>
              <FormLabel>Professeurs</FormLabel>
              <FormControl>
                <div>
                  <Button type="button" onClick={() => setIsModalOpen(true)} className="w-[100px]">
                    <Pen size={14} className="mr-2" />
                    Editer
                  </Button>
                </div>
              </FormControl>
            </FormItem>
          </div>
          <div className="col-span-4 mt-4 flex justify-end">
            <Button onClick={resetForm} disabled={!isDirty} variant="outline" type="button">
              <X className="mr-2 size-4" />
              Annuler
            </Button>
            <Button type="submit" disabled={!isDirty || disableSubmit} className="ml-2">
              <Save className="mr-2 size-4" />
              Sauvegarder
            </Button>
          </div>
        </form>
      </Form>
      <SelectTeachersModal
        isOpen={isModalOpen}
        onClose={onCloseModal}
        onSubmit={(teachers: string[]) => {
          onValidateFavoriteTeachers(teachers);
          onCloseModal();
        }}
        favoriteTeachers={favoriteTeachers}
      />
    </>
  );
};
