import { createReducer } from "@reduxjs/toolkit";
import { ProcessingState } from "@redux/app-state";
import { resetRevertFundingRequestToReview, revertFundingRequestToReview } from "./revert-funding-request-to-review";

const initialState: ProcessingState = { processing: "idle" };

export const revertFundingRequestToReviewReducer = createReducer<ProcessingState>(initialState, builder => {
  builder.addCase(revertFundingRequestToReview.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(revertFundingRequestToReview.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(revertFundingRequestToReview.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetRevertFundingRequestToReview, () => {
    return initialState;
  });
});
