import { FormInputs } from "./form-validation/funding-request";
import { FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { useFormContext } from "react-hook-form";
import { AppState } from "@redux/app-state";
import { UploadButton } from "@shared-kernel/primary/shared/upload-button/upload-button";
import { ArrowUpRightFromCircle } from "lucide-react";
import { getFileProps } from "@utils/utils";
import { BIO_AND_RESUME_FILETYPE, isValidFile } from "@academy-context/write/domain/constants/shared";
import { resetUploadResume, uploadResume } from "@academy-context/write/application/use-cases/student/resume-upload/upload-resume";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { resetUploadBio, uploadBio } from "@academy-context/write/application/use-cases/student/bio-upload/upload-bio";
import { useEffect } from "react";

export const BioResumeFormField = () => {
  const dispatch = useAppDispatch();
  const { bioUrl, processing: bioProcessing } = useAppSelector((state: AppState) => state.bioUpload);
  const { resumeUrl, processing: resumeProcessing } = useAppSelector((state: AppState) => state.resumeUpload);

  const { control, clearErrors, setError, setValue, watch } = useFormContext<FormInputs>();

  useEffect(() => {
    if (bioUrl) {
      setValue("bioUrl", bioUrl, { shouldDirty: true });
    }
  }, [bioUrl, setValue]);

  useEffect(() => {
    if (resumeUrl) {
      setValue("resumeUrl", resumeUrl, { shouldDirty: true });
    }
  }, [resumeUrl, setValue]);

  const onResumeChange = (resume: File) => {
    clearErrors("resumeUrl");
    dispatch(uploadResume({ resume }));
  };

  const onResumeError = () => {
    setError("resumeUrl", { type: "manual", message: "Veuillez télécharger un fichier PDF de 10 Mo max" });
  };

  const onResumeDelete = () => {
    clearErrors("resumeUrl");
    setValue("resumeUrl", "", { shouldDirty: true });
    dispatch(resetUploadResume());
  };

  const onBioChange = (bio: File) => {
    clearErrors("bioUrl");
    dispatch(uploadBio({ bio }));
  };

  const onBioError = () => {
    setError("bioUrl", { type: "manual", message: "Veuillez télécharger un fichier PDF de 10 Mo max" });
  };

  const onBioDelete = () => {
    clearErrors("bioUrl");
    setValue("bioUrl", "", { shouldDirty: true });
    dispatch(resetUploadBio());
  };

  const bioFromForm = watch("bioUrl");
  const bio = bioUrl || bioFromForm;
  const resumeFromForm = watch("resumeUrl");
  const resume = resumeUrl || resumeFromForm;

  return (
    <FormItem>
      <FormLabel>Télécharger Bio et/ou CV</FormLabel>
      <FormDescription>Dans l'idéal, fournissez votre bio ET votre CV</FormDescription>
      <FormField
        control={control}
        name="bioUrl"
        render={() => (
          <FormItem>
            <div className="flex items-center">
              <UploadButton
                processing={bioProcessing}
                label="Télécharger ma bio (pdf)"
                onChange={onBioChange}
                onError={onBioError}
                types={BIO_AND_RESUME_FILETYPE}
                value={bio}
                validatorFunc={isValidFile}
                onDelete={onBioDelete}
                getFileProps={getFileProps}
                dataTestId="bio-upload"
              />
              {bio && (
                <a href={bio} target="_blank" rel="noreferrer" className="ml-2 flex flex-col items-center">
                  <span className="text-sm font-normal">Aperçu</span>
                  <ArrowUpRightFromCircle size={18} />
                </a>
              )}
            </div>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="resumeUrl"
        render={() => (
          <FormItem className="mt-2">
            <div className="flex items-center">
              <UploadButton
                processing={resumeProcessing}
                label="Télécharger mon CV (pdf)"
                onChange={onResumeChange}
                onError={onResumeError}
                types={BIO_AND_RESUME_FILETYPE}
                value={resume}
                validatorFunc={isValidFile}
                onDelete={onResumeDelete}
                getFileProps={getFileProps}
              />
              {resume && (
                <a href={resume} target="_blank" rel="noreferrer" className="ml-2 flex flex-col items-center">
                  <span className="text-sm font-normal">Aperçu</span>
                  <ArrowUpRightFromCircle size={18} />
                </a>
              )}
            </div>
            <FormMessage />
          </FormItem>
        )}
      />
    </FormItem>
  );
};
