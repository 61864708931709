import { Link, matchPath, useLocation } from "react-router-dom";
import { SidebarMenu, SidebarMenuButton, SidebarMenuItem, useSidebar } from "@components/ui/sidebar";
import {
  BadgeEuro,
  BookOpen,
  FolderDown,
  HandCoins,
  Hexagon,
  CircleGauge,
  LayoutList,
  TriangleAlert,
  Zap,
  Scale,
  BadgeAlert,
} from "lucide-react";
import { ReactElement, useEffect, useState } from "react";
import { ROLE_BASED_URLS } from "src/routes";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { Student } from "@user-management-context/read/domain/types/student/student";
import { useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import { cn } from "@components/utils/utils";

interface NavListItem {
  title: string;
  url: string;
  icon: ReactElement;
}

const STUDENT_FUNDING_REQUEST_MENU_LABEL = "Nouveau financement";
const TRAINING_FOLLOW_UP_MENU_LABEL = "Suivi QUALIOPI";
const REFUND_REQUEST_MENU_LABEL = "Remboursement de frais";

const studentNavList: NavListItem[] = [
  {
    title: "Tableau de bord",
    url: ROLE_BASED_URLS[ROLES.STUDENT]["dashboard"],
    icon: <CircleGauge />,
  },
  {
    title: STUDENT_FUNDING_REQUEST_MENU_LABEL,
    url: ROLE_BASED_URLS[ROLES.STUDENT]["fundingRequest"].base,
    icon: <FolderDown />,
  },
  {
    title: TRAINING_FOLLOW_UP_MENU_LABEL,
    url: ROLE_BASED_URLS[ROLES.STUDENT]["trainingFollowUp"].list,
    icon: <Hexagon />,
  },
  {
    title: REFUND_REQUEST_MENU_LABEL,
    url: ROLE_BASED_URLS[ROLES.STUDENT]["refundRequest"].base,
    icon: <HandCoins />,
  },
  {
    title: "Cours",
    url: ROLE_BASED_URLS[ROLES.STUDENT]["lesson"].list,
    icon: <BookOpen />,
  },
  {
    title: "Ajustement de solde",
    url: ROLE_BASED_URLS[ROLES.STUDENT]["balanceAdjustment"].list,
    icon: <Scale />,
  },
  {
    title: "Financements",
    url: ROLE_BASED_URLS[ROLES.STUDENT]["funding"].list,
    icon: <BadgeEuro />,
  },
  {
    title: "Signalements",
    url: ROLE_BASED_URLS[ROLES.STUDENT]["issue"].base,
    icon: <BadgeAlert />,
  },
];

const teacherNavList: NavListItem[] = [
  {
    title: "Tableau de bord",
    url: ROLE_BASED_URLS[ROLES.TEACHER]["dashboard"],
    icon: <CircleGauge />,
  },
  {
    title: TRAINING_FOLLOW_UP_MENU_LABEL,
    url: ROLE_BASED_URLS[ROLES.TEACHER]["trainingFollowUp"].list,
    icon: <Hexagon />,
  },
  {
    title: "Cours",
    url: ROLE_BASED_URLS[ROLES.TEACHER]["lesson"].list,
    icon: <BookOpen />,
  },
  {
    title: "Signalements",
    url: ROLE_BASED_URLS[ROLES.TEACHER]["issue"].base,
    icon: <BadgeAlert />,
  },
];

const providerNavList: NavListItem[] = [
  {
    title: "Tableau de bord",
    url: ROLE_BASED_URLS[ROLES.PROVIDER]["dashboard"],
    icon: <CircleGauge />,
  },
  {
    title: TRAINING_FOLLOW_UP_MENU_LABEL,
    url: ROLE_BASED_URLS[ROLES.PROVIDER]["trainingFollowUp"].list,
    icon: <Hexagon />,
  },
  {
    title: "Formations",
    url: ROLE_BASED_URLS[ROLES.PROVIDER]["providerTraining"].list,
    icon: <LayoutList />,
  },
  {
    title: "Signalements",
    url: ROLE_BASED_URLS[ROLES.PROVIDER]["issue"].base,
    icon: <BadgeAlert />,
  },
];

const navListByRole: Record<ROLES, NavListItem[]> = {
  [ROLES.STUDENT]: studentNavList,
  [ROLES.TEACHER]: teacherNavList,
  [ROLES.PROVIDER]: providerNavList,
  // Not defined here since it has its own sidebar
  [ROLES.ADMIN]: [],
};

interface Props {
  role: Nullable<ROLES>;
  student: Nullable<Student>;
  isActionAvailable: boolean;
}

export const StandardUserSideBar = ({ role, student, isActionAvailable }: Props) => {
  const location = useLocation();
  const [navList, setNavList] = useState<NavListItem[]>([]);
  const { data } = useAppSelector((state: AppState) => state.fundingRequestEligibilityRetrieval);
  const { isMobile, toggleSidebar } = useSidebar();

  useEffect(() => {
    let list: NavListItem[] = [];

    if (role) {
      list = navListByRole[role];

      if (role === ROLES.STUDENT && student && !student.canRequestARefund) {
        list = list.filter(r => !r.title.match(REFUND_REQUEST_MENU_LABEL));
      }
    }
    setNavList(list);
  }, [role, student]);

  return (
    <SidebarMenu>
      {navList.map(item => {
        // Check if the current path matches the item URL, including subpaths
        const isActive = Boolean(matchPath(`/${item.url}/*`, location.pathname));
        const isStudentEligibleForAFundingRequest = item.title === STUDENT_FUNDING_REQUEST_MENU_LABEL && data?.isEligibleForAFundingRequest;
        const isTrainingActionAvailable = item.title === TRAINING_FOLLOW_UP_MENU_LABEL && isActionAvailable;
        const displayDangerSign = isStudentEligibleForAFundingRequest || isTrainingActionAvailable;
        let icon = item.icon;
        if (isStudentEligibleForAFundingRequest) icon = <Zap fill="yellow" />;
        if (isTrainingActionAvailable) icon = <TriangleAlert fill="yellow" />;

        return (
          <SidebarMenuItem key={item.title}>
            <SidebarMenuButton
              asChild
              isActive={isActive}
              tooltip={item.title}
              hidden={false}
              className={cn("mx-auto", displayDangerSign && "bg-yellow-100 font-bold animate-yellowBackgroundPulse")}
            >
              <Link
                to={item.url}
                onClick={() => {
                  if (isMobile) toggleSidebar();
                }}
              >
                {icon} <span>{item.title}</span>
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
        );
      })}
    </SidebarMenu>
  );
};
