import { Button } from "@components/ui/button";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@components/ui/form";
import { Input } from "@components/ui/input";
import { Label } from "@components/ui/label";
import { Separator } from "@components/ui/separator";
import { Nullable } from "@shared-kernel/core/types/nullable";
import {
  ProviderTrainingFormInputs,
  formatProviderTrainingDataToFormData,
  providerTrainingdefaultValues,
} from "@academy-context/primary/shared/provider-trainings/form-validation/provider-training";
import { ProviderTraining } from "@academy-context/read/domain/types/shared/provider-training";
import { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { AlertModal } from "@shared-kernel/primary/shared/modal/alert-modal";
import { useModal } from "@hooks/useModal";
import RichTextLexical from "@shared-kernel/primary/shared/text-editor/rich-text/rich-text-lexical";

interface TrainingFormProps {
  training: Nullable<Omit<ProviderTraining, "providerId">>;
  onDelete: (trainingId: string) => void;
  isNew: boolean;
}

export function ProviderTrainingForm({ training, onDelete, isNew }: TrainingFormProps) {
  const form = useFormContext<ProviderTrainingFormInputs>();
  const { closeModal, isModalOpen, openModal } = useModal();
  const {
    control,
    reset,
    formState: { isDirty, defaultValues },
    register,
  } = form;
  const { fields } = useFieldArray({
    control,
    name: "questions",
  });

  const isAnUpdateForm = !isNew;

  // Force re-mount of RichTextLexical when objective changes
  // We do this because this form is handled weirdly so initial value is empty
  // Also it resets the editor internal state (undo, redo button)
  const [objectiveEditorKey, setoOjectiveEditorKey] = useState(0);
  useEffect(() => {
    setoOjectiveEditorKey(prev => prev + 1);
  }, [defaultValues?.objective]);

  // Force re-mount of RichTextLexical when description changes
  // We do this because this form is handled weirdly so initial value is empty
  // Also it resets the editor internal state (undo, redo button)
  const [descriptionEditorKey, setDescriptionEditorKey] = useState(0);
  useEffect(() => {
    setDescriptionEditorKey(prev => prev + 1);
  }, [defaultValues?.description]);

  useEffect(() => {
    if (training) reset(formatProviderTrainingDataToFormData(training));
    else reset(providerTrainingdefaultValues);
  }, [reset, training]);

  const handleOnDelete = () => {
    if (training) onDelete(training.id);
  };

  return (
    <>
      <AlertModal
        isOpen={isModalOpen}
        onClose={() => closeModal()}
        onSubmit={() => {
          closeModal();
          handleOnDelete();
        }}
        title="Supprimer cette formation"
        body={`Êtes-vous sûr de vouloir supprimer la formation : ${training?.title} ?`}
        submitText="Supprimer"
      />
      <div className="flex h-full flex-col">
        <div className="mt-2 grid gap-2">
          <FormField
            control={form.control}
            name="title"
            render={({ field }) => (
              <FormItem>
                <Label htmlFor="name">Titre</Label>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="tag"
            render={({ field }) => (
              <FormItem>
                <Label htmlFor="name">Acronyme</Label>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="objective"
            render={({ field }) => (
              <FormItem>
                <FormLabel htmlFor="objective">Objectif de la formation</FormLabel>
                <FormControl>
                  <RichTextLexical<ProviderTrainingFormInputs>
                    key={objectiveEditorKey}
                    name={field.name}
                    control={control}
                    defaultValue={defaultValues?.objective}
                    placeholder="Saisissez l'objectif de la formation..."
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="description"
            render={() => (
              <FormItem>
                <FormLabel htmlFor="description">Description de la formation</FormLabel>
                <FormControl>
                  <RichTextLexical<ProviderTrainingFormInputs>
                    key={descriptionEditorKey}
                    name="description"
                    control={control}
                    defaultValue={defaultValues?.description}
                    placeholder="Saisissez la description de la formation..."
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="team"
            render={({ field }) => (
              <FormItem>
                <Label htmlFor="name">Equipe pédagogique</Label>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="price"
            render={({ field }) => (
              <FormItem>
                <Label htmlFor="name">Tarif</Label>
                <FormControl>
                  <Input type="number" {...field} value={field.value ?? ""} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <p className="mt-2 text-sm text-muted-foreground">
            Les questions suivantes visent à évaluer si l'élève est bien positionné pour suivre cette formation
          </p>
          {fields.map((question, index) => (
            <FormField
              key={question.id}
              control={control}
              name={`questions.${index}.text`}
              render={() => (
                <FormItem>
                  <Label>Question de positionnement {question.order}</Label>
                  <FormControl>
                    <Input type="text" {...register(`questions.${index}.text`)} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          ))}
        </div>
        <Separator className="mt-4" />
        <div className="mt-4 flex justify-end gap-4">
          <Button onClick={() => openModal()} variant="destructive" disabled={!isAnUpdateForm} type="button">
            Supprimer
          </Button>
          <Button type="submit" disabled={!isDirty}>
            {isAnUpdateForm ? "Sauvegarder" : "Créer"}
          </Button>
        </div>
      </div>
    </>
  );
}
