import { createSelector } from "@reduxjs/toolkit";
import { AdminStudentListItem } from "@user-management-context/read/application/use-cases/admin/students-retrieval/selectors/students-selectors";
import { isLoadingState } from "@utils/utils";
import { AppState } from "src/redux/app-state";

export const selectStudentsForSearchBar = createSelector(
  [(state: AppState) => state.studentsRetrievalForSearchBar],
  studentsRetrievalForSearchBar => {
    const { data, fetching } = studentsRetrievalForSearchBar;

    const students: AdminStudentListItem[] = data.map(s => {
      return {
        studentId: s.details.studentId,
        name: s.name,
        email: s.email,
        lastName: s.lastName,
        nextFundingDate: s.details.nextFundingDate,
        userStatus: s.userStatus,
        isBlacklisted: s.details.isBlacklisted,
        isFundingRequestInProgress: s.details.isFundingRequestInProgress,
        balance: s.details.balance,
        admin: s.details.admin,
      };
    });

    return { students, isLoading: isLoadingState(fetching) };
  }
);
