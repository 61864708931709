import { createReducer } from "@reduxjs/toolkit";
import { RetrievalState } from "@redux/app-state";
import { resetStudentRetrieveTrainingFollowUps, studentRetrieveTrainingFollowUps } from "./retrieve-training-follow-ups";
import { StudentTrainingFollowUpListVM } from "@academy-context/read/domain/types/training-follow-up";

const initialState: RetrievalState<StudentTrainingFollowUpListVM[]> = { fetching: "idle", data: [] };

export const studentRetrieveTrainingFollowUpsReducer = createReducer<RetrievalState<StudentTrainingFollowUpListVM[]>>(
  initialState,
  builder => {
    builder.addCase(studentRetrieveTrainingFollowUps.fulfilled, (state, { payload }) => {
      return {
        ...state,
        data: payload,
        fetching: "success",
      };
    });
    builder.addCase(studentRetrieveTrainingFollowUps.pending, state => {
      return {
        ...state,
        fetching: "pending",
      };
    });
    builder.addCase(studentRetrieveTrainingFollowUps.rejected, state => {
      return {
        ...state,
        fetching: "failed",
      };
    });
    builder.addCase(resetStudentRetrieveTrainingFollowUps, () => {
      return initialState;
    });
  }
);
