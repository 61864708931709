import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@shared-kernel/primary/shared/shadcn/ui/dialog";
import { ProviderBatchItem } from "@academy-context/read/domain/types/shared/provider-batch";
import { Calendar } from "@components/ui/calendar";
import { fr } from "date-fns/locale";
import { format, startOfMonth } from "date-fns";
import { commonAdapters } from "src/common-adapters";
import { useState } from "react";
import { DayBlockLabelMap } from "@academy-context/read/domain/types/shared/training-day";

interface Props {
  batch: ProviderBatchItem;
  isOpen: boolean;
  onClose: () => void;
}

export const BatchCalendarModal = ({ isOpen, onClose, batch }: Props) => {
  const { dateProvider } = commonAdapters;
  const now = dateProvider!.now();
  const [monthDisplayed, setMonthDisplayed] = useState(startOfMonth(batch.interval.start));

  const totalItems = batch.trainingDays.length;

  const col1Size = Math.ceil(totalItems / 3);
  const col2Size = Math.ceil((totalItems - col1Size) / 2);

  const col1 = batch.trainingDays.slice(0, col1Size);
  const col2 = batch.trainingDays.slice(col1Size, col1Size + col2Size);
  const col3 = batch.trainingDays.slice(col1Size + col2Size);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent onPointerDownOutside={onClose} className="space-y-2">
        <DialogHeader>
          <DialogTitle>Dates de sessions</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <div className="flex flex-col items-center">
          <Calendar
            mode="multiple"
            selected={batch.trainingDays.map(day => new Date(day.date))}
            onSelect={() => {}}
            numberOfMonths={4}
            today={now}
            weekStartsOn={1}
            locale={fr}
            className="p-0"
            month={new Date(monthDisplayed)}
            onMonthChange={newMonth => setMonthDisplayed(newMonth)}
            showOutsideDays={false}
            disabled={true}
          />
          <div className="grid grid-cols-3 gap-4">
            {[col1, col2, col3].map((column, columnIndex) => (
              <div key={columnIndex} className="space-y-2">
                {column.map(({ date, block }, index) => {
                  // Calculate the global index from the original array
                  const globalIndex = (columnIndex === 0 ? 0 : columnIndex === 1 ? col1Size : col1Size + col2Size) + index;

                  return (
                    <div key={date + globalIndex} className="rounded border p-2">
                      <div className="text-sm font-bold">
                        {`Jour ${globalIndex + 1} : ${format(new Date(date), "PPP", { locale: fr })}`}
                      </div>
                      <div className="text-sm">{DayBlockLabelMap[block]}</div>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
