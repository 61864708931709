import { useMemo } from "react";
import { formatDateToLocale, formatToCurrency } from "../../../../utils/formatting";
import { EMAIL_CONTACT } from "../../../../config/constant";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { SimpleTable } from "@shared-kernel/primary/shared/simple-table";
import { LessonExtended } from "@academy-context/read/domain/types/teacher/lesson";

const headers = [
  { title: "Enregistré le", classname: "w-[120px]" },
  { title: "Date du cours" },
  { title: "Elève" },
  { title: "Studio" },
  { title: "Durée du cours (heures)" },
  { title: "Tarif horaire (€/heure)" },
  { title: "Règlement professeur" },
  { title: "Commentaire" },
];

interface Props {
  lessons: LessonExtended[];
}
export const LessonListSummary = ({ lessons }: Props) => {
  const entries = useMemo(
    () =>
      lessons.map(l => {
        return [
          { value: formatDateToLocale(l.creationDate) },
          { value: formatDateToLocale(l.date) },
          { value: l.student.fullName },
          { value: l.studio },
          { value: l.hours },
          { value: formatToCurrency(l.hourlyPrice) },
          { value: formatToCurrency(l.paidToTeacher) },
          { value: l.commentary },
        ];
      }),
    [lessons]
  );
  return (
    <CustomCard
      title="10 DERNIERS COURS ENREGISTRES"
      headerChildren={
        <div className="text-red-500">
          Attention, tous les cours enregistrés le sont définitivement. <br />
          Si vous devez annuler une écriture, adressez un mail à{" "}
          <a href={`mailto:${EMAIL_CONTACT}`} target="_blank" rel="noreferrer" className="text-theme">
            {EMAIL_CONTACT}
          </a>
        </div>
      }
    >
      <SimpleTable headers={headers} entries={entries} />
    </CustomCard>
  );
};
