import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { AssignEducationalAdvisorToEvaluationBody } from "@academy-context/shared/application/ports/training-follow-up-port";

export const assignEducationalAdvisorToEvaluations = createAsyncThunk<
  void,
  AssignEducationalAdvisorToEvaluationBody,
  { extra: Partial<Dependencies> }
>(
  "trainingFollowUps/assignEducationalAdvisorToEvaluations",
  async (payload: AssignEducationalAdvisorToEvaluationBody, { extra: { trainingFollowUpGateway } }) => {
    return trainingFollowUpGateway!.assignEducationalAdvisorToEvaluations(payload);
  }
);

export const resetAssignEducationalAdvisorToEvaluations = createAction("training-follow-ups/resetAssignEducationalAdvisorToEvaluations");
