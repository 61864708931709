import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";

export const triggerBatchParticipationCompletionCheck = createAsyncThunk<void, void, { extra: Partial<Dependencies> }>(
  "providerParticipations/triggerBatchParticipationCompletionCheck",
  async (_: void, { extra: { adminProviderRepository } }) => {
    await adminProviderRepository!.triggerBatchParticipationCompletionCheck();
  }
);

export const resetBatchParticipationCompletionCheck = createAction("providerParticipations/resetBatchParticipationCompletionCheck");
