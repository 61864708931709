import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { retrieveTrainingFollowUpEvaluations } from "../../../read/application/use-cases/shared/training-follow-up-evaluations-retrieval/retrieve-training-follow-up-evaluations";
import { TrainingFollowUpEvaluationAssignmentForm } from "@academy-context/primary/student/training-follow-up-evaluations/training-follow-up-evaluation-assignment-form.components";
import { useEvaluationAssignment } from "@academy-context/primary/student/training-follow-up-evaluations/use-evaluation-assignment";
import { retrieveTeachers } from "@user-management-context/read/application/use-cases/student/teachers-retrieval/retrieve-teachers";
import { retrieveProviders } from "@user-management-context/read/application/use-cases/student/providers-retrieval/retrieve-providers";
import { assignEducationalAdvisorToEvaluations } from "@academy-context/write/application/use-cases/student/training-follow-up-evaluation-assignment/assign-educational-advisor-to-evaluations";
import { AssignEducationalAdvisorToEvaluationBody } from "@academy-context/shared/application/ports/training-follow-up-port";
import { retrieveTrainingFollowUpSurvey } from "@academy-context/read/application/use-cases/student/training-follow-up-survey/retrieve-training-follow-up-survey";

export const StudentTrainingFollowUpEvaluationsContainer = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { data: evaluations } = useAppSelector((state: AppState) => state.trainingFollowUpEvaluationsRetrieval);
  const { data: teachers } = useAppSelector((state: AppState) => state.studentTeachersRetrieval);
  const { data: providers } = useAppSelector((state: AppState) => state.studentProvidersRetrieval);

  useEffect(() => {
    dispatch(retrieveTeachers());
    dispatch(retrieveProviders());
    if (id) {
      dispatch(retrieveTrainingFollowUpEvaluations({ trainingId: id }));
      dispatch(retrieveTrainingFollowUpSurvey(id));
    }
  }, [dispatch, id]);

  const { teacherOptions, providerOptions } = useEvaluationAssignment({ trainingFollowUpId: id!, teachers, providers });

  const onSubmit = (body: AssignEducationalAdvisorToEvaluationBody) => {
    dispatch(assignEducationalAdvisorToEvaluations(body));
  };

  if (!id) return <></>;

  return (
    <TrainingFollowUpEvaluationAssignmentForm
      evaluations={evaluations}
      trainingFollowUpId={id}
      teacherOptions={teacherOptions}
      providerOptions={providerOptions}
      onSubmit={onSubmit}
    />
  );
};
