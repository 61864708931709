import { createReducer } from "@reduxjs/toolkit";
import { adminRetrieveTrainingFollowUpEvaluations } from "./retrieve-training-follow-up-evaluations";
import { RetrievalState } from "@redux/app-state";
import { TrainingFollowUpEvaluation } from "@academy-context/read/domain/types/training-follow-up";

const initialState: RetrievalState<TrainingFollowUpEvaluation[]> = {
  fetching: "idle",
  data: [],
};

export const adminRetrieveTrainingFollowUpEvaluationsReducer = createReducer<RetrievalState<TrainingFollowUpEvaluation[]>>(
  initialState,
  builder => {
    builder.addCase(adminRetrieveTrainingFollowUpEvaluations.fulfilled, (state, { payload }) => {
      return {
        ...state,
        data: payload,
        fetching: "success",
      };
    });
    builder.addCase(adminRetrieveTrainingFollowUpEvaluations.pending, state => {
      return {
        ...state,
        fetching: "pending",
      };
    });
    builder.addCase(adminRetrieveTrainingFollowUpEvaluations.rejected, state => {
      return {
        ...state,
        fetching: "failed",
      };
    });
  }
);
