import { EvaluationAcquisitionStatusLabelMap, TrainingFollowUpEvaluation } from "@academy-context/read/domain/types/training-follow-up";
import { Label } from "@components/ui/label";
import parse from "html-react-parser";

interface Props {
  evaluation: TrainingFollowUpEvaluation;
}

export const EducationalAdvisorEvaluationDetailsComponent = ({ evaluation }: Props) => {
  const isAssigned = evaluation.assignedEducationalAdvisor !== null;

  if (!isAssigned) return null;

  if (!evaluation.acquisitionStatus) {
    return (
      <div className="mt-2 flex items-center justify-center text-center text-gray-500">
        <div className="size-4 animate-pulse rounded-full bg-gray-400" />
        <h2 className="ml-2 text-lg font-semibold">Non évalué pour le moment</h2>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <Label>Niveau d'acquisition</Label>
        <div className="text-sm font-normal">{EvaluationAcquisitionStatusLabelMap[evaluation.acquisitionStatus]}</div>
      </div>

      <div className="space-y-2">
        <Label>Commentaire intervenant</Label>
        <div className="min-h-[236px] rounded-md border border-[#ced4da] bg-[#e9ecef] px-2 py-3 text-sm">
          {evaluation.evaluation ? parse(evaluation.evaluation) : "Aucun commentaire"}
        </div>
      </div>
    </div>
  );
};
