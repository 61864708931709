import { SignatureCanvas } from "../signature-canvas/signature-canvas";
import { DangerSign } from "@shared-kernel/primary/shared/danger-sign/danger-sign";
import { BackButton } from "@shared-kernel/primary/shared/back-button/back-button";
import { signAttendanceSheet } from "../../../write/application/use-cases/shared/attendance-sheet-signature/sign-attendance-sheet";
import { useAppDispatch } from "@redux/hooks";
import { useAttendanceSheetSignature } from "./use-attendance-sheet-signature";
import { ROLE_BASED_URLS } from "src/routes";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { TrainingFollowUpAttendanceSheet } from "@academy-context/read/domain/types/training-follow-up";

export interface AttendanceSheetFormProps {
  trainingDate: Date;
  title: string;
  attendanceSheets: TrainingFollowUpAttendanceSheet[];
  trainingFollowUpId: string;
  isAdmin: boolean;
}

export const AttendanceSheetForm = ({ trainingDate, title, attendanceSheets, trainingFollowUpId, isAdmin }: AttendanceSheetFormProps) => {
  const dispatch = useAppDispatch();

  const onSubmit = (id: string) => (signatureUrl: string) => {
    if (trainingFollowUpId) dispatch(signAttendanceSheet({ trainingId: trainingFollowUpId, id, signatureUrl }));
  };

  useAttendanceSheetSignature(trainingFollowUpId, isAdmin);

  const defaultSignatureUrl = attendanceSheets.find(s => s.number === 1)?.studentSignatureUrl ?? null;

  const sortedAttendanceSheet = [...attendanceSheets].sort((a, b) => a.number - b.number);

  return (
    <div className="flex justify-center">
      <div className="w-[90%]">
        <BackButton
          link={`${ROLE_BASED_URLS[ROLES.ADMIN].trainingFollowUp.list}/${trainingFollowUpId}`}
          label="Suivi qualité de votre formation"
        />
        <div className="mt-5">
          <span>
            <strong>{new Date(trainingDate).toLocaleDateString("fr-FR")}</strong>
          </span>
          <span className="ml-2">
            <strong className="text-theme">{title}</strong>
          </span>
          {sortedAttendanceSheet.length === 0 ? (
            <div className="mt-10 flex flex-col items-center justify-center text-center text-gray-500">
              <div className="size-4 animate-pulse rounded-full bg-gray-400" />
              <h2 className="mt-5 text-lg font-semibold">Aucune feuille d'émargement à signer pour le moment</h2>
            </div>
          ) : (
            <div className="mt-3 grid grid-cols-[5%_95%]">
              <div className="flex items-center">
                <DangerSign />
              </div>
              <div>
                <span>
                  <strong>Emargement</strong>
                </span>
                <br />
                <span>
                  Vous devez émarger pour chaque demi-journée de formation.
                  <br />
                  Ces dates correspondent exactement aux dates indiquées dans le devis qui a été mis en ligne et accepté par l'AFDAS et sont
                  indispensables pour pouvoir facturer votre formation à l'AFDAS.
                  <br />
                  En cliquant sur la zone de signature (chaque rectangle blanc), vous pourrez signer en dessinant avec votre
                  souris/trackpad.
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="mt-5 grid grid-cols-1 gap-4 xl:grid-cols-2">
          {sortedAttendanceSheet.map(s => (
            <SignatureCanvas key={s.id} attendanceSheet={s} onSubmit={onSubmit(s.id)} defaultSignatureUrl={defaultSignatureUrl} />
          ))}
        </div>
      </div>
    </div>
  );
};
