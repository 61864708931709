import { $generateHtmlFromNodes } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { $getRoot, EditorState } from "lexical";

export function OnChangePluginImplementation({ onChange }: { onChange: (value: string) => void }) {
  const [editor] = useLexicalComposerContext();

  const handleEditorChange = (editorState: EditorState) => {
    editorState.read(() => {
      const root = $getRoot();
      const htmlString = $generateHtmlFromNodes(editor, null);
      const textContent = root.getTextContent().trim();

      if (textContent.length === 0 || htmlString === '<p class="plain-text-paragraph"><br></p>') {
        onChange("");
      } else {
        onChange(htmlString);
      }
    });
  };

  return (
    <OnChangePlugin
      onChange={editorState => {
        handleEditorChange(editorState);
      }}
    />
  );
}
