import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { PopoverCalendar } from "@shared-kernel/primary/shared/calendar/popover-calendar";
import { CreatableSelect } from "@shared-kernel/primary/shared/creatable-select/creatable-select";
import { titleDefaultOptions } from "@academy-context/primary/admin/student-detail/shared";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { DialogFooter } from "@components/ui/dialog";
import { useFormContext } from "react-hook-form";
import { commonAdapters } from "src/common-adapters";
import PlainTextLexical from "@shared-kernel/primary/shared/text-editor/plain-text/plain-text-lexical";
import { TimelineEventFormInputs } from "@academy-context/primary/admin/student-detail/form-validation/timeline-event";

interface TimelineEventFormProps {
  isDirty: boolean;
  onClose: () => void;
}

export const TimelineEventForm = ({ isDirty, onClose }: TimelineEventFormProps) => {
  const { dateProvider } = commonAdapters;
  const now = dateProvider!.now();
  const {
    control,
    formState: { defaultValues },
  } = useFormContext<TimelineEventFormInputs>();
  const today = new Date().toISOString();

  return (
    <>
      <FormField
        control={control}
        name="title"
        render={() => (
          <FormItem>
            <FormLabel>Titre</FormLabel>
            <CreatableSelect formName={"title"} control={control} options={titleDefaultOptions} />
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="eventDate"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Date de l'évènement</FormLabel>
            <FormControl>
              <div>
                <PopoverCalendar value={field.value ?? today} onChange={value => field.onChange(value)} now={now} />
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="description"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Description</FormLabel>
            <FormControl>
              <PlainTextLexical<TimelineEventFormInputs> name={field.name} control={control} defaultValue={defaultValues?.[field.name]} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <DialogFooter>
        <Button onClick={onClose} variant="outline" type="button">
          Annuler
        </Button>
        <Button type="submit" disabled={!isDirty}>
          Valider
        </Button>
      </DialogFooter>
    </>
  );
};
