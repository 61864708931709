import React from "react";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { useFormContext } from "react-hook-form";
import { FormInputs } from "./form-validation/funding-request";
import { OrganisationOptions } from "@shared-kernel/domain/organisation";
import { Combobox, ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { RadioGroup, RadioGroupItem } from "@components/ui/radio-group";
import { EDUCATIONAL_ADVISOR, EDUCATIONAL_ADVISOR_MAP } from "@academy-context/shared/domain/types/enums/education-advisors";
import { Checkbox } from "@components/ui/checkbox";
import { Input } from "@components/ui/input";
import { TRAINING_LEVEL, TRAINING_LEVEL_LABELS } from "@academy-context/shared/domain/types/enums/training-levels";
import { useAppDispatch } from "@redux/hooks";
import { adminRetrieveProviderBatches } from "@academy-context/read/application/use-cases/admin/provider-batches-retrieval/retrieve-provider-batches";
import { DateInterval } from "@shared-kernel/core/types/date-interval";

interface Props {
  providerBatchesOptions: (ComboboxOptions & { interval: DateInterval })[];
  internalTrainingsOptions: ComboboxOptions[];
  teacherOptions: ComboboxOptions[];
  providerOptions: ComboboxOptions[];
}

export const BasicFormField: React.FC<Props> = ({ providerOptions, teacherOptions, providerBatchesOptions, internalTrainingsOptions }) => {
  const dispatch = useAppDispatch();
  const formMethods = useFormContext<FormInputs>();
  const { watch, setValue } = formMethods;

  const reviewedEducationalAdvisorType = watch("reviewedEducationalAdvisorType");
  const isProvider = reviewedEducationalAdvisorType === EDUCATIONAL_ADVISOR.PROVIDER;
  const isUndefinedTeacher = watch("isUndefinedTeacher");

  const resetFields = () => {
    setValue("reviewedEducationalAdvisorId", "", { shouldDirty: false });
    setValue("reviewedInternalTrainingId", "", { shouldDirty: false });
    setValue("reviewedProviderBatchId", "", { shouldDirty: false });
    setValue("administrativeInternalTrainingId", "", { shouldDirty: false });
    setValue("address", "");
    setValue("additionalAddress", "");
  };

  return (
    <div className="space-y-2">
      <FormField
        control={formMethods.control}
        name="organization"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Société</FormLabel>
            <FormControl>
              <Combobox
                options={OrganisationOptions}
                value={field.value}
                onChange={value => field.onChange(value)}
                placeholder="Selectionner la société ..."
                search={{
                  notFoundText: "Pas de société trouvée.",
                  commandInputPlaceHolder: "Chercher société...",
                }}
                dataTestId="organization-select"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={formMethods.control}
        name="reviewedEducationalAdvisorType"
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>Professeur / Prestataire principal</FormLabel>
              <RadioGroup
                onValueChange={value => {
                  field.onChange(value);
                  const isProvider = value === EDUCATIONAL_ADVISOR.PROVIDER;
                  const amount = isProvider ? 400 : 550;
                  setValue("operaOffCommission", amount, { shouldDirty: false });
                  resetFields();
                }}
                value={field.value}
                className="flex flex-col space-y-1"
                disabled={isUndefinedTeacher}
              >
                {Object.values(EDUCATIONAL_ADVISOR).map((v, index) => (
                  <FormItem className="flex items-center space-x-3 space-y-0" key={index}>
                    <FormControl>
                      <RadioGroupItem value={v} data-testid={`educational-advisor-radio-option-${v}`} />
                    </FormControl>
                    <FormLabel className="font-normal">{EDUCATIONAL_ADVISOR_MAP[v]}</FormLabel>
                  </FormItem>
                ))}
              </RadioGroup>
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        control={formMethods.control}
        name="reviewedEducationalAdvisorId"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              {isProvider ? (
                <Combobox
                  options={providerOptions}
                  value={field.value}
                  onChange={value => {
                    field.onChange(value);
                    dispatch(adminRetrieveProviderBatches(value));
                    setValue("reviewedProviderBatchId", "", { shouldDirty: false });
                  }}
                  placeholder="Selectionner prestataire..."
                  search={{
                    notFoundText: "Pas de prestataire trouvé.",
                    commandInputPlaceHolder: "Chercher prestataire...",
                  }}
                  dataTestId="provider-select"
                />
              ) : (
                <>
                  <Combobox
                    options={teacherOptions}
                    value={field.value}
                    onChange={value => field.onChange(value)}
                    placeholder="Selectionner professeur..."
                    search={{
                      notFoundText: "Pas de professeur trouvé.",
                      commandInputPlaceHolder: "Chercher professeur...",
                    }}
                    disabled={isUndefinedTeacher}
                    dataTestId="teacher-select"
                  />
                  <div className="flex items-center space-x-3 space-y-0">
                    <Checkbox
                      checked={isUndefinedTeacher}
                      onCheckedChange={checked => {
                        setValue("isUndefinedTeacher", Boolean(checked));
                        resetFields();
                      }}
                      id="undefined-teacher"
                    />
                    <label htmlFor="undefined-teacher">
                      <span className="text-sm">Professeur principal non défini</span>
                    </label>
                  </div>
                </>
              )}
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={formMethods.control}
        name="address"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Adresse du lieu des cours</FormLabel>
            <FormControl>
              <Input placeholder="Ex: 111 rue de vaugirard" {...field} disabled={isUndefinedTeacher} data-testid="address-input" />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={formMethods.control}
        name="additionalAddress"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Code postal, Ville</FormLabel>
            <FormControl>
              <Input placeholder="Ex: 75006 Paris" {...field} disabled={isUndefinedTeacher} data-testid="additional-address-input" />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      {isProvider ? (
        <>
          <FormField
            control={formMethods.control}
            name="reviewedProviderBatchId"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Session</FormLabel>
                <FormControl>
                  <Combobox
                    options={providerBatchesOptions}
                    value={field.value}
                    onChange={value => {
                      field.onChange(value);
                    }}
                    placeholder="Selectionner session..."
                    search={{
                      notFoundText: "Pas de session trouvée.",
                      commandInputPlaceHolder: "Chercher session...",
                    }}
                    dataTestId="provider-batch-select"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={formMethods.control}
            name="administrativeInternalTrainingId"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Formation administrative</FormLabel>
                <FormControl>
                  <Combobox
                    options={internalTrainingsOptions}
                    value={field.value}
                    onChange={value => field.onChange(value)}
                    placeholder="Selectionner formation..."
                    search={{
                      notFoundText: "Pas de formation trouvé.",
                      commandInputPlaceHolder: "Chercher formation...",
                    }}
                    dataTestId="internal-training-select"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </>
      ) : (
        <FormField
          control={formMethods.control}
          name="reviewedInternalTrainingId"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Intitulé de la formation</FormLabel>
              <FormControl>
                <Combobox
                  options={internalTrainingsOptions}
                  value={field.value}
                  onChange={value => field.onChange(value)}
                  placeholder="Selectionner formation..."
                  search={{
                    notFoundText: "Pas de formation trouvé.",
                    commandInputPlaceHolder: "Chercher formation...",
                  }}
                  dataTestId="internal-training-select"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      )}
      <FormField
        control={formMethods.control}
        name="level"
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>Niveau</FormLabel>
              <RadioGroup onValueChange={value => field.onChange(value)} value={field.value} className="flex flex-col space-y-1">
                {Object.values(TRAINING_LEVEL).map((v, index) => (
                  <FormItem className="flex items-center space-x-3 space-y-0" key={index}>
                    <FormControl>
                      <RadioGroupItem value={v} data-testid={`level-radio-option-${v}`} />
                    </FormControl>
                    <FormLabel className="font-normal">{TRAINING_LEVEL_LABELS[v]}</FormLabel>
                  </FormItem>
                ))}
              </RadioGroup>
              <FormMessage />
            </FormItem>
          );
        }}
      />
    </div>
  );
};
