import { FundingRequestVM } from "@academy-context/read/domain/types/admin/funding-request";
import { ReviewFundingRequestBody, UpdateFundingRequestBody } from "@shared-kernel/application/ports/shared/funding-request-repository";
import { PersonalizedEducationalProject } from "./personalized-educational-project";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Loader2 } from "lucide-react";
import { ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { AlertModal } from "@shared-kernel/primary/shared/modal/alert-modal";
import { ProviderListItem } from "@user-management-context/read/domain/types/admin/provider";
import { TeacherListElement } from "@user-management-context/read/domain/types/admin/teacher";
import { Checkbox } from "@components/ui/checkbox";
import { CoverLetterField } from "@academy-context/primary/shared/funding-request-form/cover-letter-field";
import { BasicFormField } from "@academy-context/primary/admin/funding-request-detail/basic-form-field";
import { PriceFormField } from "@academy-context/primary/admin/funding-request-detail/price-form-field";
import { TrainingDaysConfigFormField } from "@academy-context/primary/admin/funding-request-detail/training-days-config-form-field";
import { useFundingRequestFormLogic } from "@academy-context/primary/admin/funding-request-detail/use-funding-request-form-logic";
import { DateInterval } from "@shared-kernel/core/types/date-interval";
import PlainTextLexical from "@shared-kernel/primary/shared/text-editor/plain-text/plain-text-lexical";
import { FormInputs } from "./form-validation/funding-request";

interface Props {
  onSubmit: (body: ReviewFundingRequestBody) => void;
  onSave: (body: UpdateFundingRequestBody) => void;
  onDelete: () => void;
  fundingRequest: FundingRequestVM;
  providers: ProviderListItem[];
  teachers: TeacherListElement[];
  providerBatchesOptions: (ComboboxOptions & { interval: DateInterval })[];
  internalTrainingsOptions: ComboboxOptions[];
  teacherOptions: ComboboxOptions[];
  providerOptions: ComboboxOptions[];
}

export const FundingRequestForm = ({
  onSubmit,
  onSave,
  fundingRequest,
  onDelete,
  providers,
  teachers,
  providerBatchesOptions,
  internalTrainingsOptions,
  teacherOptions,
  providerOptions,
}: Props) => {
  const {
    handleFormSubmit,
    handleSave,
    isProcessing,
    isModalOpen,
    setIsModalOpen,
    formMethods,
    needsAccommodation,
    setNeedsAccommodation,
    now,
    defaultValues,
    setValue,
  } = useFundingRequestFormLogic({
    onSubmit,
    onSave,
    fundingRequest,
    providers,
    teachers,
  });

  const {
    formState: { isDirty },
    control,
  } = formMethods;

  return (
    <div className="flex w-full justify-start">
      <AlertModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={() => {
          setIsModalOpen(false);
          onDelete();
        }}
        title="Supprimer la demande de financement"
        body="Êtes-vous sûr de vouloir supprimer cette demande ?"
        submitText="Supprimer"
      />
      <Form {...formMethods}>
        <form className="w-full space-y-4">
          <BasicFormField
            providerBatchesOptions={providerBatchesOptions}
            internalTrainingsOptions={internalTrainingsOptions}
            teacherOptions={teacherOptions}
            providerOptions={providerOptions}
          />
          <CoverLetterField />
          <PersonalizedEducationalProject />
          <PriceFormField />
          <TrainingDaysConfigFormField
            now={now}
            initialStartDate={defaultValues.trainingDaysIntervalStartDate ?? now}
            fundingRequest={fundingRequest}
          />
          <FormField
            control={formMethods.control}
            name="reviewedSpecificAccommodation"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Mesures prises si aménagement spécifique</FormLabel>
                <div className="flex items-center space-x-3 space-y-0">
                  <Checkbox
                    checked={needsAccommodation}
                    onCheckedChange={checked => setNeedsAccommodation(Boolean(checked))}
                    id="reviewedSpecificAccommodation"
                  />
                  <label htmlFor="reviewedSpecificAccommodation">
                    <FormDescription>Besoin d'un aménagement spécifique pour la formation ?</FormDescription>
                  </label>
                </div>
                {needsAccommodation && (
                  <FormControl>
                    <PlainTextLexical<FormInputs>
                      name={field.name}
                      control={control}
                      defaultValue={field.value}
                      placeholder={needsAccommodation ? "Saisir un aménagement spécifique" : undefined}
                      dataTestId="specific-accommodation-editor"
                    />
                  </FormControl>
                )}
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={formMethods.control}
            name="adminCommentary"
            render={({ field }) => (
              <FormItem className="col-span-3">
                <FormLabel>Commentaire ADMIN</FormLabel>
                <FormControl>
                  <PlainTextLexical<FormInputs>
                    name={field.name}
                    control={control}
                    defaultValue={defaultValues?.[field.name]}
                    dataTestId="admin-commentary-editor"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="mt-4 flex justify-center">
            <div className="flex flex-col">
              {isProcessing ? (
                <div className="mr-3 flex items-center gap-3">
                  <Loader2 className="ml-2 animate-spin" size={24} />
                  <div className="">
                    <p className="mb-1 mt-2">Le dossier est en train d'être traité.</p>
                    <p> Veuillez patienter quelques secondes ...</p>
                  </div>
                </div>
              ) : (
                <div className="grid space-y-4">
                  <Button
                    type="button"
                    disabled={!isDirty}
                    onClick={() => {
                      setValue("validationMode", "submit");
                      formMethods.handleSubmit(handleFormSubmit)();
                    }}
                    data-testid="submit-button"
                  >
                    Valider la demande
                  </Button>
                  <Button
                    type="button"
                    disabled={!isDirty}
                    variant="secondary"
                    onClick={() => {
                      setValue("validationMode", "save");
                      formMethods.handleSubmit(handleSave)();
                    }}
                    data-testid="save-button"
                  >
                    Sauvegarder
                  </Button>
                  <Button type="button" onClick={() => setIsModalOpen(true)} variant="destructive" data-testid="delete-button">
                    Supprimer la demande (déconseillé)
                  </Button>
                </div>
              )}
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};
