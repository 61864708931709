import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { AdminFundingListVM } from "../../../../domain/types/admin/funding";

export const retrieveFundings = createAsyncThunk<AdminFundingListVM[], void, { extra: Partial<Dependencies> }>(
  "fundings/retrieveFundings",
  async (_: void, { extra: { fundingRequestRepository } }) => {
    const requests = await fundingRequestRepository!.getAll({
      status: ["approved"],
    });
    const response: AdminFundingListVM[] = [];
    for (const r of requests) {
      if (r.status === "approved") {
        response.push({
          id: r.id,
          creationDate: r.creationDate,
          internshipEndDate: r.training.endDate,
          student: r.student.name,
          funder: r.funder.type,
          allocatedAmount: r.training.price,
          operaOffCommission: r.training.operaOffCommission,
          totalAmount: r.training.price - r.training.operaOffCommission,
          externalFundingRequestId: r.externalFundingRequestId,
          organization: r.organization,
          obtentionDate: r.obtentionDate,
        });
      }
    }
    return response;
  }
);
