import { Skeleton } from "@components/ui/skeleton";
import { cn } from "@components/utils/utils";

interface Props {
  className?: string;
}

export const FormSkeleton = ({ className }: Props) => (
  <Skeleton data-testid="form-skeleton" className={cn("h-[408px] w-full", className)} />
);
