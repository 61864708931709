import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@shared-kernel/primary/shared/shadcn/ui/dialog";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { ProviderBatchItem } from "@academy-context/read/domain/types/shared/provider-batch";
import { Textarea } from "@shared-kernel/primary/shared/shadcn/ui/textarea";
import { useState } from "react";

export interface CancelBatchEnrollmentCommand {
  enrollmentId: string;
  cancellationMessage: string | null;
}

interface Props {
  enrollment: ProviderBatchItem["enrollments"][number];
  isOpen: boolean;
  onClose: () => void;
  onCancelBatchEnrollment: (body: CancelBatchEnrollmentCommand) => void;
}

export const CancelBatchEnrollmentModal = ({ isOpen, onClose, enrollment, onCancelBatchEnrollment }: Props) => {
  const [cancellationMessage, setCancellationMessage] = useState<string>("");

  const handleOnSubmit = () => {
    onCancelBatchEnrollment({
      enrollmentId: enrollment.id,
      cancellationMessage: cancellationMessage.trim() || null,
    });
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:min-w-[425px]" onPointerDownOutside={onClose}>
        <DialogHeader>
          <DialogTitle>Annuler l'inscription</DialogTitle>
          <DialogDescription>
            Vous êtes sur le point d'annuler l'inscription de {enrollment.student.name} {enrollment.student.lastName}.
            <br />
            Cette action est irréversible.
          </DialogDescription>
        </DialogHeader>
        <div className="grid py-4">
          <div className="col-span-3 flex flex-col">
            <label className="mb-2 text-sm font-medium">Message d'annulation (optionnel)</label>
            <Textarea
              placeholder="Raison de l'annulation..."
              value={cancellationMessage}
              onChange={e => setCancellationMessage(e.target.value)}
              className="resize-none"
            />
          </div>
        </div>
        <DialogFooter>
          <Button onClick={onClose} variant="outline" type="button">
            Retour
          </Button>
          <Button onClick={handleOnSubmit} variant="destructive">
            Confirmer l'annulation
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
