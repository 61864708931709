import { createReducer } from "@reduxjs/toolkit";
import { RetrievalState } from "@redux/app-state";
import { retrieveFundings } from "./retrieve-fundings";
import { AdminFundingListVM } from "@academy-context/read/domain/types/admin/funding";

const initialState: RetrievalState<AdminFundingListVM[]> = { fetching: "idle", data: [] };

export const retrieveFundingsReducer = createReducer<RetrievalState<AdminFundingListVM[]>>(initialState, builder => {
  builder.addCase(retrieveFundings.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveFundings.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveFundings.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
