import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { BackButton } from "@shared-kernel/primary/shared/back-button/back-button";
import { adminRetrieveTrainingFollowUp } from "../../../read/application/use-cases/admin/training-follow-up-retrieval/retrieve-training-follow-up";
import { DownloadableElement } from "../../shared/training-follow-up/downloadable-element";
import { LinkTrainingFollowUpElement } from "../../shared/training-follow-up/link-element";
import { TrainingFollowUpDetailsHeader } from "../../shared/training-follow-up/header";
import { AppState } from "@redux/app-state";
import { Separator } from "@shared-kernel/primary/shared/shadcn/ui/separator";
import { ROLE_BASED_URLS } from "src/routes";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { ACTION_URGENCY_LEVEL } from "@academy-context/read/domain/types/training-follow-up";

export const AdminTrainingFollowUpDetail = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { data: training } = useAppSelector((state: AppState) => state.adminTrainingFollowUpRetrieval);

  useEffect(() => {
    if (id) dispatch(adminRetrieveTrainingFollowUp(id));
  }, [dispatch, id]);

  if (!training) return <></>;

  let evaluationStatus: ACTION_URGENCY_LEVEL;

  if (
    training.availableActionUrgency.student.evaluationAssignment === ACTION_URGENCY_LEVEL.DONE &&
    training.availableActionUrgency.educationalAdvisor.evaluationCompletion === ACTION_URGENCY_LEVEL.DONE
  ) {
    evaluationStatus = ACTION_URGENCY_LEVEL.DONE;
  } else if (
    training.availableActionUrgency.student.evaluationAssignment === ACTION_URGENCY_LEVEL.HIGH ||
    training.availableActionUrgency.educationalAdvisor.evaluationCompletion === ACTION_URGENCY_LEVEL.HIGH
  ) {
    evaluationStatus = ACTION_URGENCY_LEVEL.HIGH;
  } else if (
    training.availableActionUrgency.student.evaluationAssignment === ACTION_URGENCY_LEVEL.MEDIUM ||
    training.availableActionUrgency.educationalAdvisor.evaluationCompletion === ACTION_URGENCY_LEVEL.MEDIUM
  ) {
    evaluationStatus = ACTION_URGENCY_LEVEL.MEDIUM;
  } else if (
    training.availableActionUrgency.student.evaluationAssignment === ACTION_URGENCY_LEVEL.LOW ||
    training.availableActionUrgency.educationalAdvisor.evaluationCompletion === ACTION_URGENCY_LEVEL.LOW
  ) {
    evaluationStatus = ACTION_URGENCY_LEVEL.LOW;
  } else {
    evaluationStatus = ACTION_URGENCY_LEVEL.NOT_AVAILABLE;
  }

  return (
    <div className="flex justify-center">
      <div className="w-[90%]">
        <BackButton link={ROLE_BASED_URLS[ROLES.ADMIN].trainingFollowUp.list} label="Suivi de formation" />
        <TrainingFollowUpDetailsHeader title={`${training.title} | ${training.student.name}`} date={training.date} />
        <div className="mt-5">
          <div className="flex items-baseline">
            <h2 className="text-2xl font-semibold text-theme">Emargements</h2>
            <span className="ml-3 leading-8">(cliquer sur la zone pour ouvrir la page et le module d’émargement)</span>
          </div>
          <Separator className="mt-2" />
          <LinkTrainingFollowUpElement
            title="Emargements - Elève"
            status={training.availableActionUrgency.student.attendanceSheet}
            link={`${ROLE_BASED_URLS[ROLES.ADMIN].trainingFollowUp.list}/${id}/attendance-sheets`}
          />
        </div>
        <div className="mt-5">
          <div className="flex items-baseline">
            <h2 className="text-2xl font-semibold text-theme">Projet Pédagogique</h2>
            <span className="ml-3 leading-8">(Apparait lorsque la formation est finie)</span>
          </div>
          <Separator className="mt-2" />
          <LinkTrainingFollowUpElement
            title="Projet Pédagogique"
            status={evaluationStatus}
            link={
              [ACTION_URGENCY_LEVEL.NOT_AVAILABLE].includes(evaluationStatus)
                ? undefined
                : `${ROLE_BASED_URLS[ROLES.ADMIN].trainingFollowUp.list}/${id}/evaluations`
            }
          />
        </div>
        <div className="mt-5">
          <div className="flex items-baseline">
            <h2 className="text-2xl font-semibold text-theme">Documents à télécharger</h2>
          </div>
          <Separator className="mt-2" />
          <DownloadableElement title="Convocation" url={training.student.trainingInvitationUrl} />
          <DownloadableElement title="Attestation d'assiduité" url={training.student.attendanceCertificateUrl} />
          <DownloadableElement title="Certificat de réalisation de l'action de formation" url={training.student.completionCertificateUrl} />
        </div>
      </div>
    </div>
  );
};
