import { createReducer } from "@reduxjs/toolkit";
import { ProcessingState } from "@redux/app-state";
import { cancelBatchEnrollment } from "@academy-context/write/application/use-cases/provider/batch-enrollment-cancellation/cancel-batch-enrollment";

const initialState: ProcessingState = { processing: "idle" };

export const cancelBatchEnrollmentReducer = createReducer<ProcessingState>(initialState, builder => {
  builder.addCase(cancelBatchEnrollment.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(cancelBatchEnrollment.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(cancelBatchEnrollment.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
});
