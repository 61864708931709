import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import { HttpGateway } from "@shared-kernel/secondary/http-gateway";
import config from "../../../../config/backend";
import { AssignEducationalAdvisorToEvaluationBody, TrainingFollowUpPort } from "../../application/ports/training-follow-up-port";
import {
  AdminTrainingFollowUpListVM,
  AdminTrainingFollowUpVM,
  StudentTrainingFollowUpVM,
  StudentTrainingFollowUpListVM,
  EducationalAdvisorTrainingFollowUpVM,
  TrainingFollowUpEvaluation,
  TrainingFollowUpAttendanceSheet,
  EducationalAdvisorTrainingFollowUpListVM,
} from "@academy-context/read/domain/types/training-follow-up";
import { urlToBloB } from "../../../../utils/utils";
import { SignAttendanceSheetPayload } from "../../../write/application/use-cases/shared/attendance-sheet-signature/sign-attendance-sheet";
import { EvaluateTrainingFollowUpObjectivePayload } from "../../../write/application/use-cases/shared/training-follow-up-objective-evaluation/evaluate-training-follow-up-objective";
import { TrainingFollowUpSurveyVM } from "@academy-context/read/domain/types/shared/training-follow-up-survey";

export class BackendTrainingFollowUpGateway extends HttpGateway implements TrainingFollowUpPort {
  private _route: string = "v1/training-follow-ups";
  private _studentRoute: string = "v1/student/training-follow-ups";
  private _teacherRoute: string = "v1/teacher/training-follow-ups";
  private _adminRoute: string = "v1/admin/training-follow-ups";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async getTrainingFollowUpAttendanceSheets(trainingId: string): Promise<TrainingFollowUpAttendanceSheet[]> {
    const { data: sheets } = await this._instance.get(`${this._route}/${trainingId}/attendance-sheets`);
    return sheets;
  }

  async getTrainingFollowUpEvaluations(trainingId: string): Promise<TrainingFollowUpEvaluation[]> {
    const { data: evaluations } = await this._instance.get(`${this._route}/${trainingId}/evaluations`);
    return evaluations;
  }

  async studentGetAll(): Promise<StudentTrainingFollowUpListVM[]> {
    const { data: trainings } = await this._instance.get(`${this._studentRoute}`);
    return trainings;
  }

  async teacherGetAll(): Promise<EducationalAdvisorTrainingFollowUpListVM[]> {
    const { data: trainings } = await this._instance.get(`${this._teacherRoute}`);
    return trainings;
  }

  async adminGetAll(): Promise<AdminTrainingFollowUpListVM[]> {
    const { data: trainings } = await this._instance.get(`${this._adminRoute}`);
    return trainings;
  }

  async studentGetOne(id: string): Promise<StudentTrainingFollowUpVM> {
    const { data: training } = await this._instance.get(`${this._studentRoute}/${id}`);
    return training;
  }

  async teacherGetOne(id: string): Promise<EducationalAdvisorTrainingFollowUpVM> {
    const { data: training } = await this._instance.get(`${this._teacherRoute}/${id}`);
    return training;
  }

  async adminGetOne(id: string): Promise<AdminTrainingFollowUpVM> {
    const { data: training } = await this._instance.get(`${this._adminRoute}/${id}`);
    return training;
  }

  async signAttendanceSheet({ trainingId, id, signatureUrl }: SignAttendanceSheetPayload): Promise<void> {
    const blob = await urlToBloB(signatureUrl);
    const formDataBody = new FormData();
    formDataBody.append("signature", blob);

    const res = await this._instance.post(`${this._route}/${trainingId}/attendance-sheets/${id}:sign`, formDataBody);
    return res.data;
  }

  async evaluateTrainingFollowUpObjective(body: EvaluateTrainingFollowUpObjectivePayload): Promise<void> {
    const { trainingFollowUpId, id } = body;
    await this._instance.post(`${this._route}/${trainingFollowUpId}/evaluations/${id}`, body);
  }

  async toggleTrainingFollowUpDunningProcessStatus({
    trainingId,
    action,
  }: {
    trainingId: string;
    action: "pause" | "resume";
  }): Promise<void> {
    await this._instance.post(`${this._adminRoute}/${trainingId}:toggle-dunning-status`, { action });
  }

  async assignEducationalAdvisorToEvaluations(body: AssignEducationalAdvisorToEvaluationBody): Promise<void> {
    await this._instance.post(`${this._studentRoute}/evaluations:assign`, body);
  }

  async getTrainingFollowUpSurvey(trainingFollowUpId: string): Promise<TrainingFollowUpSurveyVM[]> {
    const { data: survey } = await this._instance.get(`${this._route}/${trainingFollowUpId}/post-training-survey`);
    return survey;
  }
}
