import { object, string, array, boolean, mixed } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { EDUCATIONAL_ADVISOR } from "@academy-context/shared/domain/types/enums/education-advisors";
import { AssignEducationalAdvisorToEvaluationBody } from "@academy-context/shared/application/ports/training-follow-up-port";
import { Answer, SURVEY_QUESTION_TYPE } from "@academy-context/shared/domain/types/enums/survey";

export type ExistingEvaluationAdvisorInput = {
  evaluationId: string;
  educationalAdvisorType: EDUCATIONAL_ADVISOR;
  educationalAdvisorId: string;
  isAbandoned: boolean;
};

export type NewEvaluationInput = {
  title: string;
  quantifiableObjective: string;
  educationalAdvisorType: EDUCATIONAL_ADVISOR;
  educationalAdvisorId: string;
};

export type FormInputs = {
  existingEvaluations: ExistingEvaluationAdvisorInput[];
  newEvaluations: NewEvaluationInput[];
  surveyAnswers: Answer[];
};

export const defaultValues: FormInputs = {
  existingEvaluations: [],
  newEvaluations: [],
  surveyAnswers: [],
};

const evaluationAdvisorSchema = object().shape({
  evaluationId: string().required(REQUIRED_FIELD),
  educationalAdvisorType: string()
    .max(255)
    .when("isAbandoned", {
      is: false,
      then: schema => schema.required(REQUIRED_FIELD).oneOf(Object.values(EDUCATIONAL_ADVISOR)),
    }),
  educationalAdvisorId: string()
    .max(255)
    .when("isAbandoned", {
      is: false,
      then: schema => schema.required(REQUIRED_FIELD),
    }),
  isAbandoned: boolean().required(),
});

const newEvaluationSchema = object().shape({
  title: string().required(REQUIRED_FIELD),
  quantifiableObjective: string().required(REQUIRED_FIELD),
  educationalAdvisorType: string().required(REQUIRED_FIELD).oneOf(Object.values(EDUCATIONAL_ADVISOR)),
  educationalAdvisorId: string().required(REQUIRED_FIELD),
});

const surveyAnswerSchema = object().shape({
  questionId: string().required(REQUIRED_FIELD),
  questionType: string().required().oneOf(Object.values(SURVEY_QUESTION_TYPE)),
  value: mixed().test("valid-type", REQUIRED_FIELD, function (value) {
    const { questionType } = this.parent;
    if (questionType === SURVEY_QUESTION_TYPE.RATING) {
      return typeof value === "number" && value >= 0 && value <= 5;
    }
    return typeof value === "string" && value.trim().length > 0;
  }),
});

export const evaluationAssignmentsSchema = object().shape({
  existingEvaluations: array().of(evaluationAdvisorSchema).min(1, "Au moins une évaluation doit être assignée"),
  newEvaluations: array().of(newEvaluationSchema),
  surveyAnswers: array().of(surveyAnswerSchema),
});

export const formatFormDataToBodyData = (formData: FormInputs, trainingFollowUpId: string): AssignEducationalAdvisorToEvaluationBody => {
  return {
    trainingFollowUpId,
    existingEvaluations: formData.existingEvaluations.map(evaluation => ({
      evaluationId: evaluation.evaluationId,
      isAbandoned: evaluation.isAbandoned,
      educationalAdvisor: evaluation.isAbandoned
        ? null
        : {
            id: evaluation.educationalAdvisorId,
            type: evaluation.educationalAdvisorType,
          },
    })),
    newEvaluations: formData.newEvaluations.map(evaluation => ({
      title: evaluation.title,
      quantifiableObjective: evaluation.quantifiableObjective,
      educationalAdvisor: {
        id: evaluation.educationalAdvisorId,
        type: evaluation.educationalAdvisorType,
      },
    })),
    surveyAnswers: formData.surveyAnswers,
  };
};
