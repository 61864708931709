import { object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { EvaluateTrainingFollowUpObjectivePayload } from "@academy-context/write/application/use-cases/shared/training-follow-up-objective-evaluation/evaluate-training-follow-up-objective";
import { EVALUATION_ACQUISITION_STATUS, TrainingFollowUpEvaluation } from "@academy-context/read/domain/types/training-follow-up";

export type FormInputs = {
  evaluation: string;
  acquisitionStatus: EVALUATION_ACQUISITION_STATUS;
};

export const defaultValues: FormInputs = {
  evaluation: "",
  acquisitionStatus: EVALUATION_ACQUISITION_STATUS.ACQUIRED,
};

export const schema = object().shape({
  evaluation: string(),
  acquisitionStatus: string().oneOf(Object.values(EVALUATION_ACQUISITION_STATUS)).required(REQUIRED_FIELD),
});

export const formatFormDataToBodyData = (
  evaluation: FormInputs
): Omit<EvaluateTrainingFollowUpObjectivePayload, "trainingFollowUpId" | "id"> => {
  return {
    evaluation: evaluation.evaluation || null,
    acquisitionStatus: evaluation.acquisitionStatus,
  };
};

export const formatEvaluationDataToFormData = (evaluation: TrainingFollowUpEvaluation): FormInputs => {
  return {
    evaluation: evaluation.evaluation ?? defaultValues.evaluation,
    acquisitionStatus: evaluation.acquisitionStatus ?? defaultValues.acquisitionStatus,
  };
};
