import { number, object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { ApproveFundingRequestBody } from "@shared-kernel/application/ports/shared/funding-request-repository";

export type FormInputs = {
  fundingRequestId: string;
  obtentionDate: string;
  externalFundingRequestId: string;
  operaOffCommission: number | undefined;
};

export const defaultValues = {
  obtentionDate: "",
  externalFundingRequestId: "",
  fundingRequestId: "",
  operaOffCommission: undefined,
};

export const schema = object().shape({
  obtentionDate: string().max(255).required(REQUIRED_FIELD),
  externalFundingRequestId: string().max(20).required(REQUIRED_FIELD),
  fundingRequestId: string().required(REQUIRED_FIELD).max(255),
  operaOffCommission: number().typeError(REQUIRED_FIELD).required(REQUIRED_FIELD),
});

export const formatFormDataToBodyData = (fundingRequest: FormInputs): ApproveFundingRequestBody => {
  return {
    obtentionDate: new Date(fundingRequest.obtentionDate),
    externalFundingRequestId: fundingRequest.externalFundingRequestId,
    fundingRequestId: fundingRequest.fundingRequestId,
    operaOffCommission: fundingRequest.operaOffCommission ?? 0,
  };
};
