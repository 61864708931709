import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";

export interface SignAttendanceSheetPayload {
  trainingId: string;
  id: string;
  signatureUrl: string;
}

export const signAttendanceSheet = createAsyncThunk<void, SignAttendanceSheetPayload, { extra: Partial<Dependencies> }>(
  "trainingFollowUps/signAttendanceSheet",
  async (payload: SignAttendanceSheetPayload, { extra: { trainingFollowUpGateway } }) => {
    return trainingFollowUpGateway!.signAttendanceSheet(payload);
  }
);
