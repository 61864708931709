export enum SURVEY_QUESTION_TYPE {
  RATING = "RATING",
  TEXT = "TEXT",
}

export interface Question {
  id: string;
  text: string;
  type: SURVEY_QUESTION_TYPE;
}

export interface Answer {
  questionId: string;
  value: string | number;
}
