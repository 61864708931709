import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { EducationalAdvisorTrainingFollowUpListVM } from "../../../../domain/types/training-follow-up";

export const providerRetrieveTrainingFollowUps = createAsyncThunk<
  EducationalAdvisorTrainingFollowUpListVM[],
  void,
  { extra: Partial<Dependencies> }
>("trainingFollowUps/providerRetrieveTrainingFollowUps", async (_: void, { extra: { providerTrainingFollowUpRepository } }) => {
  return providerTrainingFollowUpRepository!.list();
});

export const resetProviderRetrieveTrainingFollowUps = createAction("trainingFollowUps/resetProviderRetrieveTrainingFollowUps");
