import { FormInputs } from "./form-validation/funding-request";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { FieldArrayWithId, useFormContext } from "react-hook-form";
import PlainTextLexical from "@shared-kernel/primary/shared/text-editor/plain-text/plain-text-lexical";

interface Props {
  questionFields: FieldArrayWithId<FormInputs, "questions", "fieldId">[];
}

export const QuestionsFormField = ({ questionFields }: Props) => {
  const { control } = useFormContext<FormInputs>();

  if (questionFields.length < 1) return null;

  return (
    <FormField
      name="questions"
      render={() => {
        return (
          <FormItem>
            <FormLabel>Questionnaire de positionnement</FormLabel>
            <FormControl className="w-[100px]">
              <>
                {questionFields.map((field, index) => (
                  <FormField
                    key={field.fieldId}
                    name={`questions.${index}.answer`}
                    render={() => {
                      return (
                        <FormItem>
                          <FormLabel className="font-normal">{field.question}</FormLabel>
                          <FormControl>
                            <PlainTextLexical<FormInputs>
                              name={`questions.${index}.answer`}
                              control={control}
                              defaultValue=""
                              className="h-[100px]"
                              dataTestId={`question-answer-${index}`}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                ))}
              </>
            </FormControl>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
};
