import React, { useMemo } from "react";
import { formatDateToLocale, formatToCurrency } from "../../../../utils/formatting";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import clsx from "clsx";
import { SimpleTable } from "@shared-kernel/primary/shared/simple-table";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { selectStudentSummaryRetrieval } from "@academy-context/read/application/use-cases/student/summary-retrieval/selectors/student-summary-selectors";
import parse from "html-react-parser";

interface Props {
  entries: ReturnType<typeof selectStudentSummaryRetrieval>["data"]["lastEntries"];
  adminView: boolean;
}

const generateHeaders = (admin: boolean) => {
  const headers = [{ title: "Enregistré le", classname: "w-[120px]" }, { title: "Catégorie" }, { title: "Détail" }, { title: "Total" }];
  if (admin) {
    headers.push({ title: "Commentaire" });
  }

  return headers;
};

export const WritingListSummary = ({ entries, adminView }: Props) => {
  const cardTitle = `HISTORIQUE DE VOS ${adminView ? "" : "10"} DERNIERES ÉCRITURES`;

  const entriesFormatted = useMemo(
    () =>
      entries.map(e => {
        let transactionSign = "";
        if (e.totalAmount !== 0) {
          if (e.isCredited) transactionSign = "+";
          else if (!e.isBalanceAdjustment) transactionSign = "-";
        }

        const res: { value: Nullable<string | number> | JSX.Element | JSX.Element[]; classname?: string }[] = [
          { value: formatDateToLocale(e.date) },
          { value: e.category },
          { value: parse(e.label), classname: "whitespace-pre-wrap" },
          {
            value: `${transactionSign}${formatToCurrency(e.totalAmount)}`,
            classname: clsx(e.isCredited && "font-bold text-success"),
          },
        ];
        if (adminView) {
          res.push({ value: e.commentary ? parse(e.commentary) : null, classname: "w-[350px]" });
        }
        return res;
      }),
    [entries, adminView]
  );

  return (
    <CustomCard
      title={cardTitle}
      headerChildren={
        <div>
          <span>Total d’un cours = tarif professeur + tarif studio</span>
          <br />
        </div>
      }
    >
      <SimpleTable headers={generateHeaders(adminView)} entries={entriesFormatted} />
    </CustomCard>
  );
};
