import { createReducer } from "@reduxjs/toolkit";
import { ProcessingState } from "@redux/app-state";
import { approveFundingRequest, resetApproveFundingRequest } from "./approve-funding-request";

const initialState: ProcessingState = { processing: "idle" };

export const approveFundingRequestReducer = createReducer<ProcessingState>(initialState, builder => {
  builder.addCase(approveFundingRequest.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(approveFundingRequest.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(approveFundingRequest.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetApproveFundingRequest, () => {
    return initialState;
  });
});
