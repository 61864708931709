import { FormInputs } from "./form-validation/funding-request";
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Checkbox } from "@components/ui/checkbox";
import { useFormContext } from "react-hook-form";
import PlainTextLexical from "@shared-kernel/primary/shared/text-editor/plain-text/plain-text-lexical";

interface Props {
  needsAccommodation: boolean;
  setNeedsAccommodation: (value: boolean) => void;
}

export const SpecificAccommodationFormField = ({ needsAccommodation, setNeedsAccommodation }: Props) => {
  const { control } = useFormContext<FormInputs>();

  return (
    <FormField
      name="specificAccommodation"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Aménagements spécifiques</FormLabel>
          <div className="flex items-center space-x-3 space-y-0">
            <Checkbox
              checked={needsAccommodation}
              onCheckedChange={checked => setNeedsAccommodation(Boolean(checked))}
              id="specificAccommodation"
            />
            <label htmlFor="specificAccommodation">
              <FormDescription>
                J'ai besoin d'un aménagement particulier en raison d'un handicap, d'une maladie, ou de toute autre situation pouvant
                compliquer l'accès à la formation. Ex : Adaptation du matériel, des salles ou des horaires en conséquence.
              </FormDescription>
            </label>
          </div>
          <FormControl>
            {needsAccommodation && (
              <PlainTextLexical<FormInputs>
                name={field.name}
                control={control}
                defaultValue={field.value}
                placeholder={needsAccommodation ? "Saisir votre aménagement spécifique" : undefined}
              />
            )}
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
