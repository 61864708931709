import { createReducer } from "@reduxjs/toolkit";
import { resetRetrieveTrainingFollowUpEvaluations, retrieveTrainingFollowUpEvaluations } from "./retrieve-training-follow-up-evaluations";
import { TrainingFollowUpEvaluation } from "@academy-context/read/domain/types/training-follow-up";
import { RetrievalState } from "@redux/app-state";

const initialState: RetrievalState<TrainingFollowUpEvaluation[]> = { fetching: "idle", data: [] };

export const retrieveTrainingFollowUpEvaluationsReducer = createReducer<RetrievalState<TrainingFollowUpEvaluation[]>>(
  initialState,
  builder => {
    builder.addCase(retrieveTrainingFollowUpEvaluations.fulfilled, (state, { payload }) => {
      return {
        ...state,
        data: payload,
        fetching: "success",
      };
    });
    builder.addCase(retrieveTrainingFollowUpEvaluations.pending, state => {
      return {
        ...state,
        fetching: "pending",
      };
    });
    builder.addCase(retrieveTrainingFollowUpEvaluations.rejected, state => {
      return {
        ...state,
        fetching: "failed",
      };
    });
    builder.addCase(resetRetrieveTrainingFollowUpEvaluations, () => {
      return initialState;
    });
  }
);
