import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { CreateLessonBody } from "@academy-context/write/domain/types/teacher/lesson";
import { LessonCreate } from "./lesson-create.component";
import { LessonListSummary } from "./lesson-list-summary.components";
import { retrieveLessons } from "../../../read/application/use-cases/teacher/lessons-retrieval/retrieve-lessons";
import { selectTeacherLessonsRetrievalForList } from "../../../read/application/use-cases/teacher/lessons-retrieval/selectors/lessons-list-selectors";
import { retrieveTeacherStudents } from "../../../../user-management-context/read/application/use-cases/teacher/students-retrieval/retrieve-students";
import { createLesson } from "../../../write/application/use-cases/teacher/lesson-creation/create-lesson";
import { retrieveTeacherProfile } from "../../../../user-management-context/read/application/use-cases/teacher/teacher-profile-retrieval/retrieve-teacher";

export const LessonTeacherDetail = () => {
  const dispatch = useAppDispatch();
  const { data: lessons } = useAppSelector(selectTeacherLessonsRetrievalForList);

  useEffect(() => {
    // This call is needed to fetch default price
    dispatch(retrieveTeacherProfile());
    dispatch(retrieveTeacherStudents());
    dispatch(retrieveLessons());
  }, [dispatch]);

  const saveLesson = async (body: CreateLessonBody) => {
    await dispatch(createLesson(body));
  };

  return (
    <div>
      <div className="mb-5 grid gap-4">
        <LessonCreate onSubmit={saveLesson} />
      </div>
      <LessonListSummary lessons={lessons.slice(0, 10)} />
    </div>
  );
};
