import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { adminRetrieveTrainingFollowUpEvaluations } from "../../../read/application/use-cases/admin/training-follow-up-evaluations-retrieval/retrieve-training-follow-up-evaluations";

export const selectTrainingFollowUpObjectiveEvaluation = (state: AppState) => state.trainingFollowUpObjectiveEvaluation;

interface Props {
  trainingId: string;
}

export const useEvaluationUpdated = ({ trainingId }: Props) => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { processing: evaluationProcessing } = useAppSelector(selectTrainingFollowUpObjectiveEvaluation);
  const { processing: assignmentProcessing } = useAppSelector((state: AppState) => state.trainingFollowUpEvaluationsAssignment);

  useEffect(() => {
    if (evaluationProcessing === "success") {
      toast({ description: "Bilan mis à jour !" });
      dispatch(adminRetrieveTrainingFollowUpEvaluations({ trainingId }));
    } else if (evaluationProcessing === "failed") {
      toast({ description: "Erreur lors de la mise à jour du bilan", variant: "destructive" });
    }
  }, [dispatch, evaluationProcessing, toast, trainingId]);

  useEffect(() => {
    if (assignmentProcessing === "success") {
      toast({ description: "Intervenant(s) assigné(s) avec succès !" });
      dispatch(adminRetrieveTrainingFollowUpEvaluations({ trainingId }));
    } else if (assignmentProcessing === "failed") {
      toast({ description: "Erreur lors de l'assignation des intervenants", variant: "destructive" });
    }
  }, [dispatch, assignmentProcessing, toast, trainingId]);
};
