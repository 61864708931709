import { createReducer } from "@reduxjs/toolkit";
import {
  resetRetrieveTrainingFollowUpSurvey,
  retrieveTrainingFollowUpSurvey,
} from "@academy-context/read/application/use-cases/student/training-follow-up-survey/retrieve-training-follow-up-survey";
import { RetrievalState } from "@redux/app-state";
import { TrainingFollowUpSurveyVM } from "@academy-context/read/domain/types/shared/training-follow-up-survey";

const initialState: RetrievalState<TrainingFollowUpSurveyVM[]> = {
  fetching: "idle",
  data: [],
};

export const retrieveTrainingFollowUpSurveyReducer = createReducer<RetrievalState<TrainingFollowUpSurveyVM[]>>(initialState, builder => {
  builder.addCase(retrieveTrainingFollowUpSurvey.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveTrainingFollowUpSurvey.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveTrainingFollowUpSurvey.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetRetrieveTrainingFollowUpSurvey, () => {
    return initialState;
  });
});
