import { createReducer } from "@reduxjs/toolkit";
import { ProcessingState } from "@redux/app-state";
import {
  resetBatchParticipationCompletionCheck,
  triggerBatchParticipationCompletionCheck,
} from "./trigger-batch-participation-completion-check";

const initialState: ProcessingState = { processing: "idle" };

export const adminBatchParticipationCompletionCheckReducer = createReducer<ProcessingState>(initialState, builder => {
  builder.addCase(triggerBatchParticipationCompletionCheck.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(triggerBatchParticipationCompletionCheck.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(triggerBatchParticipationCompletionCheck.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetBatchParticipationCompletionCheck, () => {
    return initialState;
  });
});
