import { createReducer } from "@reduxjs/toolkit";
import {
  resetSubmitProviderBatchParticipationInvoice,
  submitProviderBatchParticipationInvoice,
} from "./submit-provider-batch-participation-invoice";
import { ProcessingState } from "src/redux/app-state";

const initialState: ProcessingState = { processing: "idle" };

export const submitProviderBatchParticipationInvoiceReducer = createReducer<ProcessingState>(initialState, builder => {
  builder.addCase(submitProviderBatchParticipationInvoice.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(submitProviderBatchParticipationInvoice.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(submitProviderBatchParticipationInvoice.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetSubmitProviderBatchParticipationInvoice, () => {
    return initialState;
  });
});
