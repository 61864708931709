import { createReducer } from "@reduxjs/toolkit";
import { ProcessingState } from "@redux/app-state";
import { contactAdminForEnrollment } from "./contact-admin-for-enrollment";

const initialState: ProcessingState = {
  processing: "idle",
};

export const contactAdminForEnrollmentReducer = createReducer<ProcessingState>(initialState, builder => {
  builder.addCase(contactAdminForEnrollment.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(contactAdminForEnrollment.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(contactAdminForEnrollment.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
});
