import { useFieldArray, useFormContext } from "react-hook-form";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Checkbox } from "@shared-kernel/primary/shared/shadcn/ui/checkbox";
import parse from "html-react-parser";
import {
  FormInputs,
  ExistingEvaluationAdvisorInput,
} from "@academy-context/primary/student/training-follow-up-evaluations/form-validation/evaluation-assignments";
import { RadioGroup, RadioGroupItem } from "@shared-kernel/primary/shared/shadcn/ui/radio-group";
import { EDUCATIONAL_ADVISOR, EDUCATIONAL_ADVISOR_MAP } from "@academy-context/shared/domain/types/enums/education-advisors";
import { Combobox, ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { TrainingFollowUpEvaluation } from "@academy-context/read/domain/types/training-follow-up";
import { Card, CardContent, CardHeader, CardTitle } from "@components/ui/card";
import { Separator } from "@components/ui/separator";
import { EducationalAdvisorEvaluationDetailsComponent } from "@academy-context/primary/student/training-follow-up-evaluations/educational-advisor-evaluation.components";

interface Props {
  evaluation: TrainingFollowUpEvaluation;
  teacherOptions: ComboboxOptions[];
  providerOptions: ComboboxOptions[];
}

// We need both evaluation and form evaluation since form evaluation doesn't have the same properties as the evaluation (title, ...)
export const ExistingEvaluationAssignmentCardComponent = ({ evaluation, teacherOptions, providerOptions }: Props) => {
  const formMethods = useFormContext<FormInputs>();

  const { setValue, watch } = formMethods;

  const { fields } = useFieldArray({
    control: formMethods.control,
    name: "existingEvaluations",
  });

  const isFormDisabled = evaluation.assignedEducationalAdvisor !== null || evaluation.isAbandoned;
  const fieldIndex = fields.findIndex(field => (field as unknown as ExistingEvaluationAdvisorInput).evaluationId === evaluation.id);

  if (fieldIndex === -1) return null;

  const isAbandoned = watch(`existingEvaluations.${fieldIndex}.isAbandoned`);

  return (
    <Card className="w-full">
      <CardHeader className="flex flex-col items-center justify-between ">
        <CardTitle className="text-base">{evaluation.title}</CardTitle>
      </CardHeader>
      <Separator className="bg-gray-400" />
      <CardContent className="flex flex-col items-center pt-3 text-black dark:text-white">
        <div className="grid w-full gap-5">
          <div className="flex flex-col items-center">
            <div className="max-h-[150px] min-h-[150px] w-full overflow-y-auto rounded-md border border-[#ced4da] bg-[#e9ecef] px-2 py-3 text-sm">
              {parse(evaluation.quantifiableObjective)}
            </div>
          </div>
          <div className="space-y-4">
            <FormField
              control={formMethods.control}
              name={`existingEvaluations.${fieldIndex}.educationalAdvisorType`}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Type d'intervenant</FormLabel>
                  <RadioGroup
                    onValueChange={value => {
                      field.onChange(value);
                      setValue(`existingEvaluations.${fieldIndex}.educationalAdvisorId`, "", { shouldDirty: false });
                    }}
                    value={field.value}
                    className="flex flex-col space-y-1"
                    disabled={isFormDisabled || isAbandoned}
                  >
                    {Object.values(EDUCATIONAL_ADVISOR).map(v => (
                      <FormItem className="flex items-center space-x-3 space-y-0" key={v}>
                        <FormControl>
                          <RadioGroupItem value={v} />
                        </FormControl>
                        <FormLabel className="font-normal">{EDUCATIONAL_ADVISOR_MAP[v]}</FormLabel>
                      </FormItem>
                    ))}
                  </RadioGroup>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={formMethods.control}
              name={`existingEvaluations.${fieldIndex}.educationalAdvisorId`}
              render={({ field }) => {
                const advisorType = watch(`existingEvaluations.${fieldIndex}.educationalAdvisorType`);
                const isProvider = advisorType === EDUCATIONAL_ADVISOR.PROVIDER;

                return (
                  <FormItem>
                    <FormLabel>Sélectionner un intervenant</FormLabel>
                    <FormControl>
                      {isProvider ? (
                        <Combobox
                          options={providerOptions}
                          value={field.value}
                          onChange={value => field.onChange(value)}
                          placeholder="Selectionner prestataire..."
                          search={{
                            notFoundText: "Pas de prestataire trouvé.",
                            commandInputPlaceHolder: "Chercher prestataire...",
                          }}
                          disabled={isFormDisabled || isAbandoned}
                        />
                      ) : (
                        <Combobox
                          options={teacherOptions}
                          value={field.value}
                          onChange={value => field.onChange(value)}
                          placeholder="Selectionner professeur..."
                          search={{
                            notFoundText: "Pas de professeur trouvé.",
                            commandInputPlaceHolder: "Chercher professeur...",
                          }}
                          dataTestId="teacher-select"
                          disabled={isFormDisabled || isAbandoned}
                        />
                      )}
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={formMethods.control}
              name={`existingEvaluations.${fieldIndex}.isAbandoned`}
              render={({ field }) => (
                <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={checked => {
                        field.onChange(checked);
                        if (checked) {
                          setValue(`existingEvaluations.${fieldIndex}.educationalAdvisorType`, EDUCATIONAL_ADVISOR.TEACHER, {
                            shouldDirty: true,
                          });
                          setValue(`existingEvaluations.${fieldIndex}.educationalAdvisorId`, "", { shouldDirty: true });
                        } else {
                          // When unchecking, restore default values if fields are empty
                          const currentType = watch(`existingEvaluations.${fieldIndex}.educationalAdvisorType`);
                          const currentId = watch(`existingEvaluations.${fieldIndex}.educationalAdvisorId`);
                          if (!currentType || !currentId) {
                            setValue(`existingEvaluations.${fieldIndex}.educationalAdvisorType`, EDUCATIONAL_ADVISOR.TEACHER, {
                              shouldDirty: true,
                            });
                          }
                        }
                      }}
                      disabled={isFormDisabled}
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>Objectif abandonné au cours de la formation</FormLabel>
                  </div>
                </FormItem>
              )}
            />
          </div>
          <EducationalAdvisorEvaluationDetailsComponent evaluation={evaluation} />
        </div>
      </CardContent>
    </Card>
  );
};
