import React, { useState, useCallback, useEffect, useRef } from "react";
import { SortingState } from "@tanstack/react-table";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ColumnDef,
  ColumnFiltersState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@shared-kernel/primary/shared/shadcn/ui/table";
import { DataTablePagination } from "@shared-kernel/primary/shared/shadcn/ui/data-table-with-pagination";
import { DataTableColumnHeader } from "./data-table-column-header";
import { DataTableFilter, DataTableToolbar } from "@shared-kernel/primary/shared/shadcn/ui/data-table-toolbar";

/**
 * Represents the pagination state of a DataTable
 * pageIndex: 0-based index of the current page
 * pageSize: Number of items per page
 */
export type PaginationState = {
  pageIndex: number;
  pageSize: number;
};

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  sortField?: string;
  searchPlaceHolder?: string;
  order?: "asc" | "desc";
  displayDataViewOptions?: boolean;
  pageSize?: number;
  invisibleColumns?: VisibilityState;
  filters?: DataTableFilter[];
  // Whether to sync pagination with URL query parameters
  syncWithUrl?: boolean;
  // Optional table ID for URL parameter prefixing when multiple tables are on the same page
  tableId?: string;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  searchPlaceHolder,
  sortField,
  order,
  displayDataViewOptions = false,
  pageSize = 10,
  invisibleColumns = {},
  filters,
  syncWithUrl = false,
  tableId = "",
}: DataTableProps<TData, TValue>) {
  const location = useLocation();
  const navigate = useNavigate();
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>(invisibleColumns);

  // Initialize sorting state from props
  const sortingInitialState = sortField ? [{ id: sortField, desc: order === "desc" }] : [];
  const [sorting, setSorting] = useState<SortingState>(sortingInitialState);

  /**
   * Helper function to get URL parameter names based on tableId
   * When multiple tables are on the same page, each table needs unique parameter names
   * @returns Object with parameter names for pagination, filtering, etc.
   */
  const getParamNames = useCallback(() => {
    // Only add prefix if tableId is provided and not empty
    const prefix = tableId ? `${tableId}_` : "";
    return {
      pageParamName: `${prefix}page`,
      pageSizeParamName: `${prefix}pageSize`,
      filterParamName: `${prefix}filter`,
    };
  }, [tableId]);

  /**
   * Extracts state from URL query parameters
   * @returns Object with pagination state and global filter
   */
  const getInitialStateFromUrl = useCallback(() => {
    if (syncWithUrl) {
      try {
        const searchParams = new URLSearchParams(location.search);
        const { pageParamName, pageSizeParamName, filterParamName } = getParamNames();

        const pageParam = searchParams.get(pageParamName);
        const pageSizeParam = searchParams.get(pageSizeParamName);
        const filterParam = searchParams.get(filterParamName);

        // Parse and validate page index (convert from 1-based URL to 0-based internal)
        let pageIndex = 0;
        if (pageParam) {
          const parsed = parseInt(pageParam, 10);
          if (!isNaN(parsed) && parsed > 0) {
            pageIndex = parsed - 1;
          }
        }

        // Parse and validate page size
        let pageSizeValue = pageSize;
        if (pageSizeParam) {
          const parsed = parseInt(pageSizeParam, 10);
          if (!isNaN(parsed) && parsed > 0) {
            pageSizeValue = parsed;
          }
        }

        return {
          paginationState: {
            pageIndex,
            pageSize: pageSizeValue,
          },
          globalFilter: filterParam || "",
        };
      } catch (error) {
        console.error("Error parsing state from URL:", error);
      }
    }

    // Default values if not syncing with URL or if parsing failed
    return {
      paginationState: {
        pageIndex: 0,
        pageSize,
      },
      globalFilter: "",
    };
  }, [syncWithUrl, location.search, getParamNames, pageSize]);

  // Initialize state from URL or defaults
  const initialState = getInitialStateFromUrl();
  const [paginationState, setPaginationState] = useState<PaginationState>(initialState.paginationState);
  const [globalFilter, setGlobalFilter] = useState(initialState.globalFilter);

  // Track previous filter and sorting values to detect actual changes
  const prevFiltersRef = useRef({ columnFilters, globalFilter });
  const prevSortingRef = useRef<SortingState>(sortingInitialState);

  // Update state when URL changes (e.g., browser back/forward navigation)
  useEffect(() => {
    if (syncWithUrl) {
      const newState = getInitialStateFromUrl();
      setPaginationState(newState.paginationState);
      setGlobalFilter(newState.globalFilter);
    }
  }, [syncWithUrl, location.search, getInitialStateFromUrl]);

  /**
   * Helper function to check if filters have actually changed
   * Compares current filters with previous filters
   */
  const haveFiltersChanged = () => {
    // Check if global filter has changed
    if (prevFiltersRef.current.globalFilter !== globalFilter) {
      return true;
    }

    // Check if column filters have changed
    const prevColumnFilters = prevFiltersRef.current.columnFilters;
    if (prevColumnFilters.length !== columnFilters.length) {
      return true;
    }

    // Compare each filter
    for (let i = 0; i < columnFilters.length; i++) {
      const currentFilter = columnFilters[i];
      const prevFilter = prevColumnFilters[i];

      // Skip if either filter is undefined (shouldn't happen, but TypeScript needs this check)
      if (!currentFilter || !prevFilter) {
        continue;
      }

      if (currentFilter.id !== prevFilter.id || currentFilter.value !== prevFilter.value) {
        return true;
      }
    }

    return false;
  };

  /**
   * Reset to first page when filters change
   * This ensures users see the first page of filtered results
   * which is the expected behavior when applying filters
   */
  useEffect(() => {
    // Skip the initial render and only reset when filters actually change
    if (haveFiltersChanged()) {
      // Only reset if we're not on the first page already
      if (paginationState.pageIndex !== 0) {
        const newState = {
          ...paginationState,
          pageIndex: 0, // Reset to first page
        };
        setPaginationState(newState);

        // Update URL if sync is enabled
        if (syncWithUrl) {
          updateUrl(newState, globalFilter);
        }
      } else if (syncWithUrl) {
        // Even if we're already on the first page, we still need to update the URL with the new filter
        updateUrl(paginationState, globalFilter);
      }

      // Update the previous filters ref
      prevFiltersRef.current = { columnFilters, globalFilter };
    }
  }, [columnFilters, globalFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Helper function to check if sorting has changed
   * Compares current sorting with previous sorting
   */
  const hasSortingChanged = (currentSorting: SortingState) => {
    const prevSorting = prevSortingRef.current;

    // Check if length has changed
    if (prevSorting.length !== currentSorting.length) {
      return true;
    }

    // Compare each sorting entry
    for (let i = 0; i < currentSorting.length; i++) {
      // Skip if index is out of bounds
      if (i >= currentSorting.length || i >= prevSorting.length) {
        continue;
      }

      const current = currentSorting[i];
      const prev = prevSorting[i];

      // Skip if either sort entry is undefined
      if (!current || !prev) {
        continue;
      }

      if (current.id !== prev.id || current.desc !== prev.desc) {
        return true;
      }
    }

    return false;
  };

  /**
   * Reset to first page when sorting changes
   * This ensures users see the first page of sorted results
   * which is the expected behavior when changing sort order
   */
  const handleSortingChange = (updaterOrValue: any) => {
    // Handle both function updater and direct value
    const updatedSorting: SortingState = typeof updaterOrValue === "function" ? updaterOrValue(sorting) : updaterOrValue;

    // Check if sorting has actually changed
    if (hasSortingChanged(updatedSorting)) {
      // Only reset if we're not on the first page already
      if (paginationState.pageIndex !== 0) {
        const newState = {
          ...paginationState,
          pageIndex: 0, // Reset to first page
        };
        setPaginationState(newState);

        // Update URL if sync is enabled
        if (syncWithUrl) {
          updateUrl(newState, globalFilter);
        }
      }

      // Update the previous sorting ref
      prevSortingRef.current = updatedSorting;
    }

    // Always update the sorting state
    setSorting(updatedSorting);
  };

  /**
   * Updates the URL with current pagination state and global filter
   * Uses URL parameters to make pagination state and filter bookmarkable and shareable
   * @param newPaginationState The new pagination state to reflect in the URL
   * @param filter The global filter to include in the URL
   */
  const updateUrl = useCallback(
    (newPaginationState: PaginationState, filter: string) => {
      if (syncWithUrl) {
        try {
          const searchParams = new URLSearchParams(location.search);
          const { pageParamName, pageSizeParamName, filterParamName } = getParamNames();

          // Convert from 0-based (internal) to 1-based (URL)
          searchParams.set(pageParamName, String(newPaginationState.pageIndex + 1));

          // Only include pageSize in URL if it differs from default
          if (newPaginationState.pageSize !== pageSize) {
            searchParams.set(pageSizeParamName, String(newPaginationState.pageSize));
          } else {
            searchParams.delete(pageSizeParamName);
          }

          // Include global filter in URL if it's not empty
          if (filter) {
            searchParams.set(filterParamName, filter);
          } else {
            searchParams.delete(filterParamName);
          }

          // Update URL without reloading the page
          navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
        } catch (error) {
          console.error("Error updating URL with state:", error);
        }
      }
    },
    [location.pathname, location.search, navigate, pageSize, syncWithUrl, getParamNames]
  );

  // Custom handler for global filter changes to update URL
  const handleGlobalFilterChange = (value: string) => {
    setGlobalFilter(value);
    // URL update will be handled by the filter change effect
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: handleGlobalFilterChange,
    onSortingChange: handleSortingChange, // Custom handler to reset page on sort change
    autoResetPageIndex: false, // Prevent automatic page reset when data changes
    state: {
      columnFilters,
      columnVisibility,
      globalFilter,
      pagination: paginationState,
      sorting, // Use the managed sorting state
    },
    onPaginationChange: updater => {
      let newPaginationState: PaginationState;

      if (typeof updater === "function") {
        newPaginationState = updater({
          pageIndex: paginationState.pageIndex,
          pageSize: paginationState.pageSize,
        });
      } else {
        newPaginationState = updater;
      }

      setPaginationState(newPaginationState);
      if (syncWithUrl) {
        updateUrl(newPaginationState, globalFilter);
      }
    },
  });

  return (
    <div className="w-full">
      <DataTableToolbar
        table={table}
        displayDataViewOptions={displayDataViewOptions}
        searchPlaceHolder={searchPlaceHolder}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        filters={filters}
      />
      <div className="rounded-md border p-1">
        <Table style={{ tableLayout: "fixed" }}>
          <TableHeader>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  const { meta } = header.column.columnDef;
                  const { width } = meta ?? { width: null };
                  return (
                    <TableHead
                      key={header.id}
                      style={{
                        width: width ? width : "auto",
                        wordWrap: "break-word", // Allows long words to break and wrap to the next line
                        whiteSpace: "normal", // Allows text to wrap onto multiple lines
                      }}
                    >
                      {/* When sorting is enabled, meta must be set with column name */}
                      {header.column.columnDef.enableSorting ? (
                        <DataTableColumnHeader
                          column={header.column}
                          title={header.column.columnDef.meta?.title ?? "Add a meta property to your column"}
                          displayHide={displayDataViewOptions}
                        />
                      ) : (
                        flexRender(header.column.columnDef.header, header.getContext())
                      )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map(row => (
                <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
                  {row.getVisibleCells().map(cell => {
                    const { meta } = cell.column.columnDef;
                    let className;
                    const hasMeta = cell.getContext().cell.column.columnDef.meta;
                    if (hasMeta && hasMeta.getCellContext) {
                      className = hasMeta.getCellContext(cell.getContext()).className;
                    }

                    return (
                      <TableCell
                        key={cell.id}
                        style={{
                          width: meta?.width ? meta.width : "auto",
                          wordWrap: "break-word", // Allows long words to break and wrap to the next line
                          whiteSpace: "normal", // Allows text to wrap onto multiple lines
                        }}
                        className={className}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length}>Pas de résultats.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  );
}
