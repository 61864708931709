import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { EVALUATION_ACQUISITION_STATUS } from "@academy-context/read/domain/types/training-follow-up";
import { Nullable } from "@shared-kernel/core/types/nullable";

export interface EvaluateTrainingFollowUpObjectivePayload {
  trainingFollowUpId: string;
  id: string;
  evaluation: Nullable<string>;
  acquisitionStatus: EVALUATION_ACQUISITION_STATUS;
}

export const evaluateTrainingFollowUpObjective = createAsyncThunk<
  void,
  EvaluateTrainingFollowUpObjectivePayload,
  { extra: Partial<Dependencies> }
>(
  "trainingFollowUps/evaluateTrainingFollowUpObjective",
  async (payload: EvaluateTrainingFollowUpObjectivePayload, { extra: { trainingFollowUpGateway } }) => {
    return trainingFollowUpGateway!.evaluateTrainingFollowUpObjective(payload);
  }
);

export const resetEvaluateTrainingFollowUpObjective = createAction("trainingFollowUps/resetEvaluateTrainingFollowUpObjective");
