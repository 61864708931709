import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import { HttpGateway } from "@shared-kernel/secondary/http-gateway";
import config from "../../../../../../config/backend";
import {
  AdminProviderRepository,
  AdminCreateProviderTrainingBody,
  AdminUpdateProviderTrainingBody,
  ApproveProviderBatchParticipationPaymentBody,
  RefuseProviderBatchParticipationPaymentBody,
} from "@user-management-context/shared/application/ports/admin-provider-repository";
import { ProviderTraining } from "@academy-context/read/domain/types/shared/provider-training";
import { Provider, ProviderListItem } from "@user-management-context/read/domain/types/admin/provider";
import { ProviderStudent } from "@user-management-context/read/domain/types/provider/student";
import { CreateProviderBody, UpdateProviderBody } from "@user-management-context/write/domain/types/admin/provider";
import { ProviderBatchItem } from "@academy-context/read/domain/types/shared/provider-batch";
import {
  ProviderStudentEnrollmentToBatchBody,
  ConfirmBatchEnrollmentBody,
  CreateProviderBatchBody,
} from "@user-management-context/shared/application/ports/provider-repository";
import { ProviderBatchParticipationPayment } from "@academy-context/read/domain/types/admin/provider-batch-participation-payment";

export class AdminBackendProviderRepository extends HttpGateway implements AdminProviderRepository {
  private _route: string = "v1/admin/providers";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async list(): Promise<ProviderListItem[]> {
    const { data: providers } = await this._instance.get(this._route);
    return providers;
  }

  async getById(id: string): Promise<Provider> {
    const { data: provider } = await this._instance.get(`${this._route}/${id}`);
    return provider;
  }

  async create(provider: CreateProviderBody): Promise<string> {
    const { data: providerId } = await this._instance.put(this._route, provider);
    return providerId;
  }

  async update(provider: UpdateProviderBody): Promise<void> {
    await this._instance.post(`${this._route}/${provider.id}`, provider);
  }

  async trainingList(providerId: string): Promise<ProviderTraining[]> {
    const { data: trainings } = await this._instance.get(`${this._route}/${providerId}/trainings`);
    return trainings;
  }

  async createTraining(training: AdminCreateProviderTrainingBody): Promise<string> {
    return this._instance.put(`${this._route}/${training.providerId}/trainings`, training);
  }

  async updateTraining(training: AdminUpdateProviderTrainingBody): Promise<void> {
    await this._instance.post(`${this._route}/trainings/${training.trainingId}`, training);
  }

  async deleteTraining(trainingId: string): Promise<void> {
    await this._instance.delete(`${this._route}/trainings/${trainingId}`);
  }

  async batchList(providerId: string): Promise<ProviderBatchItem[]> {
    const { data: batches } = await this._instance.get(`${this._route}/${providerId}/batches`);
    return batches;
  }

  async createBatch(batch: CreateProviderBatchBody): Promise<void> {
    return this._instance.put(`${this._route}/trainings/${batch.trainingId}/batches`, batch);
  }

  async enrollStudentToBatch(enrollment: ProviderStudentEnrollmentToBatchBody): Promise<void> {
    await this._instance.put(`${this._route}/enrollments`, enrollment);
  }

  async confirmEnrollment(enrollment: ConfirmBatchEnrollmentBody): Promise<void> {
    await this._instance.post(`${this._route}/enrollments/${enrollment.enrollmentId}:confirm`, enrollment);
  }

  async payments(): Promise<ProviderBatchParticipationPayment[]> {
    const { data: payments } = await this._instance.get(`${this._route}/payments`);
    return payments;
  }

  async uploadBatchParticipationInvoice(invoice: File, providerId: string): Promise<string> {
    const formDataBody = new FormData();
    formDataBody.append("invoice", invoice);

    const res = await this._instance.post(`${this._route}/${providerId}/participations/invoices`, formDataBody);
    return res.data;
  }

  async approvePayment(body: ApproveProviderBatchParticipationPaymentBody): Promise<void> {
    await this._instance.post(`${this._route}/payments/${body.paymentId}:approve`, body);
  }

  async refusePayment(body: RefuseProviderBatchParticipationPaymentBody): Promise<void> {
    await this._instance.post(`${this._route}/payments/${body.paymentId}:refuse`, body);
  }

  async contactAdminForEnrollment(enrollmentId: string): Promise<void> {
    await this._instance.post(`${this._route}/enrollments/${enrollmentId}:contact`);
  }

  async inviteStudent(studentId: string): Promise<void> {
    await this._instance.post(`v1/admin/accounts/students/${studentId}:invite`);
  }

  async triggerBatchParticipationCompletionCheck(): Promise<void> {
    await this._instance.post(`cron/providers/participations/v1:batch-participation-completion-check`);
  }

  async getProviderStudents(providerId: string): Promise<ProviderStudent[]> {
    const { data } = await this._instance.get(`${this._route}/${providerId}/students`);
    return data;
  }
}
