import { FormInputs } from "./form-validation/funding-request";
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { RadioGroup, RadioGroupItem } from "@components/ui/radio-group";
import { EDUCATIONAL_ADVISOR, EDUCATIONAL_ADVISOR_MAP } from "@academy-context/shared/domain/types/enums/education-advisors";
import { Combobox, ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { Checkbox } from "@components/ui/checkbox";
import { useFormContext } from "react-hook-form";

interface Props {
  hasEligibleProviderEnrollment: boolean;
  isUndefinedTeacher: boolean;
  setIsUndefinedTeacher: (value: boolean) => void;
  teacherOptions: ComboboxOptions[];
  providerOptions: ComboboxOptions[];
}

export const EducationalAdvisorFormField = ({
  hasEligibleProviderEnrollment,
  isUndefinedTeacher,
  setIsUndefinedTeacher,
  teacherOptions,
  providerOptions,
}: Props) => {
  const { watch } = useFormContext<FormInputs>();

  const educationalAdvisorType = watch("educationalAdvisorType");

  const isProvider = educationalAdvisorType === EDUCATIONAL_ADVISOR.PROVIDER;

  return (
    <>
      <FormField
        name="educationalAdvisorType"
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>Professeur / Prestataire principal</FormLabel>
              <FormDescription>
                Professeur / Prestataire auquel vous allouerez la majeure partie de ce financement (c'est-à-dire environ 30% du financement)
              </FormDescription>
              <RadioGroup
                onValueChange={value => field.onChange(value)}
                value={field.value}
                className="flex flex-col space-y-1"
                disabled={hasEligibleProviderEnrollment || isUndefinedTeacher}
              >
                {Object.values(EDUCATIONAL_ADVISOR).map(v => (
                  <FormItem className="flex items-center space-x-3 space-y-0" key={v}>
                    <FormControl>
                      <RadioGroupItem value={v} />
                    </FormControl>
                    <FormLabel className="font-normal">{EDUCATIONAL_ADVISOR_MAP[v]}</FormLabel>
                  </FormItem>
                ))}
              </RadioGroup>
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        name="educationalAdvisorId"
        render={({ field }) => {
          return (
            <FormItem>
              <FormControl>
                {isProvider ? (
                  <Combobox
                    options={providerOptions}
                    value={field.value}
                    onChange={value => field.onChange(value)}
                    placeholder="Selectionner prestataire..."
                    search={{
                      notFoundText: "Pas de prestataire trouvé.",
                      commandInputPlaceHolder: "Chercher prestataire...",
                    }}
                    disabled={hasEligibleProviderEnrollment}
                  />
                ) : (
                  <>
                    <Combobox
                      options={teacherOptions}
                      value={field.value}
                      onChange={value => field.onChange(value)}
                      placeholder="Selectionner professeur..."
                      search={{
                        notFoundText: "Pas de professeur trouvé.",
                        commandInputPlaceHolder: "Chercher professeur...",
                      }}
                      disabled={isUndefinedTeacher}
                      dataTestId="teacher-select"
                    />
                    <div className="flex items-center space-x-3 space-y-0">
                      <Checkbox
                        checked={isUndefinedTeacher}
                        onCheckedChange={checked => setIsUndefinedTeacher(Boolean(checked))}
                        id="undefined-teacher"
                        disabled={hasEligibleProviderEnrollment}
                      />
                      <label htmlFor="undefined-teacher">
                        <span className="text-sm">Je ne suis pas certain de mon professeur principal</span>
                        <FormDescription>
                          Vous avez 4 semaines pour nous confirmer votre choix. Au dela de cette date, sans réponse de votre part, votre
                          compte sera mis en pause.
                        </FormDescription>
                      </label>
                    </div>
                  </>
                )}
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
    </>
  );
};
