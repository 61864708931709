import { StudentListElement } from "@user-management-context/read/domain/types/admin/student";
import { AppState } from "src/redux/app-state";
import { createSelector } from "@reduxjs/toolkit";
import { ORGANIZATION } from "@shared-kernel/domain/organisation";

export interface RegisteredStudent {
  id: string;
  name: string;
  lastName: string;
  balance: {
    global: number;
    byOrganization: Record<ORGANIZATION, number>;
  };
}

export const selectRegisteredStudents = createSelector([(state: AppState) => state.studentsRetrieval], studentsRetrieval => {
  const { data } = studentsRetrieval;

  const registeredUserStudents = data.filter((s): s is StudentListElement & { details: NonNullable<StudentListElement["details"]> } =>
    Boolean(s.details)
  );

  const result: RegisteredStudent[] = registeredUserStudents.map(s => {
    return {
      id: s.details.studentId,
      name: s.name,
      lastName: s.lastName,
      balance: s.details.balance,
    };
  });

  return result;
});
