import { createReducer } from "@reduxjs/toolkit";
import { adminResetDeleteInternalTraining, adminDeleteInternalTraining } from "./delete-internal-training";
import { ProcessingState } from "@redux/app-state";

const initialState: ProcessingState = { processing: "idle" };

export const adminDeleteInternalTrainingReducer = createReducer<ProcessingState>(initialState, builder => {
  builder.addCase(adminDeleteInternalTraining.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(adminDeleteInternalTraining.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(adminDeleteInternalTraining.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(adminResetDeleteInternalTraining, () => {
    return initialState;
  });
});
