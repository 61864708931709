import { FUNDER } from "@academy-context/shared/domain/types/enums/funders";

export const FUNDER_TUTORIALS: Record<FUNDER, JSX.Element> = {
  [FUNDER.AFDAS]: (
    <div className="rounded-md border-l-4 border-green-500 bg-green-50 p-4 text-green-800">
      <p className="text-black">
        <strong>⚠️ Vos identifiants et mot de passe AFDAS doivent être fonctionnels.</strong>
      </p>
      <p className="text-sm">
        Pour cela, créez votre compte ou vérifier que celui-ci est toujours fonctionnel en allant sur le lien ci-dessous.
      </p>
      <p className="mt-2 text-sm">
        Si votre mot de passe ne fonctionne pas, réinitialisez-le sur le site AFDAS (le mot de passe expire tous les 3 mois).
      </p>
      <p className="mt-2 text-sm text-destructive">
        <strong>ATTENTION</strong> : Une fois vos identifiants AFDAS fonctionnels (mail de connexion + mot de passe) , vous devez
        impérativement revenir sur ce formulaire (site OO Connect) pour poursuivre votre demande de financement.{" "}
        <strong>Ne poursuivez pas sur le site AFDAS.</strong>
      </p>
      <p className="mt-2 text-sm">
        <a
          href="https://afdas.my.site.com/Particulier/login?startURL=%2FParticulier%2Fs%2F%3Ft%3D1734611004409"
          target="_blank"
          className="cursor-pointer text-blue-600 visited:text-purple-600 dark:text-blue-500"
          rel="noreferrer"
        >
          Lien vers votre portail AFDAS
        </a>
      </p>
      <p className="mt-2 text-sm">
        Si vous rencontrez un problème avec cette étape : contactez par message Brigitte au{" "}
        <a href="tel:06 19 48 89 11" className="cursor-pointer text-blue-600 visited:text-purple-600 dark:text-blue-500">
          06 19 48 89 11
        </a>{" "}
        ou Charles au{" "}
        <a href="tel:07 78 82 73 29" className="cursor-pointer text-blue-600 visited:text-purple-600 dark:text-blue-500">
          07 78 82 73 29
        </a>
        .
      </p>
    </div>
  ),
  [FUNDER.FRANCE_TRAVAIL]: (
    <div className="rounded-md border-l-4 border-green-500 bg-green-50 p-4 text-green-800">
      <p className="text-sm">
        OPÉRA Off est éligible au <strong>dispositif AIF</strong> Aide Individuelle à la Formation de France TRAVAIL qui peut prendre le
        relais du financement de votre formation lorsque vous êtes en carence ou que vous n’avez pas de droits Afdas ouverts. En général
        vous obtenez un financement net de cours de 1500 euros.
      </p>
      <br />
      <p className="text-sm">
        Si vous ne savez pas ce qu’est le dispositif AIF, voici la source qui vous explique tout : <br />
        <a
          href="https://www.francetravail.fr/candidat/en-formation/mes-aides-financieres/laide-individuelle-a-la-formatio.html"
          target="_blank"
          className="cursor-pointer text-blue-600 visited:text-purple-600 dark:text-blue-500"
          rel="noreferrer"
        >
          Lien vers le dispositif AIF
        </a>
      </p>
      <br />
      <ol className="mt-2 list-inside list-decimal space-y-2 text-sm">
        <li>
          Appeler la Hotline Afdas{" "}
          <a href="tel:0144785587" className="cursor-pointer text-blue-600 visited:text-purple-600 dark:text-blue-500">
            01 44 78 55 87
          </a>{" "}
          (en matinée). Pour demander l’envoi d’une lettre attestant de votre carence ou de votre insuffisance de cachets. Vous pouvez
          également faire en parallèle cette demande depuis votre espace personnel AFDAS « Demande de contact » Dès que vous avez reçu ce
          document, le mettre en ligne sur votre espace FRANCE TRAVAIL et en informer Opéra Off. Sous 24h vous trouverez le DEVIS d’OPÉRA
          Off directement sur votre espace FRANCE TRAVAIL.
        </li>
        <li>
          Si vous dépendez de Paris/Ile de France, je vous recommande de vous rendre au Pôle France TRAVAIL Artiste 10 rue de Brancion 75015
          (en matinée) pour rencontrer un conseiller, en ayant préalablement accepté ce devis sur votre espace pour que le conseiller puisse
          le voir à l’écran lors de votre entretien (ne l’accepter que la veille de votre visite ou de votre téléphone). Sinon appeler le{" "}
          <a href="tel:3949" className="cursor-pointer text-blue-600 visited:text-purple-600 dark:text-blue-500">
            3949
          </a>{" "}
          (France Travail) qui vous affectera un conseiller en ligne. Mais l’entretien « face à face » est toujours plus favorable.
        </li>
        <li>
          Le Conseiller FT vous posera des questions et peut soit donner son accord (mais il demandera la validation de sa hiérarchie), soit
          refuser tout simplement. L’accord FT prend une semaine à 10 jours.
        </li>
        <li>
          <strong className="text-destructive">
            ATTENTION Bien demander au Conseiller lors du 1errendez vous que votre indemnité soit maintenue pendant les dates de votre
            formation (durée de la formation : 50h qui devra être déclarée à FT lors de votre actualisation)
          </strong>
        </li>
        <li>
          Ne pas rédiger de lettre de motivation avant votre entretien (elle n’est pas obligatoirement demandée) mais après l’entretien avec
          le conseiller de manière à reprendre les termes de votre échange dans la lettre de motivation.
        </li>
      </ol>
    </div>
  ),
  [FUNDER.FIFPL]: <></>,
  [FUNDER.ATLAS]: <></>,
  [FUNDER.AKTO]: <></>,
  [FUNDER.AGEFICE]: <></>,
};
