import { FormInputs, SecondaryTeacher } from "./form-validation/funding-request";
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { PlusCircle, CircleX } from "lucide-react";
import { Combobox, ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { useFieldArray, useFormContext } from "react-hook-form";

interface Props {
  teacherOptions: ComboboxOptions[];
  isUndefinedTeacher: boolean;
}

export const SecondaryTeachersFormField = ({ teacherOptions, isUndefinedTeacher }: Props) => {
  const { control } = useFormContext<FormInputs>();
  const {
    append: appendSecondaryTeachers,
    remove: removeSecondaryTeachers,
    fields: secondaryTeachersFields,
  } = useFieldArray({
    control,
    name: "secondaryTeachers",
    keyName: "fieldId",
  });

  return (
    <FormField
      control={control}
      name="secondaryTeachers"
      render={() => {
        return (
          <FormItem>
            <FormLabel>Professeurs complémentaires</FormLabel>
            <FormDescription>
              Constituez votre équipe pédagogique, si vous avez l'intention de travailler avec des professeurs complémentaires.
            </FormDescription>
            <FormControl className="w-[100px]">
              <>
                {secondaryTeachersFields.map(({ fieldId }, index) => {
                  return (
                    <FormField
                      key={fieldId}
                      control={control}
                      name={`secondaryTeachers.${index}.teacherId`}
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <div className="relative">
                              <Combobox
                                options={teacherOptions}
                                value={field.value}
                                onChange={value => field.onChange(value)}
                                placeholder="Selectionner professeur..."
                                search={{
                                  notFoundText: "Pas de professeur trouvé.",
                                  commandInputPlaceHolder: "Chercher professeur...",
                                }}
                              />
                              <button
                                type="button"
                                className="absolute right-[18px] top-1/2 -translate-y-1/2 cursor-pointer bg-white text-red-500 opacity-100 hover:text-red-700"
                                onClick={() => removeSecondaryTeachers(index)}
                              >
                                <CircleX />
                              </button>
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  );
                })}
              </>
            </FormControl>
            <FormMessage />
            <Button
              onClick={() => appendSecondaryTeachers({ teacherId: "" } as SecondaryTeacher)}
              className="mt-2"
              type="button"
              variant="outline"
              disabled={isUndefinedTeacher}
            >
              <PlusCircle className="mr-3" />
              Ajouter un professeur complémentaire
            </Button>
          </FormItem>
        );
      }}
    />
  );
};
