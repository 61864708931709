import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { TrainingFollowUpSurveyVM } from "@academy-context/read/domain/types/shared/training-follow-up-survey";
import { Dependencies } from "@redux/store";

export const resetRetrieveTrainingFollowUpSurvey = createAction("training-follow-ups/resetRetrieveTrainingFollowUpSurvey");

export const retrieveTrainingFollowUpSurvey = createAsyncThunk<TrainingFollowUpSurveyVM[], string, { extra: Partial<Dependencies> }>(
  "training-follow-up/retrieveTrainingFollowUpSurvey",
  async (trainingFollowUpId: string, { extra: { trainingFollowUpGateway } }) => {
    return await trainingFollowUpGateway!.getTrainingFollowUpSurvey(trainingFollowUpId);
  }
);
