import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { StudentTrainingFollowUpListVM } from "../../../../domain/types/training-follow-up";

export const studentRetrieveTrainingFollowUps = createAsyncThunk<StudentTrainingFollowUpListVM[], void, { extra: Partial<Dependencies> }>(
  "trainingFollowUps/studentRetrieveTrainingFollowUps",
  async (_: void, { extra: { trainingFollowUpGateway } }) => {
    return trainingFollowUpGateway!.studentGetAll();
  }
);

export const resetStudentRetrieveTrainingFollowUps = createAction("trainingFollowUps/resetStudentRetrieveTrainingFollowUps");
