import { useEffect, useMemo, useRef, useState } from "react";
import { cn } from "@shared-kernel/primary/shared/shadcn/utils/utils";
import { ChevronsUpDown, Check } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "@shared-kernel/primary/shared/shadcn/ui/popover";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import {
  Command,
  CommandInput,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
} from "@shared-kernel/primary/shared/shadcn/ui/command";
import clsx from "clsx";
import { getNormalizedString, sortAlphabetically } from "@utils/utils";
import Fuse from "fuse.js";

export interface ComboboxOptions {
  label: string;
  value: string;
  isDisabled?: boolean;
}

const fuseOptions = {
  keys: ["label"],
};

interface Props {
  options: ComboboxOptions[];
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  search: {
    commandInputPlaceHolder: string;
    notFoundText: string;
  };
  disabled?: boolean;
  disableSort?: boolean;
  dataTestId?: string;
}

export const Combobox = ({ value, options, onChange, search, placeholder, disabled = false, disableSort = false, dataTestId }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const orderedOptions = useMemo(
    () => (disableSort ? options : [...options].sort((a, b) => sortAlphabetically(a.label, b.label))),
    [options, disableSort]
  );
  const [filteredOptions, setFilteredOptions] = useState<ComboboxOptions[]>(orderedOptions);
  const [inputValue, setInputValue] = useState<string>("");
  const scrollableRef = useRef<HTMLDivElement>(null);

  const fuse = useMemo(() => new Fuse(orderedOptions, fuseOptions), [orderedOptions]);

  useEffect(() => {
    const normalizedInputValue = getNormalizedString(inputValue);

    if (!normalizedInputValue) {
      setFilteredOptions(orderedOptions);
    } else {
      const results = fuse.search(normalizedInputValue);
      setFilteredOptions(results.map(result => result.item));
    }
  }, [fuse, inputValue, isOpen, orderedOptions]);

  useEffect(() => {
    scrollableRef.current?.scrollTo(0, 0);
  }, [filteredOptions, isOpen]);

  useEffect(() => {
    setInputValue("");
  }, [isOpen]);

  return (
    <Popover open={isOpen} onOpenChange={setOpen} modal>
      <PopoverTrigger asChild disabled={disabled}>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={isOpen}
          className="size-full justify-between"
          data-testid={dataTestId ? `${dataTestId}-trigger` : undefined}
        >
          {value ? (
            orderedOptions.find(option => option.value === value)?.label
          ) : (
            <span className="font-normal opacity-50">{placeholder}</span>
          )}
          <ChevronsUpDown className="ml-2 size-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[400px] p-0">
        <Command shouldFilter={false}>
          <CommandInput placeholder={search.commandInputPlaceHolder} value={inputValue} onValueChange={setInputValue} />
          <CommandList ref={scrollableRef}>
            <CommandEmpty>{search.notFoundText}</CommandEmpty>
            <CommandGroup>
              {filteredOptions.map(option => (
                <CommandItem
                  key={option.value}
                  value={option.value}
                  onSelect={currentValue => {
                    setOpen(false);
                    onChange(currentValue === value ? "" : currentValue);
                    setInputValue("");
                  }}
                  disabled={option.isDisabled}
                  className={clsx(option.isDisabled && "cursor-not-allowed text-gray-400")}
                  data-testid={dataTestId ? `${dataTestId}-option-${option.value}` : undefined}
                >
                  <Check className={cn("mr-2 h-4 w-4", value === option.value ? "opacity-100" : "opacity-0")} />
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
