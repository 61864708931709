import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { FormInputs, schema, defaultValues, formatFormDataToBodyData, formatEvaluationDataToFormData } from "./form-validation/evaluation";
import { useAppSelector } from "@redux/hooks";
import { EvaluateTrainingFollowUpObjectivePayload } from "../../../write/application/use-cases/shared/training-follow-up-objective-evaluation/evaluate-training-follow-up-objective";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Save } from "lucide-react";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { RadioGroup, RadioGroupItem } from "@shared-kernel/primary/shared/shadcn/ui/radio-group";
import { Label } from "@shared-kernel/primary/shared/shadcn/ui/label";
import PlainTextLexical from "@shared-kernel/primary/shared/text-editor/plain-text/plain-text-lexical";
import parse from "html-react-parser";
import {
  EVALUATION_ACQUISITION_STATUS,
  EvaluationAcquisitionStatusLabelMap,
  TrainingFollowUpEvaluation,
} from "@academy-context/read/domain/types/training-follow-up";
import { selectTrainingFollowUpEvaluationCompleted } from "@academy-context/primary/shared/educational-advisor-training-follow-up-evaluation-form/use-evaluation-completed";
import { Card, CardContent, CardHeader, CardTitle } from "@components/ui/card";
import { Separator } from "@components/ui/separator";
import { useEffect } from "react";

interface Props {
  evaluation: TrainingFollowUpEvaluation;
  onSubmit: (body: EvaluateTrainingFollowUpObjectivePayload) => void;
}

export const EvaluationCard = ({ evaluation, onSubmit }: Props) => {
  const form = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues });
  const { processing } = useAppSelector(selectTrainingFollowUpEvaluationCompleted);

  const { id: evaluationId, trainingFollowUpId, title, quantifiableObjective, number, acquisitionStatus } = evaluation;

  const isAlreadyFilled = Boolean(acquisitionStatus);

  const { handleSubmit, reset } = form;

  useEffect(() => {
    reset(formatEvaluationDataToFormData(evaluation));
  }, [evaluation, reset]);

  const handleOnSubmit = async (formBody: FormInputs) => {
    const body = formatFormDataToBodyData(formBody);
    onSubmit({ ...body, id: evaluationId, trainingFollowUpId: trainingFollowUpId });
  };

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Card className="w-full">
          <CardHeader className="flex flex-col items-center justify-between ">
            <CardTitle className="text-lg">{title}</CardTitle>
          </CardHeader>
          <Separator className="bg-gray-400" />
          <CardContent className="flex flex-col items-center pt-3 text-black dark:text-white">
            <div className="grid w-full gap-5">
              <div className="flex flex-col items-center">
                <div className="max-h-[150px] min-h-[150px] w-full overflow-y-auto rounded-md border border-[#ced4da] bg-[#e9ecef] px-2 py-3 text-sm">
                  {parse(quantifiableObjective)}
                </div>
              </div>
              <div className="space-y-4">
                <FormField
                  control={form.control}
                  name="acquisitionStatus"
                  render={({ field }) => (
                    <FormItem>
                      <RadioGroup
                        onValueChange={field.onChange}
                        value={field.value}
                        className="flex flex-col space-y-2"
                        disabled={isAlreadyFilled}
                      >
                        {Object.values(EVALUATION_ACQUISITION_STATUS).map(status => {
                          const key = status + number;
                          return (
                            <div className="flex items-center space-x-2" key={key}>
                              <RadioGroupItem value={status} id={key} />
                              <Label htmlFor={key}>{EvaluationAcquisitionStatusLabelMap[status]}</Label>
                            </div>
                          );
                        })}
                      </RadioGroup>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="evaluation"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel htmlFor={field.name}>Commentaire</FormLabel>
                      <FormControl>
                        <PlainTextLexical<FormInputs> name={field.name} control={form.control} disabled={isAlreadyFilled} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div className="mr-4 mt-3 flex w-full justify-end">
              {!isAlreadyFilled && (
                <Button type="submit" disabled={processing === "pending"}>
                  <Save className="mr-2" />
                  Sauvegarder
                </Button>
              )}
            </div>
          </CardContent>
        </Card>
      </form>
    </Form>
  );
};
