import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { teacherRetrieveTrainingFollowUps } from "@academy-context/read/application/use-cases/teacher/training-follow-ups-retrieval/retrieve-training-follow-ups";
import { retrieveTrainingFollowUpEvaluations } from "@academy-context/read/application/use-cases/shared/training-follow-up-evaluations-retrieval/retrieve-training-follow-up-evaluations";
import { providerRetrieveTrainingFollowUps } from "@academy-context/read/application/use-cases/provider/training-follow-ups-retrieval/retrieve-training-follow-ups";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { resetEvaluateTrainingFollowUpObjective } from "@academy-context/write/application/use-cases/shared/training-follow-up-objective-evaluation/evaluate-training-follow-up-objective";

export const selectTrainingFollowUpEvaluationCompleted = (state: AppState) => state.trainingFollowUpObjectiveEvaluation;

interface Props {
  trainingId: string;
  role: ROLES.TEACHER | ROLES.PROVIDER;
}

export const useEvaluationCompleted = ({ trainingId, role }: Props) => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { processing } = useAppSelector(selectTrainingFollowUpEvaluationCompleted);

  useEffect(() => {
    if (processing === "success") {
      toast({ description: "Bilan mis à jour !" });

      dispatch(retrieveTrainingFollowUpEvaluations({ trainingId }));
      // Used to display correct status on teacher / provider sidebar
      if (role === ROLES.TEACHER) dispatch(teacherRetrieveTrainingFollowUps());
      if (role === ROLES.PROVIDER) dispatch(providerRetrieveTrainingFollowUps());
    } else if (processing === "failed") {
      toast({ description: "Erreur lors de la mise à jour du bilan", variant: "destructive" });
    }
    if (processing === "success" || processing === "failed") dispatch(resetEvaluateTrainingFollowUpObjective());
  }, [processing, dispatch, trainingId, toast, role]);
};
