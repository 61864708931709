import { createReducer } from "@reduxjs/toolkit";
import { RetrievalState } from "@redux/app-state";
import { adminRetrieveTrainingFollowUps } from "./retrieve-training-follow-ups";
import { AdminTrainingFollowUpListVM } from "@academy-context/read/domain/types/training-follow-up";

const initialState: RetrievalState<AdminTrainingFollowUpListVM[]> = { fetching: "idle", data: [] };

export const adminRetrieveTrainingFollowUpsReducer = createReducer<RetrievalState<AdminTrainingFollowUpListVM[]>>(initialState, builder => {
  builder.addCase(adminRetrieveTrainingFollowUps.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(adminRetrieveTrainingFollowUps.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(adminRetrieveTrainingFollowUps.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
