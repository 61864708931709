import { PROVIDER_BATCH_ENROLLMENT_STATUS } from "@academy-context/read/domain/types/enums/provider-batch-enrollment-status";
import { ProviderBatchItem } from "@academy-context/read/domain/types/shared/provider-batch";
import { PROVIDER_BATCH_PARTICIPATION_STATUS } from "@academy-context/read/domain/types/enums/provider-batch-participation-status";
import { commonAdapters } from "src/common-adapters";
import { DateInterval } from "@shared-kernel/core/types/date-interval";

const weightByEnrollmentStatus: Record<PROVIDER_BATCH_ENROLLMENT_STATUS, number> = {
  [PROVIDER_BATCH_ENROLLMENT_STATUS.CONTACT_PENDING]: 1,
  [PROVIDER_BATCH_ENROLLMENT_STATUS.INVITATION_SENT_BY_ADMIN]: 2,
  [PROVIDER_BATCH_ENROLLMENT_STATUS.INVITATION_REFUSED_BY_ADMIN]: 3,
  [PROVIDER_BATCH_ENROLLMENT_STATUS.ACCOUNT_REGISTERED]: 4,
  [PROVIDER_BATCH_ENROLLMENT_STATUS.FUNDING_REQUEST_SUBMITTED_BY_STUDENT]: 5,
  [PROVIDER_BATCH_ENROLLMENT_STATUS.FUNDING_REQUEST_SUBMITTED_TO_FUNDER]: 6,
  [PROVIDER_BATCH_ENROLLMENT_STATUS.FUNDING_REQUEST_ACCEPTED_BY_FUNDER]: 7,
  [PROVIDER_BATCH_ENROLLMENT_STATUS.ENROLLMENT_CONFIRMED]: 8,
};

const weightByParticipationStatus: Record<PROVIDER_BATCH_PARTICIPATION_STATUS, number> = {
  [PROVIDER_BATCH_PARTICIPATION_STATUS.BATCH_PARTICIPATION_CONFIRMED]: 1,
  [PROVIDER_BATCH_PARTICIPATION_STATUS.BATCH_PARTICIPATION_FINISHED]: 2,
  [PROVIDER_BATCH_PARTICIPATION_STATUS.INVOICE_RECEIVED]: 3,
  [PROVIDER_BATCH_PARTICIPATION_STATUS.INVOICE_PAID]: 4,
  [PROVIDER_BATCH_PARTICIPATION_STATUS.INVOICE_REFUSED]: 5,
};

export enum BATCH_STATUS {
  UPCOMING = "upcoming",
  FINISHED = "finished",
  ONGOING = "ongoing",
  ARCHIVED = "archived",
}

export const formatBatchesForList = (batches: ProviderBatchItem[]) => {
  const { dateProvider } = commonAdapters;
  const now = dateProvider!.now();

  const orderedData = [...batches]
    .map(batch => {
      const enrollments = extractAndSortEnrollments(batch.enrollments);
      const participations = extractAndSortParticipations(batch.participations);
      const cancellations = extractAndSortCancellations(batch.enrollments, batch.participations);
      const status = getBatchStatus(now, batch.interval, enrollments, participations);

      return {
        ...batch,
        status,
        enrollments,
        participations,
        cancellations,
      };
    })
    .sort((a, b) => new Date(a.interval.start).getTime() - new Date(b.interval.start).getTime());

  return orderedData;
};

const extractAndSortEnrollments = (enrollments: ProviderBatchItem["enrollments"]) =>
  [...enrollments]
    .filter(
      enrollment =>
        // ENROLLMENT_CONFIRMED filter: This is due to the fact that there is a participation created when this status is reached
        ![PROVIDER_BATCH_ENROLLMENT_STATUS.INVITATION_REFUSED_BY_ADMIN, PROVIDER_BATCH_ENROLLMENT_STATUS.ENROLLMENT_CONFIRMED].includes(
          enrollment.status
        ) && !enrollment.cancellationDate
    )
    .sort((a, b) => weightByEnrollmentStatus[a.status] - weightByEnrollmentStatus[b.status]);

const extractAndSortParticipations = (participations: ProviderBatchItem["participations"]) =>
  [...participations]
    .filter(participation => !participation.cancellationDate)
    .sort((a, b) => weightByParticipationStatus[a.status] - weightByParticipationStatus[b.status]);

const extractAndSortCancellations = (
  enrollments: ProviderBatchItem["enrollments"],
  participations: ProviderBatchItem["participations"]
) => {
  const cancelledEnrollments = [...enrollments]
    .filter(
      enrollment =>
        [PROVIDER_BATCH_ENROLLMENT_STATUS.INVITATION_REFUSED_BY_ADMIN].includes(enrollment.status) || enrollment.cancellationDate
    )
    .map(enrollment => ({
      name: enrollment.student.name,
      lastName: enrollment.student.lastName,
      status: enrollment.status,
      cancellationDate: enrollment.cancellationDate,
      cancellationMessage: enrollment.cancellationMessage,
    }))
    .sort((a, b) => weightByEnrollmentStatus[a.status] - weightByEnrollmentStatus[b.status]);

  const cancelledParticipations = [...participations]
    .filter(participation => participation.cancellationDate)
    .map(participation => ({
      name: participation.student.name,
      lastName: participation.student.lastName,
      status: participation.status,
      cancellationDate: participation.cancellationDate,
      cancellationMessage: participation.cancellationMessage,
    }))
    .sort((a, b) => weightByParticipationStatus[a.status] - weightByParticipationStatus[b.status]);

  return [...cancelledEnrollments, ...cancelledParticipations];
};

const getBatchStatus = (
  now: Date,
  interval: DateInterval,
  enrollments: ProviderBatchItem["enrollments"],
  participations: ProviderBatchItem["participations"]
): BATCH_STATUS => {
  const startDate = new Date(interval.start);
  const endDate = new Date(interval.end);

  if (now < startDate) {
    return BATCH_STATUS.UPCOMING;
  } else if (now > endDate) {
    const hasRunningEnrollments = Boolean(enrollments.length);
    const isEveryInvoiceProcessed = participations.every(participation =>
      [PROVIDER_BATCH_PARTICIPATION_STATUS.INVOICE_PAID, PROVIDER_BATCH_PARTICIPATION_STATUS.INVOICE_REFUSED].includes(participation.status)
    );
    if (isEveryInvoiceProcessed && !hasRunningEnrollments) {
      return BATCH_STATUS.ARCHIVED;
    } else {
      return BATCH_STATUS.FINISHED;
    }
  } else {
    return BATCH_STATUS.ONGOING;
  }
};
