import { createReducer } from "@reduxjs/toolkit";
import { ProviderStudent } from "@user-management-context/read/domain/types/provider/student";
import { adminRetrieveProviderStudents, resetAdminRetrieveProviderStudents } from "./retrieve-provider-students";
import { RetrievalState } from "@redux/app-state";

const initialState: RetrievalState<ProviderStudent[]> = {
  fetching: "idle",
  data: [],
};

export const adminProviderStudentsListReducer = createReducer(initialState, builder => {
  builder
    .addCase(adminRetrieveProviderStudents.pending, state => {
      state.fetching = "pending";
    })
    .addCase(adminRetrieveProviderStudents.fulfilled, (state, action) => {
      state.fetching = "success";
      state.data = action.payload;
    })
    .addCase(adminRetrieveProviderStudents.rejected, state => {
      state.fetching = "failed";
      state.data = [];
    })
    .addCase(resetAdminRetrieveProviderStudents, () => initialState);
});
