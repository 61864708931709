import { BATCH_STATUS } from "@academy-context/read/application/use-cases/shared/selectors/format-batches-for-list";
import { ProviderBatchItem } from "@academy-context/read/domain/types/shared/provider-batch";
import { Badge } from "@components/ui/badge";
import { Card, CardHeader } from "@components/ui/card";
import { Link } from "react-router-dom";
import { formatDateToLocale } from "@utils/formatting";
import { ReactElement } from "react";

const batchStatusElement: Record<BATCH_STATUS, ReactElement> = {
  [BATCH_STATUS.UPCOMING]: <Badge variant="secondary">À venir</Badge>,
  [BATCH_STATUS.FINISHED]: <Badge variant="destructive">Terminé</Badge>,
  [BATCH_STATUS.ARCHIVED]: <Badge variant="default">Terminé (Archivé)</Badge>,
  [BATCH_STATUS.ONGOING]: <Badge className="bg-green-500">En cours</Badge>,
};

interface Props {
  batch: ProviderBatchItem & {
    status: BATCH_STATUS;
  };
  linkTo: string;
}

export const CompactProviderBatch = ({ batch, linkTo }: Props) => {
  return (
    <Link to={linkTo}>
      <Card className="mb-2 hover:bg-gray-50">
        <CardHeader className="grid grid-cols-4 p-2">
          <div className="col-span-1 flex items-center">{batchStatusElement[batch.status]}</div>
          <div className="col-span-2 flex flex-col items-center">
            <div>
              <span className="ml-3 font-light">du</span>
              <span className="ml-3 font-bold">{formatDateToLocale(batch.interval.start)}</span>
              <span className="ml-3 font-light">au</span>
              <span className="ml-3 font-bold">{formatDateToLocale(batch.interval.end)}</span>
            </div>
            <div className="text-sm">{batch.title}</div>
          </div>
          <div className="col-span-1 flex items-center justify-end">
            <span className="font-bold">{batch.price}€</span>
          </div>
        </CardHeader>
      </Card>
    </Link>
  );
};
