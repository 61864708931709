import { SyntheticEvent, useEffect, useState } from "react";

import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useFundingRequestUpdated } from "./use-funding-request-updated";
import { ColumnDef } from "@tanstack/react-table";
import {
  errorReasonLabelMap,
  FUNDING_REQUEST_ERROR_REASONS,
  ReviewedFundingRequestVM,
  SubmittedToFunderFundingRequestVM,
} from "@academy-context/read/domain/types/admin/funding-request";
import { formatDateToLocale } from "../../../../utils/formatting";
import { retrieveReviewedFundingRequests } from "../../../read/application/use-cases/admin/funding-request-list/reviewed-funding-request-list-retrieval/retrieve-reviewed-funding-requests";
import { retrieveSubmittedToFunderFundingRequests } from "../../../read/application/use-cases/admin/funding-request-list/submitted-to-funder/retrieve-submitted-to-funder-funding-requests";
import { Card, CardContent } from "@shared-kernel/primary/shared/shadcn/ui/card";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { Checkbox } from "@shared-kernel/primary/shared/shadcn/ui/checkbox";
import { Combobox } from "@shared-kernel/primary/shared/combobox/combobox";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { deleteFundingRequest } from "@academy-context/write/application/use-cases/admin/funding-request-deletion/delete-funding-request";
import { updateFundingRequestError } from "@academy-context/write/application/use-cases/admin/funding-request-error-update/update-funding-request-error";
import { revertFundingRequestToReview } from "@academy-context/write/application/use-cases/admin/funding-request-reversion/revert-funding-request-to-review";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { AlertModal } from "@shared-kernel/primary/shared/modal/alert-modal";
import { SendAfdasRefreshNotificationModal } from "@academy-context/primary/admin/funding-request-list/send-afdas-refresh-notification-modal";
import { submitFundingRequestToFunder } from "@academy-context/write/application/use-cases/admin/funding-request-submission-to-funder/submit-funding-request-to-funder";
import { ORGANIZATION_NAME } from "@shared-kernel/domain/organisation";
import { AFDAS_STUDENT_STATUS_LABELS, FUNDER, FUNDER_LABELS } from "@academy-context/shared/domain/types/enums/funders";

type FundingRequestVM = SubmittedToFunderFundingRequestVM | ReviewedFundingRequestVM;

type ModalState = {
  type: "delete" | "revert" | "password" | null;
  fundingRequest: Nullable<FundingRequestVM>;
};

const errorReasonOptions = Object.values(FUNDING_REQUEST_ERROR_REASONS).map(e => ({ label: errorReasonLabelMap[e], value: e }));

const StatusCell = ({ fundingRequest, isSubmittedToAFDAS }: { fundingRequest: FundingRequestVM; isSubmittedToAFDAS: boolean }) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await dispatch(submitFundingRequestToFunder({ id: fundingRequest.id, hasBeenSubmitted: !isSubmittedToAFDAS })).unwrap();
    } catch (error) {
      console.error("Failed to submit funding request:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return <Checkbox id={fundingRequest.id} defaultChecked={isSubmittedToAFDAS} onClick={handleSubmit} disabled={isSubmitting} />;
};

const ErrorCell = ({
  row,
  onDelete,
  onRevert,
  onPasswordError,
}: {
  row: { original: FundingRequestVM };
  onDelete: () => void;
  onRevert: () => void;
  onPasswordError: (request: FundingRequestVM) => void;
}) => {
  const dispatch = useAppDispatch();
  const [isUpdating, setIsUpdating] = useState(false);

  const handleErrorUpdate = async (value: string) => {
    if (value === FUNDING_REQUEST_ERROR_REASONS.PASSWORD) {
      onPasswordError(row.original);
      return;
    }

    setIsUpdating(true);
    try {
      await dispatch(
        updateFundingRequestError({
          id: row.original.id,
          error: value as FUNDING_REQUEST_ERROR_REASONS,
          sendNotificationToStudent: false,
        })
      ).unwrap();
    } catch (error) {
      console.error("Failed to update funding request error:", error);
    } finally {
      setIsUpdating(false);
    }
  };

  const isFundingRequestDeletable =
    row.original.error &&
    [
      FUNDING_REQUEST_ERROR_REASONS.REFUSED_BY_FUNDER,
      FUNDING_REQUEST_ERROR_REASONS.WAITING_PERIOD,
      FUNDING_REQUEST_ERROR_REASONS.OTHER,
    ].includes(row.original.error);

  return (
    <div className="grid grid-cols-2 gap-2">
      <div className="flex items-center">
        <Combobox
          options={errorReasonOptions}
          value={row.original.error ?? ""}
          onChange={handleErrorUpdate}
          disabled={isUpdating}
          placeholder="Selectionner"
          search={{
            notFoundText: "Pas d'erreur trouvée.",
            commandInputPlaceHolder: "Chercher erreur...",
          }}
        />
      </div>
      <div className="flex flex-col gap-2">
        {isFundingRequestDeletable && (
          <>
            <Button variant="destructive" onClick={onDelete}>
              Supprimer
            </Button>
            <Button onClick={onRevert}>Retour validation</Button>
          </>
        )}
      </div>
    </div>
  );
};

const getFundingRequestColumns = ({
  onDelete,
  onRevert,
  onPasswordError,
}: {
  onDelete: (request: FundingRequestVM) => void;
  onRevert: (request: FundingRequestVM) => void;
  onPasswordError: (request: FundingRequestVM) => void;
}): ColumnDef<FundingRequestVM>[] => [
  {
    accessorKey: "reviewDate",
    header: () => "Date de finalisation (par Brigitte)",
    cell: info => formatDateToLocale(info.row.original.reviewDate),
    meta: {
      title: "Date de finalisation (par Brigitte)",
      width: "90px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "training.startDate",
    header: () => "Date début stage",
    cell: info => formatDateToLocale(info.row.original.training.startDate),
    meta: {
      title: "Date début stage",
      width: "90px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "student.name",
    header: () => "Elève",
    meta: {
      title: "Elève",
    },
    enableSorting: true,
  },
  {
    accessorKey: "funder.type",
    header: () => "Financeur",
    cell: info => {
      const label =
        FUNDER_LABELS[info.row.original.funder.type] +
        (info.row.original.funder.type === FUNDER.AFDAS
          ? ` (${AFDAS_STUDENT_STATUS_LABELS[info.row.original.funder.payload.studentStatus]})`
          : "");
      return label;
    },
    meta: {
      title: "Financeur",
    },
    enableSorting: true,
  },
  {
    accessorKey: "organization",
    header: () => "Société",
    cell: info => {
      const value = info.row.original.organization;
      return ORGANIZATION_NAME[value].long;
    },
    meta: {
      title: "Société",
    },
    enableSorting: true,
  },
  {
    accessorKey: "status",
    header: () => "Mise en ligne",
    meta: {
      title: "Mise en ligne",
      width: "90px",
    },
    enableSorting: true,
    cell: info => <StatusCell fundingRequest={info.row.original} isSubmittedToAFDAS={info.row.original.status === "submitted_to_funder"} />,
  },
  {
    accessorKey: "submissionDate",
    header: () => "Date de mise en ligne",
    cell: info => {
      const value = info.row.original.submissionDate;
      return value ? formatDateToLocale(value) : "";
    },
    meta: {
      title: "Date de mise en ligne",
      width: "90px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "error",
    header: () => "Problème",
    meta: {
      title: "Problème",
      width: "280px",
    },
    cell: ({ row }) => (
      <ErrorCell
        row={row}
        onDelete={() => onDelete(row.original)}
        onRevert={() => onRevert(row.original)}
        onPasswordError={onPasswordError}
      />
    ),
  },
];

export const FundingRequestToSubmitList = () => {
  const dispatch = useAppDispatch();
  const { data: reviewed, fetching: reviewedFetching } = useAppSelector((state: AppState) => state.reviewedFundingRequestsRetrieval);
  const { data: submitted, fetching: submittedFetching } = useAppSelector(
    (state: AppState) => state.submittedToFunderFundingRequestsRetrieval
  );
  const [modalState, setModalState] = useState<ModalState>({ type: null, fundingRequest: null });

  useEffect(() => {
    dispatch(retrieveReviewedFundingRequests());
    dispatch(retrieveSubmittedToFunderFundingRequests());
  }, [dispatch]);

  const handleDelete = (request: FundingRequestVM) => {
    setModalState({ type: "delete", fundingRequest: request });
  };

  const handleRevert = (request: FundingRequestVM) => {
    setModalState({ type: "revert", fundingRequest: request });
  };

  const handlePasswordError = (request: FundingRequestVM) => {
    setModalState({ type: "password", fundingRequest: request });
  };

  const handleCloseModal = () => {
    setModalState({ type: null, fundingRequest: null });
  };

  const handleModalAction = async () => {
    if (!modalState.fundingRequest) return;

    switch (modalState.type) {
      case "delete":
        await dispatch(deleteFundingRequest(modalState.fundingRequest.id));
        break;
      case "revert":
        await dispatch(revertFundingRequestToReview(modalState.fundingRequest.id));
        break;
    }
    handleCloseModal();
  };

  const columns = getFundingRequestColumns({
    onDelete: handleDelete,
    onRevert: handleRevert,
    onPasswordError: handlePasswordError,
  });

  useFundingRequestUpdated();

  return (
    <>
      <AlertModal
        isOpen={modalState.type === "delete"}
        onClose={handleCloseModal}
        onSubmit={handleModalAction}
        title="Supprimer la demande de financement"
        body="Êtes-vous sûr de vouloir supprimer cette demande ?"
        submitText="Supprimer"
      />
      <AlertModal
        isOpen={modalState.type === "revert"}
        onClose={handleCloseModal}
        onSubmit={handleModalAction}
        title="Retourner à l'étape de validation de la demande de financement"
        body="Êtes-vous sûr de vouloir retourner à l'étape de validation de cette demande ?"
        submitText="Valider"
      />
      <SendAfdasRefreshNotificationModal modalState={modalState} isOpen={modalState.type === "password"} onClose={handleCloseModal} />

      <Card>
        <CardContent className="space-y-2">
          {reviewedFetching === "pending" || submittedFetching === "pending" ? (
            <div className="flex h-32 items-center justify-center">
              <div className="size-8 animate-spin rounded-full border-b-2 border-gray-900" />
            </div>
          ) : reviewedFetching === "failed" || submittedFetching === "failed" ? (
            <div className="mb-4 rounded bg-red-50 p-4 text-red-500">Une erreur s'est produite lors du chargement des données.</div>
          ) : (
            <DataTable
              columns={columns}
              data={[...reviewed, ...submitted]}
              sortField="training_startDate"
              order="asc"
              searchPlaceHolder="Rechercher une demande de financement"
              pageSize={30}
              syncWithUrl={true}
            />
          )}
        </CardContent>
      </Card>
    </>
  );
};
