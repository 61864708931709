import { createReducer } from "@reduxjs/toolkit";
import {
  assignEducationalAdvisorToEvaluations,
  resetAssignEducationalAdvisorToEvaluations,
} from "./assign-educational-advisor-to-evaluations";
import { ProcessingState } from "@redux/app-state";

const initialState: ProcessingState = { processing: "idle" };

export const assignEducationalAdvisorToEvaluationsReducer = createReducer<ProcessingState>(initialState, builder => {
  builder.addCase(assignEducationalAdvisorToEvaluations.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(assignEducationalAdvisorToEvaluations.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(assignEducationalAdvisorToEvaluations.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetAssignEducationalAdvisorToEvaluations, () => {
    return initialState;
  });
});
