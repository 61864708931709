import { FormInputs } from "./form-validation/funding-request";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { useFormContext } from "react-hook-form";

export const MonthlyLessonsCountFormField = () => {
  useFormContext<FormInputs>();

  return (
    <FormField
      name="monthlyLessonsCount"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Combien de cours par mois pensez-vous prendre ?</FormLabel>
          <FormControl className="w-[150px]">
            <Input {...field} type="number" min="1" data-testid="monthly-lessons-count-input" />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
