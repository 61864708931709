import { ReactNode, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateLessonBody } from "@academy-context/write/domain/types/teacher/lesson";
import { FormInputs, schema, defaultValues, formatFormDataToBodyData } from "./form-validation/lesson";
import { selectLessonCreate, useLessonCreate } from "./use-lesson-create";
import { useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import {
  MAX_HOURLY_PRICE,
  MAX_LESSON_HOURS,
  TEACHER_MIN_HOURLY_PRICE,
  MIN_STUDIO_PRICE,
  MAX_STUDIO_PRICE,
} from "@academy-context/write/domain/constants/shared";
import { LostDataModal } from "@shared-kernel/primary/shared/lost-data-modal/modal";
import { EMAIL_CONTACT } from "../../../../config/constant";
import { getLocationPrice } from "@academy-context/write/domain/services/locations";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { PopoverCalendar } from "@shared-kernel/primary/shared/calendar/popover-calendar";
import { Combobox } from "@shared-kernel/primary/shared/combobox/combobox";
import { RadioGroup, RadioGroupItem } from "@shared-kernel/primary/shared/shadcn/ui/radio-group";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { locationOptions as allLocationOptions, PLACE_VALUE } from "@academy-context/read/domain/services/locations";
import { Tooltip } from "@shared-kernel/primary/shared/tooltip/tooltip";
import { AddStudentsModal } from "@academy-context/primary/teacher/lesson-teacher-detail/add-students-modal";
import { StudioChoicesMap } from "@academy-context/read/domain/types/admin/lesson";
import { useFormBlocker } from "@shared-kernel/primary/shared/lost-data-modal/use-form-blocker";
import { commonAdapters } from "src/common-adapters";
import PlainTextLexical from "@shared-kernel/primary/shared/text-editor/plain-text/plain-text-lexical";
import { formatDateToLocale } from "@utils/formatting";

const STUDENT_TOOLTIP_TEXT: ReactNode = (
  <div>
    Vos élèves sont pré-enregistrés dans ce menu.
    <br />
    Pour ajouter un nouvel élève, en faire la demande par mail à {EMAIL_CONTACT}
  </div>
);

const HOURS_TOOLTIP_TEXT: ReactNode = <div>Durée en heures, ajustable par tranche d'un 1/4 d'heure</div>;

const HOURLY_PRICE_TOOLTIP_TEXT: ReactNode = (
  <div>
    Le tarif horaire est pré-enregistré.
    <br />
    Vous pouvez modifier cette valeur dans l'onglet profil.
    <br />
    Vous pouvez faire varier le tarif horaire d'un élève à l'autre.
  </div>
);

const LOCATION_TOOLTIP_TEXT: ReactNode = <div>Lorsque vous donnez les cours chez vous, laissez le champ &quot;Studio non payant&quot;</div>;

enum STUDIO_CHOICES {
  FREE = "free",
  PAID_BY_TEACHER = "paidByTeacher",
  PAID_BY_OPERA_OFF = "paidByOperaOff",
}

interface Props {
  onSubmit: (body: CreateLessonBody) => void;
}

export const LessonCreate = ({ onSubmit }: Props) => {
  const { dateProvider } = commonAdapters;
  const now = dateProvider!.now();

  const {
    teacherStudentsRetrieval: { data: students },
    teacherProfileRetrieval: { data: profile },
    lessonsRetrieval: { data: existingLessons },
  } = useAppSelector((state: AppState) => state);
  const { fetching } = useAppSelector(selectLessonCreate);

  const form = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues });
  const [isAddStudentModalOpened, setIsAddStudentModalOpened] = useState(false);
  const [isPossiblyDuplicate, setIsPossiblyDuplicate] = useState(false);

  const {
    reset,
    watch,
    setValue,
    formState: { isDirty, defaultValues: formDefaultValues },
  } = form;

  const { isModalOpen, handleCloseModal, handleProceed } = useFormBlocker(isDirty);

  useLessonCreate({ reset });

  const handleOnSubmit = async (formBody: FormInputs) => {
    const body = formatFormDataToBodyData(formBody);
    onSubmit(body);
  };

  const student = watch("student");
  const hours = watch("hours");
  const date = watch("date");
  const hourlyPrice = watch("hourlyPrice");
  const place = watch("placeName");
  const placeOption = watch("placeOption");
  const isPaidByTeacher = placeOption === STUDIO_CHOICES.PAID_BY_TEACHER;
  const placePrice = isPaidByTeacher ? watch("placePrice") : place ? getLocationPrice(place) : 0;
  const totalAmountForStudent = Number(hours ?? 0) * (Number(hourlyPrice ?? 0) + Number(placePrice ?? 0));
  const totalAmountForTeacher = Number(hours ?? 0) * (Number(hourlyPrice ?? 0) + Number(isPaidByTeacher ? placePrice : 0));

  useEffect(() => {
    if (profile?.defaultPrice) setValue("hourlyPrice", profile.defaultPrice, { shouldDirty: false });
  }, [profile, setValue]);

  const studentOptions = students.map(s => {
    let label = `${s.name} ${s.lastName}`;
    if (s.isBlacklisted) label += " (solde épuisé)";
    return { value: s.studentId, label, isDisabled: s.isBlacklisted };
  });

  // Removes perso option when not needed
  const locationOptions = [...allLocationOptions.filter(s => placeOption === STUDIO_CHOICES.FREE || s.value !== "perso")];

  useEffect(() => {
    if (placeOption === STUDIO_CHOICES.PAID_BY_OPERA_OFF) setValue("placeName", PLACE_VALUE.ACCORD_PARFAIT);
    else if (placeOption === STUDIO_CHOICES.FREE) setValue("placeName", PLACE_VALUE.PERSO);
  }, [placeOption, setValue]);

  useEffect(() => {
    const isPossiblyDuplicate = existingLessons.some(
      lesson => formatDateToLocale(lesson.date) === formatDateToLocale(date) && lesson.student.id === student
    );
    setIsPossiblyDuplicate(isPossiblyDuplicate);
  }, [date, existingLessons, student]);

  const onCloseModal = () => {
    setIsAddStudentModalOpened(false);
  };

  return (
    <CustomCard title="DECLARER UN COURS">
      <LostDataModal isOpen={isModalOpen} onClose={handleCloseModal} onSubmit={handleProceed} />
      <AddStudentsModal isOpen={isAddStudentModalOpened} onClose={onCloseModal} />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleOnSubmit)} className="grid w-full grid-cols-3 gap-3">
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="date"
              render={({ field }) => {
                return (
                  <FormItem className="flex flex-col">
                    <FormLabel>Date du cours</FormLabel>
                    <PopoverCalendar
                      value={field.value}
                      onChange={value => field.onChange(value)}
                      maxDate={now}
                      now={now}
                      dataTestId="date-calendar"
                    />
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name="student"
              render={({ field }) => (
                <FormItem>
                  <Tooltip tooltipContent={STUDENT_TOOLTIP_TEXT}>
                    <FormLabel>Élève</FormLabel>
                  </Tooltip>
                  <FormControl>
                    <Combobox
                      options={studentOptions}
                      value={student}
                      onChange={value => field.onChange(value)}
                      placeholder="Selectionner élève..."
                      search={{
                        notFoundText: "Pas d'élève trouvé.",
                        commandInputPlaceHolder: "Chercher élève...",
                      }}
                      dataTestId="student-select"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormItem className="space-y-2">
              <Tooltip tooltipContent={LOCATION_TOOLTIP_TEXT}>
                <FormLabel>Studio</FormLabel>
              </Tooltip>
              <FormControl>
                <RadioGroup
                  onValueChange={value => setValue("placeOption", value as STUDIO_CHOICES)}
                  value={placeOption}
                  className="flex flex-col space-y-1"
                >
                  {Object.values(STUDIO_CHOICES).map((v, index) => (
                    <FormItem className="flex items-center space-x-3 space-y-0" key={index}>
                      <FormControl>
                        <RadioGroupItem value={v} data-testid={`studio-radio-option-${v}`} />
                      </FormControl>
                      <FormLabel className="font-normal">{StudioChoicesMap[v]}</FormLabel>
                    </FormItem>
                  ))}
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
            {isPaidByTeacher ? (
              <FormField
                control={form.control}
                name="placePrice"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Prix studio - payé par le professeur en €/heure</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        step="1"
                        min={MIN_STUDIO_PRICE}
                        max={MAX_STUDIO_PRICE}
                        {...field}
                        value={watch("placePrice")}
                        data-testid="studio-price-input"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            ) : (
              <FormField
                control={form.control}
                name="placeName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Studio</FormLabel>
                    {place && (
                      <FormControl>
                        <Combobox
                          options={locationOptions}
                          value={place}
                          onChange={value => field.onChange(value)}
                          placeholder="Selectionner le lieu..."
                          search={{
                            notFoundText: "Pas de lieu trouvé.",
                            commandInputPlaceHolder: "Chercher lieu...",
                          }}
                          disabled={placeOption !== STUDIO_CHOICES.PAID_BY_OPERA_OFF}
                        />
                      </FormControl>
                    )}
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
          </div>
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="hours"
              render={({ field }) => (
                <FormItem>
                  <Tooltip tooltipContent={HOURS_TOOLTIP_TEXT}>
                    <FormLabel>Durée du cours (heures)</FormLabel>
                  </Tooltip>
                  <FormControl>
                    <Input type="number" step="0.25" min={0} max={MAX_LESSON_HOURS} {...field} data-testid="hours-input" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="hourlyPrice"
              render={({ field }) => (
                <FormItem>
                  <Tooltip tooltipContent={HOURLY_PRICE_TOOLTIP_TEXT}>
                    <FormLabel>Tarif horaire (€/heure)</FormLabel>
                  </Tooltip>
                  <FormControl>
                    <Input
                      type="number"
                      step="1"
                      min={TEACHER_MIN_HOURLY_PRICE}
                      max={MAX_HOURLY_PRICE}
                      {...field}
                      data-testid="hourly-price-input"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormItem>
              <FormLabel>Prix total du cours pour l'élève (€)</FormLabel>
              <FormControl>
                <Input type="number" disabled value={totalAmountForStudent} />
              </FormControl>
              <FormMessage />
            </FormItem>
            <FormItem>
              <FormLabel>Montant payé au professeur (€)</FormLabel>
              <FormControl>
                <Input type="number" disabled value={totalAmountForTeacher} />
              </FormControl>
              <FormMessage />
            </FormItem>
          </div>
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="commentary"
              render={({ field }) => (
                <FormItem>
                  <div className="flex">
                    <FormLabel>Commentaire (facultatif, non visible par l'élève)</FormLabel>
                  </div>
                  <FormControl>
                    <PlainTextLexical<FormInputs>
                      name={field.name}
                      control={form.control}
                      defaultValue={formDefaultValues?.[field.name]}
                      placeholder="Observation du professeur sur l’élève, à destination d’OPERA Off (par ex. annulation, retard)"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="col-span-3 mt-4 space-y-4">
            {isPossiblyDuplicate && (
              <p className="font-bold text-red-500">
                Attention, un cours a déjà été saisi le {formatDateToLocale(date)} avec cet élève. Vérifiez bien qu'il ne s'agit pas d'un
                doublon avant d'enregistrer
              </p>
            )}
            <div className="flex justify-between">
              <Button type="button" onClick={() => setIsAddStudentModalOpened(true)}>
                Ajouter un élève
              </Button>
              <Button type="submit" disabled={!isDirty || fetching === "pending"} data-testid="submit-button">
                ENREGISTRER
              </Button>
            </div>
          </div>
        </form>
      </Form>
      <div className="mt-2 w-full">
        <div className="mt-3 font-bold">
          Pour toute remarque ou demande, adresser un mail à:{" "}
          <a href={`mailto:${EMAIL_CONTACT}`} target="_blank" rel="noreferrer" className="text-theme">
            {EMAIL_CONTACT}
          </a>
        </div>
      </div>
    </CustomCard>
  );
};
