import { BackButton } from "@shared-kernel/primary/shared/back-button/back-button";
import { ROLE_BASED_URLS } from "src/routes";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { ExistingEvaluationAssignmentCardComponent } from "@academy-context/primary/student/training-follow-up-evaluations/existing-evaluation-assignment-card.components";
import { Button } from "@components/ui/button";
import { Save } from "lucide-react";
import { useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import { Form } from "@shared-kernel/primary/shared/shadcn/ui/form";
import {
  evaluationAssignmentsSchema,
  formatFormDataToBodyData,
  FormInputs,
} from "@academy-context/primary/student/training-follow-up-evaluations/form-validation/evaluation-assignments";
import { EDUCATIONAL_ADVISOR } from "@academy-context/shared/domain/types/enums/education-advisors";
import { ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { useEffect, useMemo } from "react";
import { TrainingFollowUpEvaluation } from "@academy-context/read/domain/types/training-follow-up";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AssignEducationalAdvisorToEvaluationBody } from "@academy-context/shared/application/ports/training-follow-up-port";
import { Card, CardContent, CardHeader, CardTitle } from "@components/ui/card";
import { Separator } from "@components/ui/separator";
import { NewEvaluationFormComponent } from "./new-evaluation-form.components";
import { SurveyQuestionsComponent } from "./survey-questions.components";
import { SURVEY_QUESTION_TYPE } from "@academy-context/shared/domain/types/enums/survey";

interface Props {
  evaluations: TrainingFollowUpEvaluation[];
  teacherOptions: ComboboxOptions[];
  providerOptions: ComboboxOptions[];
  trainingFollowUpId: string;
  onSubmit: (body: AssignEducationalAdvisorToEvaluationBody) => void;
}

export const TrainingFollowUpEvaluationAssignmentForm = ({
  evaluations,
  trainingFollowUpId,
  teacherOptions,
  providerOptions,
  onSubmit,
}: Props) => {
  const { processing } = useAppSelector((state: AppState) => state.trainingFollowUpEvaluationsAssignment);
  const { data: surveyData } = useAppSelector((state: AppState) => state.trainingFollowUpSurveyRetrieval);
  const initialValues: FormInputs = useMemo(() => {
    return {
      existingEvaluations: evaluations.map(evaluation => ({
        evaluationId: evaluation.id,
        educationalAdvisorType: evaluation.assignedEducationalAdvisor?.type ?? EDUCATIONAL_ADVISOR.TEACHER,
        educationalAdvisorId: evaluation.assignedEducationalAdvisor?.id ?? "",
        isAbandoned: evaluation.isAbandoned,
      })),
      newEvaluations: [],
      surveyAnswers: surveyData.map(item => ({
        questionId: item.question.id,
        questionType: item.question.type,
        value: item.response ?? (item.question.type === SURVEY_QUESTION_TYPE.RATING ? item.question.options?.max ?? 5 : ""),
      })),
    };
  }, [evaluations, surveyData]);

  const form = useForm<FormInputs>({
    resolver: yupResolver(evaluationAssignmentsSchema),
    defaultValues: initialValues,
  });

  const {
    handleSubmit,
    formState: { isDirty, errors },
    watch,
    reset,
  } = form;

  // Reset form with initial values without marking as dirty
  useEffect(() => {
    reset(initialValues, { keepDirty: false });
  }, [initialValues, reset]);

  const handleOnSubmit = async (formBody: FormInputs) => {
    const body = formatFormDataToBodyData(formBody, trainingFollowUpId);
    onSubmit(body);
  };

  const isAssigned = evaluations.some(evaluation => evaluation.assignedEducationalAdvisor !== null);
  const existingEvaluations = watch("existingEvaluations");
  const newEvaluations = watch("newEvaluations");
  const atLeastOneAssignedEducationalAdvisor =
    existingEvaluations.some(evaluation => !evaluation.isAbandoned && evaluation.educationalAdvisorId) ||
    newEvaluations.some(evaluation => evaluation.educationalAdvisorId);

  return (
    <div className="flex justify-center">
      <div className="w-[90%]">
        <BackButton
          link={`${ROLE_BASED_URLS[ROLES.ADMIN].trainingFollowUp.list}/${trainingFollowUpId}`}
          label="Suivi qualité de votre formation"
        />
        <div className="mt-5 space-y-5">
          <Form {...form}>
            <form onSubmit={handleSubmit(handleOnSubmit)}>
              <Card className="w-full">
                <CardHeader className="flex flex-col items-center justify-between ">
                  <CardTitle className="text-2xl">Evaluations post-formation</CardTitle>
                  <div className="text-sm font-normal">
                    Dans le cadre de la certification Qualiopi, l’évaluation de votre formation est obligatoire, merci de répondre
                    sincèrement à ce formulaire.
                  </div>
                </CardHeader>
                <Separator className="bg-gray-400" />
                <CardContent className="flex flex-col items-center pt-3 text-black dark:text-white">
                  <div className="space-y-6">
                    {evaluations.map(evaluation => (
                      <ExistingEvaluationAssignmentCardComponent
                        key={evaluation.id}
                        evaluation={evaluation}
                        teacherOptions={teacherOptions}
                        providerOptions={providerOptions}
                      />
                    ))}
                    {!isAssigned && (
                      <>
                        <Separator className="bg-gray-400" />
                        <NewEvaluationFormComponent
                          teacherOptions={teacherOptions}
                          providerOptions={providerOptions}
                          trainingFollowUpId={trainingFollowUpId}
                        />
                      </>
                    )}
                    {surveyData.length > 0 && (
                      <>
                        <Separator className="bg-gray-400" />
                        <SurveyQuestionsComponent surveyData={surveyData} />
                      </>
                    )}
                  </div>
                  {!atLeastOneAssignedEducationalAdvisor && (
                    <div className="mt-2 text-sm font-medium text-destructive">Au moins un intervenant doit être assigné</div>
                  )}
                  {!isAssigned && (
                    <div className="mt-2 flex justify-end">
                      <Button
                        type="submit"
                        disabled={
                          !isDirty || processing === "pending" || Object.keys(errors).length > 0 || !atLeastOneAssignedEducationalAdvisor
                        }
                      >
                        <Save className="mr-2" />
                        Valider
                      </Button>
                    </div>
                  )}
                </CardContent>
              </Card>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
};
