import { FormInputs } from "./form-validation/funding-request";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Student } from "@user-management-context/read/domain/types/student/student";
import { useFormContext } from "react-hook-form";
import {
  AFDAS_STUDENT_STATUS,
  AFDAS_STUDENT_STATUS_OPTIONS,
  FUNDER,
  FUNDER_LABELS,
} from "@academy-context/shared/domain/types/enums/funders";
import { ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { RadioGroup, RadioGroupItem } from "@components/ui/radio-group";
import { FUNDER_TUTORIALS } from "@academy-context/primary/student/funding-request/funder-tutorials";
import { Input } from "@components/ui/input";

interface Props {
  student: Student;
}

const afdasStudentStatusOptions: ComboboxOptions[] = Object.values(AFDAS_STUDENT_STATUS).map(status => ({
  label: AFDAS_STUDENT_STATUS_OPTIONS[status],
  value: status,
}));

export const FunderFormField = ({ student }: Props) => {
  const { watch } = useFormContext<FormInputs>();

  const funderType = watch("funder.type");

  const funderOptions: ComboboxOptions[] = Object.values(FUNDER)
    .filter(funder => {
      if (student.canRequestANonAfdasFunding) return true;
      return funder === FUNDER.AFDAS;
    })
    .map(f => ({ label: FUNDER_LABELS[f], value: f }));

  return (
    <>
      <FormField
        name="funder.type"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Type de financeur</FormLabel>
            <FormControl>
              <RadioGroup onValueChange={value => field.onChange(value)} value={field.value} className="flex flex-col space-y-1">
                {funderOptions.map(option => (
                  <FormItem className="flex items-center space-x-3 space-y-0" key={option.value}>
                    <FormControl>
                      <RadioGroupItem value={option.value} />
                    </FormControl>
                    <FormLabel className="font-normal">{option.label}</FormLabel>
                  </FormItem>
                ))}
              </RadioGroup>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      {FUNDER_TUTORIALS[funderType]}
      {funderType === FUNDER.AFDAS && (
        <>
          <FormField
            name="funder.payload.id"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Votre identifiant AFDAS</FormLabel>
                <FormControl>
                  <Input placeholder={`Adresse mail Ex: marc@gmail.com`} {...field} type="email" data-testid="afdas-id-input" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="funder.payload.password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Votre mot de passe AFDAS</FormLabel>
                <FormControl>
                  <Input {...field} data-testid="afdas-password-input" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="funder.payload.studentStatus"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Statut AFDAS</FormLabel>
                <FormControl>
                  <RadioGroup onValueChange={value => field.onChange(value)} value={field.value} className="flex flex-col space-y-1">
                    {afdasStudentStatusOptions.map(option => (
                      <FormItem className="flex items-center space-x-3 space-y-0" key={option.value}>
                        <FormControl>
                          <RadioGroupItem value={option.value} />
                        </FormControl>
                        <FormLabel className="font-normal">{option.label}</FormLabel>
                      </FormItem>
                    ))}
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </>
      )}
      {funderType === FUNDER.FRANCE_TRAVAIL && (
        <>
          <FormField
            name="funder.payload.id"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Votre identifiant France Travail</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="funder.payload.postCode"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Code postal déclaré à France Travail</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </>
      )}
    </>
  );
};
