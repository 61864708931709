import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { studentRetrieveFundings } from "../../../read/application/use-cases/student/fundings-retrieval/retrieve-fundings";
import { formatDateToLocale, formatToCurrency } from "../../../../utils/formatting";
import { ColumnDef } from "@tanstack/react-table";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { AppState } from "@redux/app-state";
import { StudentFundingListVM } from "@academy-context/read/domain/types/student/funding";
import { FUNDER_LABELS } from "@academy-context/shared/domain/types/enums/funders";

const columns: ColumnDef<StudentFundingListVM>[] = [
  {
    accessorKey: "obtentionDate",
    header: () => "Obtenu le",
    cell: ({ row }) => formatDateToLocale(row.original.obtentionDate),
    enableSorting: true,
    meta: {
      title: "Obtenu le",
      width: "90px",
    },
  },
  {
    accessorKey: "funder",
    header: () => "Financeur",
    cell: ({ row }) => FUNDER_LABELS[row.original.funder],
    meta: {
      title: "Financeur",
      width: "120px",
    },
  },
  {
    accessorKey: "row.original.trainingPrice",
    header: () => "Montant total",
    cell: ({ row }) => formatToCurrency(row.original.trainingPrice - row.original.operaOffCommission),
    meta: {
      title: "Montant total",
    },
  },
];

export const StudentFundingsList = () => {
  const dispatch = useAppDispatch();
  const { data: fundings } = useAppSelector((appState: AppState) => appState.studentFundingsRetrieval);

  useEffect(() => {
    dispatch(studentRetrieveFundings());
  }, [dispatch]);

  return (
    <CustomCard title="HISTORIQUE DES FINANCEMENTS">
      <DataTable columns={columns} data={fundings} sortField="obtentionDate" order="desc" searchPlaceHolder="Rechercher un financement" />
    </CustomCard>
  );
};
