import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { TrainingFollowUpEvaluation } from "../../../../domain/types/training-follow-up";

export const adminRetrieveTrainingFollowUpEvaluations = createAsyncThunk<
  TrainingFollowUpEvaluation[],
  { trainingId: string },
  { extra: Partial<Dependencies> }
>(
  "trainingFollowUps/adminRetrieveTrainingFollowUpEvaluations",
  async ({ trainingId }: { trainingId: string }, { extra: { trainingFollowUpGateway } }) => {
    return trainingFollowUpGateway!.getTrainingFollowUpEvaluations(trainingId);
  }
);
