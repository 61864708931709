import { useEffect, useMemo, useRef, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInputs, schema, defaultValues, formatFormDataToBodyDataCreateFundingRequest } from "./form-validation/funding-request";
import { LostDataModal } from "@shared-kernel/primary/shared/lost-data-modal/modal";
import { useAppDispatch } from "@redux/hooks";
import { RequestState } from "@redux/app-state";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Send } from "lucide-react";
import { ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { EDUCATIONAL_ADVISOR } from "@academy-context/shared/domain/types/enums/education-advisors";
import { Combobox } from "@shared-kernel/primary/shared/combobox/combobox";
import {
  resetStudentRetrieveProviderBatches,
  studentRetrieveProviderBatches,
} from "@academy-context/read/application/use-cases/student/provider-batch-list-retrieval/retrieve-provider-batch-list";
import { useFormBlocker } from "@shared-kernel/primary/shared/lost-data-modal/use-form-blocker";
import { TeacherVM } from "@user-management-context/read/domain/types/student/teacher";
import { ProviderVM } from "@user-management-context/read/domain/types/student/provider";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { StudentProviderEnrollment } from "@academy-context/read/domain/types/student/provider-enrollment";
import { CreateFundingRequestBody } from "@shared-kernel/application/ports/shared/funding-request-repository";
import { StudentInternalTraining } from "@academy-context/read/domain/types/student/internal-training";
import { StudentProviderBatchItem } from "@academy-context/read/domain/types/student/provider-batch";
import { InternalTrainingOrderedQuestion } from "@academy-context/read/domain/types/admin/internal-training";
import { ProviderTrainingOrderedQuestion } from "@academy-context/read/domain/types/shared/provider-training";
import { Student } from "@user-management-context/read/domain/types/student/student";
import { CoverLetterField } from "@academy-context/primary/shared/funding-request-form/cover-letter-field";
import { PersonalizedEducationalProjectField } from "@academy-context/primary/student/funding-request/personalized-educational-project-field";
import { FunderFormField } from "./funder-form-field";
import { EducationalAdvisorFormField } from "./educational-advisor-form-field";
import { BioResumeFormField } from "./bio-resume-form-field";
import { SecondaryTeachersFormField } from "./secondary-teachers-form-field";
import { QuestionsFormField } from "./questions-form-field";
import { SpecificAccommodationFormField } from "./specific-accommodation-form-field";
import { MonthlyLessonsCountFormField } from "./monthly-lessons-count-form-field";

interface Props {
  onSubmit: (body: CreateFundingRequestBody) => void;
  internalTrainingsOptions: ComboboxOptions[];
  rawInternalTrainings: StudentInternalTraining[];
  teachers: TeacherVM[];
  providers: ProviderVM[];
  providerBatchesOptions: ComboboxOptions[];
  rawProviderBatches: StudentProviderBatchItem[];
  processing: RequestState;
  studentProviderEnrollment: Nullable<StudentProviderEnrollment>;
  student: Student;
}

export const FundingRequestForm = ({
  onSubmit,
  internalTrainingsOptions,
  rawInternalTrainings,
  teachers,
  providers,
  providerBatchesOptions,
  rawProviderBatches,
  processing,
  studentProviderEnrollment,
  student,
}: Props) => {
  const dispatch = useAppDispatch();
  const methods = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues });
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(false);
  const { isModalOpen, handleCloseModal, handleProceed } = useFormBlocker(shouldBlockNavigation);
  const [needsAccommodation, setNeedsAccommodation] = useState(false);
  const [isUndefinedTeacher, setIsUndefinedTeacher] = useState(false);

  const hasEligibleProviderEnrollment = Boolean(studentProviderEnrollment);
  const skipUseEffect = useRef(false);

  const {
    formState: { isDirty },
    setValue,
    resetField,
    watch,
    reset,
  } = methods;

  const { replace: replaceQuestions, fields: questionFields } = useFieldArray({
    control: methods.control,
    name: "questions",
    keyName: "fieldId",
  });

  const educationalAdvisorId = watch("educationalAdvisorId");
  const educationalAdvisorType = watch("educationalAdvisorType");
  const isProvider = educationalAdvisorType === EDUCATIONAL_ADVISOR.PROVIDER;
  const funderType = watch("funder.type");
  const internalTrainingId = watch("internalTrainingId");
  const providerBatchId = watch("providerBatchId");

  useEffect(() => {
    // All this complexity is due to the fact that we don't want this useEffect to be triggered after form initialization
    // Otherwise, it resets all the values set in the form initialization
    if (skipUseEffect.current) {
      skipUseEffect.current = false;
      return;
    }

    setValue("educationalAdvisorId", "", { shouldDirty: false });
    setValue("internalTrainingId", "", { shouldDirty: false });
    setValue("providerBatchId", "", { shouldDirty: false });
  }, [educationalAdvisorType, setValue, isUndefinedTeacher]);

  useEffect(() => {
    // Reset the provider batches when the educational advisor id changes otherwise if the student switches between teacher and provider, the provider batches are not reset
    dispatch(resetStudentRetrieveProviderBatches());
    if (isProvider && educationalAdvisorId) dispatch(studentRetrieveProviderBatches(educationalAdvisorId));
    // Disabled otherwise this is triggered on the switch between teacher and provider which executes the dispatch event though it is the teacher's educational advisor id specified (results in a 404)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, educationalAdvisorId]);

  useEffect(() => {
    if (isProvider) resetField("secondaryTeachers");
  }, [setValue, educationalAdvisorType, isProvider, resetField]);

  useEffect(() => {
    resetField("funder.payload.id");
    resetField("funder.payload.password");
    resetField("funder.payload.studentStatus");
    resetField("funder.payload.postCode");
  }, [funderType, resetField]);

  useEffect(() => {
    setShouldBlockNavigation(isDirty);
  }, [isDirty]);

  useEffect(() => {
    const questions: FormInputs["questions"] = [];
    const trainings: { id: string; questions: (InternalTrainingOrderedQuestion | ProviderTrainingOrderedQuestion)[] }[] = isProvider
      ? rawProviderBatches
      : rawInternalTrainings;
    const trainingId = isProvider ? providerBatchId : internalTrainingId;
    const selectedTraining = trainings.find(training => training.id === trainingId);
    if (selectedTraining) {
      for (const q of selectedTraining.questions) {
        questions.push({
          question: q.text,
          order: q.order,
          answer: "",
        });
      }
    }
    replaceQuestions(questions);
  }, [replaceQuestions, internalTrainingId, isProvider, providerBatchId, rawInternalTrainings, rawProviderBatches, resetField]);

  useEffect(() => {
    if (!needsAccommodation) setValue("specificAccommodation", "", { shouldDirty: false });
  }, [needsAccommodation, setValue]);

  // This useEffect has to be below the previous ones to avoid the reset of the educational advisor id
  useEffect(() => {
    // If the student is invited to a provider batch, they are required to use it
    if (hasEligibleProviderEnrollment && studentProviderEnrollment) {
      // Need to specify defaultValues otherwise all values are reset to undefined
      reset({
        ...defaultValues,
        educationalAdvisorId: studentProviderEnrollment.providerId,
        educationalAdvisorType: EDUCATIONAL_ADVISOR.PROVIDER,
        providerBatchId: studentProviderEnrollment.batchId,
      });
      skipUseEffect.current = true;
    }
  }, [hasEligibleProviderEnrollment, studentProviderEnrollment, reset]);

  const handleOnSubmit = async (formBody: FormInputs) => {
    setShouldBlockNavigation(false);
    const body = formatFormDataToBodyDataCreateFundingRequest(formBody);
    onSubmit(body);
  };

  const teacherOptions: ComboboxOptions[] = teachers.map(t => ({ label: `${t.name} ${t.lastName}`, value: t.id }));
  const providerOptions: ComboboxOptions[] = useMemo(() => {
    const allProviderOptions = providers.map(p => ({ label: p.name, value: p.id }));
    return allProviderOptions;
  }, [providers]);

  return (
    <>
      <LostDataModal isOpen={isModalOpen} onClose={handleCloseModal} onSubmit={handleProceed} />
      <FormProvider {...methods}>
        <Form {...methods}>
          <form onSubmit={methods.handleSubmit(handleOnSubmit)} className="space-y-4">
            <FunderFormField student={student} />
            <EducationalAdvisorFormField
              hasEligibleProviderEnrollment={hasEligibleProviderEnrollment}
              isUndefinedTeacher={isUndefinedTeacher}
              setIsUndefinedTeacher={setIsUndefinedTeacher}
              teacherOptions={teacherOptions}
              providerOptions={providerOptions}
            />
            {isProvider ? (
              <>
                <FormField
                  name="providerBatchId"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Intitulé de la session</FormLabel>
                      <FormControl>
                        <Combobox
                          options={providerBatchesOptions}
                          value={field.value}
                          onChange={value => field.onChange(value)}
                          placeholder="Selectionner session..."
                          search={{
                            notFoundText: "Pas de session trouvée.",
                            commandInputPlaceHolder: "Chercher session...",
                          }}
                          disabled={hasEligibleProviderEnrollment}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="text-sm font-bold text-red-500">
                  Il est nécessaire que vous ayez pris contact avec le prestataire pour confirmer votre participation à l'une de leurs
                  sessions. <br />
                  Cette demande sert uniquement à obtenir un financement et ne garantit pas votre place si vous n'avez pas préalablement
                  convenu d'un accord avec le prestataire.
                </div>
              </>
            ) : (
              <FormField
                name="internalTrainingId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Intitulé de la formation</FormLabel>
                    <FormDescription>
                      Choisissez la thématique de formation qui correspond le mieux à vos objectifs. Vous aurez l'occasion de préciser
                      davantage votre projet pédagogique. Cette thématique sert de cadre général.
                    </FormDescription>
                    <FormControl>
                      <Combobox
                        options={internalTrainingsOptions}
                        value={field.value}
                        onChange={value => field.onChange(value)}
                        placeholder="Selectionner formation..."
                        search={{
                          notFoundText: "Pas de formation trouvé.",
                          commandInputPlaceHolder: "Chercher formation...",
                        }}
                        dataTestId="internal-training-select"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            <BioResumeFormField />
            <CoverLetterField isStudentForm />
            <PersonalizedEducationalProjectField />
            {!isProvider && <SecondaryTeachersFormField teacherOptions={teacherOptions} isUndefinedTeacher={isUndefinedTeacher} />}
            <QuestionsFormField questionFields={questionFields} />
            <SpecificAccommodationFormField needsAccommodation={needsAccommodation} setNeedsAccommodation={setNeedsAccommodation} />
            <MonthlyLessonsCountFormField />
            <div className="flex justify-end">
              <Button type="submit" disabled={!isDirty || processing === "pending"} data-testid="submit-button">
                <Send className="mr-2 size-4" />
                Envoyer ma demande pour validation
              </Button>
            </div>
          </form>
        </Form>
      </FormProvider>
    </>
  );
};
